<template>
  <div class="wallet">
    <div class="wallet-total">
      <div class="total-img" @click.stop="checkRechargeModal">
        <img src="~@/assets/images/balance-icon.png"/>
      </div>
      <div class="total-text">
        <div class="row">
          <div><label>门店地址：</label>{{info.shopAddress}}</div>
        </div>
        <div class="row">
          <div class="col">
            <div><label>上网卡号：</label>{{info.memberNumber}}</div>
            <div><label>上网用户：</label>{{info.memberName}}</div>
            <div><label>账户余额：</label>{{info.balance}}元 (现金:{{info.availMoney}}元 赠送金:{{info.presentedMoney}})</div>
          </div>
          <div class="col" style="margin-left: 50px">
            <div><label>会员级别：</label>{{info.memberType}}</div>
            <div><label>上网区域：</label>{{info.areaName}}</div>
            <div><label>上网费率：</label>{{info.price}}元/小时</div>
          </div>
          <div class="col">
            <div><label>机器号：</label>{{info.deviceNumber}}</div>
            <div><label>上网时长：</label>{{times}}</div>
            <div><label>本次消费：</label>{{info.consumption}}元</div>
          </div>
        </div>
      </div>
    </div>
    <div class="wallet-lists">
      <div class="lists-search">
        <div class="lists-left">
          <div :class="index == tabIdx?'tabShow':''" v-for="(item,index) in tabList" :key="index" @click="handleTabData(item,index)">{{item.name}}</div>
        </div>
        <div class="lists-right">
          <div class="block">
            <span class="demonstration">时间查询：</span>
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy - MM.dd"
              size='small'
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="search" @click="handleSearch">查询</div>

        </div>

      </div>
      <!-- 上网消费记录 -->
      <div class="lists-table" v-if="tabIdx == 0">
        <el-table
          v-loading="expenseLoading"
          :data="tableData"
          border
          size="mini" 
          
          :header-cell-style="{'text-align':'center','background': '#EBF1FE','color': '#000'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%">
          <el-table-column prop="business" label="业务类型"> 
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间" width="180px" > </el-table-column>
          <el-table-column prop="endTime" label="结束时间" width="180px"> </el-table-column>
          <el-table-column prop="money" label="消费金额"> </el-table-column>
          <el-table-column prop="shopName" label="门店"> </el-table-column>
        </el-table>
      </div>
      <!-- 充值记录 -->
      <div class="lists-table" v-else>
        <el-table
          v-loading="chargeLoading"
          :data="chargelist"
          border
          size="mini" 
          
          :header-cell-style="{'text-align':'center','background': '#EBF1FE','color': '#666666'}"
          :cell-style="{'text-align':'center'}"
          style="width: 100%">
          <el-table-column prop="business" label="业务类型" width=""> 
          </el-table-column>
          <el-table-column prop="payTime" label="充值时间" width="180px"> </el-table-column>
          <el-table-column prop="topUpMoney" label="充值金额" width="75px"> </el-table-column>
          <el-table-column prop="presenterMoney" width="75px" label="赠送金额"> </el-table-column>
          <el-table-column prop="shopName" label="充值门店" width="150px"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
        </el-table>

      </div>
      <!-- 分页 -->
      <div class="lists-page">
        <pagination
          v-show="search.total > 0"
          :total="search.total"
          :page.sync="search.params.pageNum"
          :limit.sync="search.params.pageSize"
          @pagination="handleCurrentChange"
        />
      </div>
    </div>
    <tipData></tipData>

    <div class="order-tip" v-show="rechargeModal.playImg">
      <div class="tip-close" @click="handleClearTimeout">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="play-title">
        <div class="title-name">{{rechargeModal.payName}}</div>
        <!-- <div class="title-money">
          {{palyLabel}}
          <span class="money-icon">￥</span> <span class="money-num">{{dataObj.price || 0}}</span>
        </div> -->
      </div>
      <img class="pay-code" :src="rechargeModal.payCode"/>
      <div class="pay-tip-text">扫码成功请稍等几秒,在关闭弹窗</div>
    </div>
    <!-- 网费充值弹窗 -->
    <div class="network-tip" v-if="rechargeModal.is">
      <div class="network-tip-warp">
        <div class="tip-close" @click="closeRechargeModal">
          <img src="~@/assets/images/balance/user/close.png"/>
        </div>
        <div class="network-all">
          <div class="network-title">网费充值</div>
          <div class="network-display">
            <div class="network-flex network-money">
              <div>网费余额∶</div>
              <div class="money-num">{{info.balance}}元 (现金:{{info.availMoney}}元 赠送金:{{info.presentedMoney}})</div>
              
            </div>
            <div class="network-flex">
              <div class="flex-label">充值金额∶</div>
              <div class="flex-display">
                <div class="flex-bnt" :class="{'flex-bnt-show': index == rechargeModal.currIndex}" v-for="(item,index) in rechargeModal.payMoneyList" :key="index" @click="handlePayMoneyBnt(item,index)">
                  <div class="flex-bnt-item">
                    <div>{{item.conditionMoney}}元</div>
                    <div class="bnt-item-text money-num" v-if="item.presenterMoney">赠送{{item.presenterMoney}}元</div>
                    <!-- <div>{{item.money}}元</div>
                    <div class="bnt-item-text money-num" v-if="item.text">{{item.text}}</div> -->

                  </div>
                </div>
                
                <div class="flex-input">
                  <input type="number"  @focus="handlePayNnmber"  v-model="rechargeModal.payNnmber" maxlength="5" placeholder="请输入金额"/>
                  <div class="input-text">其他金额</div>
                </div>
              </div>
            </div>
            <div class="network-flex ">
              <div class="pay-label">充值方式∶</div>
              <div class="pay-bnts">
                <div class="wechat-add" @click="handlNetworkPayData(5)">微信</div>
                <div class="zfb-add" @click="handlNetworkPayData(6)">支付宝</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import {
  onlineRecord,
  userinfo,
  rechargeList
} from "@/api/wallet";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import { activityData, clientRecharge, clientPay, rechargePayStatus } from "@/api/userInfo";
import tipData from "./common/index";

export default {
  name: 'wallet',
  data() {
    return {
      secret: {},
      times:'',
      search: {
        is: false,
        val: "",
        params: {
          page: 1,
          pageSize: 10,
          isAsc: 1,
          name: ""
        },
        total: 0,
        list: []
      },
      expenseLoading: false,
      chargeLoading: false,
      chargelist: [],
      tableData: [],
      value1: [],
      tabList: [
        {
          id: 1,
          name: '上网消费记录',
        },
        {
          id: 2,
          name: '充值记录',
        }
      ],
      tabIdx: 0,
      info: {},
      rechargeModal: {
        is: false,
        payNnmber: null,
        payMoneyList: [],
        currIndex: 0,
        info: {},
        payName: '',
        playImg: false,
        payCode: '',
        params: {},
        coinNUm: 20,
        payParams: {
          orderType: 1
        }
      }
    }
  },
  components: { tipData },
  methods: {
    handleUserinfo() {
      userinfo().then(res => {
        if (res.code == 200) {
          this.info = JSON.parse(decryptByDESModeCBC(res.msg));
          var now = this.info.systemTime
          var fun =()=>{
            this.times = this.dateDiff(new Date(this.info.onlineTime),new Date(now));
          }
          fun();
          this.inter = setInterval(() =>{
            now += 1000
            fun();
          }, 1000);
          this.isLogin = true;
          return false;
        }
      }).catch(res=>{
      });

    },
    dateDiff(startTime, endTime) {
      if (!startTime || !endTime) {
        return "";
      }
      var date1 = new Date(startTime);
      var date2 = new Date(endTime);

      var date3 = date2.getTime() - date1.getTime(); //时间差的毫秒数
      //计算出相差天数
      var days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数

      var leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));

      //计算相差秒数

      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      var str = "";
      if (days) {
        str += days + "天 ";
      }
      str += (hours < 10 ? "0" + hours : hours) + ":";
      str += (minutes < 10 ? "0" + minutes : minutes) + ":";
      str += seconds < 10 ? "0" + seconds : seconds;

      return str;
    },
    handleCurrentChange(row,res) {
      var params = {};
      params.pageNum = this.search.params.pageNum;
      params.pageSize = this.search.params.pageSize;
      params.isAsc = this.search.params.isAsc;
      if(this.value1 && this.value1.length>0) {
        this.search.params.startTime = this.value1[0] + ' 00:00:00';
        this.search.params.endTime = this.value1[1]+ ' 23:59:59';
      }else{
        this.search.params.startTime = ''
        this.search.params.endTime = ''
      }
        params.startTime = this.search.params.startTime
        params.endTime = this.search.params.endTime 
      if(this.tabIdx == 0) {
        this.expenseLoading = true
        onlineRecord(params).then(res => {
          if (res.code == 200) {
            this.tableData = res.rows
            this.search.total = res.total
          } 
          this.expenseLoading = false
        }).catch(res=>{
          this.expenseLoading = false
        });

      }else {
        this.chargeLoading = true
        rechargeList(params).then(res => {
          if (res.code == 200) {
            this.chargelist = res.rows
            this.search.total = res.total
          } 
          this.chargeLoading = false
        }).catch(res=>{
          this.chargeLoading = false
        });

      }

    },
    handleTabData(item,idx) {
      this.handleUserinfo()
      this.search.params.pageNum = 1
      this.search.total = 0
      this.tabIdx = idx
      this.search.params.startTime = '';
      this.search.params.endTime = '';
      this.value1 = []
      this.handleCurrentChange()
    },
    handleSearch() {
      this.search.params.pageNum = 1
      this.handleCurrentChange()
    },
    handleWalletFirst() {
      this.handleUserinfo()
      this.handleCurrentChange()
    },
    // 充值网费弹窗
    checkRechargeModal() {
      this.rechargeModal.is = true

      console.log(this.info)
      let params = {
        lgUserId: this.info.memberId
      }
      activityData({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {

          this.rechargeModal.payMoneyList = res.data.refillCards
          // let refillCards = res.data.refillCards
          // for(let i  in  refillCards) {
          //   for(let j  in  this.payMoneyS) {
          //     if(this.refillCards[i].conditionMoney == this.payMoneyS[j].money) {
          //       this.payMoneyS[j].text = this.refillCards[i].cardName 
          //       this.payMoneyS[j].cardId = this.refillCards[i].cardId
          //       this.payMoneyS[j].presenterMoney = this.refillCards[i].presenterMoney
          //       this.payMoneyS[j].type = this.refillCards[i].type
          //     }
          //   }
          // }
          // this.payObj = this.payMoneyS[0]
        }else {
        }
      })
    },
    // 充值网费创建订单
    handlNetworkPayData(status) {
      var payObj = {}, currIndex = this.rechargeModal.currIndex
      
      if(currIndex != null){
        payObj = this.rechargeModal.payMoneyList[currIndex]
      }else{
        var payNnmber = this.rechargeModal.payNnmber
        if(this.$utils.numberInt(payNnmber || 0)) {
          this.$message.error('请输入正整数!');
          return
        }
        if(payNnmber < 10) {
          this.$message.error('充值不能少于30元!');
          return
        }
        payObj.conditionMoney = Number(payNnmber)
      }

      if(status == 5) {
        this.rechargeModal.payName = '微信支付'
      } else if(status == 6){
        this.rechargeModal.payName = '支付宝支付'
      }
     

      let params = {
        memberId: this.info.memberId,
        refillCardId: payObj.cardId || null,
        refillCardType: payObj.type || null,
        topUpMoney: payObj.conditionMoney || null,
        presenterMoney: payObj.presenterMoney || null,
        type: status,
        source: 2
      }
      // console.log(params)
      clientRecharge({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.rechargeModal.payParams.orderNumber = res.data.orderNumber
          this.rechargeModal.payParams.payType = res.data.payType
          // this.createNumber = res.data.orderNumber
          // this.payStatus = res.data.payType
          // if(status == 2 || status == 3) {
          
          //   this.payCode = res.data.imgUrl
          // }
          this.handleClientPay()
        }else {
          this.$message.error(res.msg);
        }
      })
    },
    // 网费充值-支付接口
    handleClientPay() {
      clientPay({data:encryptByDESModeCBCUtf8to64(JSON.stringify(this.rechargeModal.payParams))}).then(res => {
        if (res.code == 200) {
          this.rechargeModal.playImg = true
          this.rechargeModal.payCode = res.data.imgUrl
          this.rechargePayStatus()
        }else {
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    // 充值网费轮询接口
    rechargePayStatus() {
      this.rechargeModal.coinNUm--
      let _this = this
      rechargePayStatus({orderNumber: this.rechargeModal.payParams.orderNumber}).then(res => {
        if (res.code == 200) {
          this.timer = setTimeout(()=>{
            console.log(this.rechargeModal.coinNUm)
            if(this.rechargeModal.coinNUm<1) {
              if(res.data.status == 2) {
                this.$message({
                    message: res.msg || '充值成功!',
                    type: 'success',
                    customClass: 2000
                });
                this.closeRechargeModal()
                this.handleUserinfo()
              } else {
                this.handleClearTimeout();
                this.$message.error('充值超时!');
              }
              return true
            }else{
              if(res.data.status == 2) {
                this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass: 2000
                });
                this.rechargeModal.coinNUm = 20 
                this.timer = null
                this.closeRechargeModal()
                this.handleUserinfo()
                return true
              }
            }
            this.rechargePayStatus()
          },3000)
        }else {
          this.$message.error(res.msg || '操作失败!');
          this.rechargeModal.coinNUm = 20 
          this.timer = null
          this.handleClearTimeout();
        }
      })

    },
    handleClearTimeout() {
      // this.rechargeModal.is = false
      // this.handlePayCodeData()

      this.rechargeModal.coinNUm = 20 
      this.rechargeModal.playImg = false
      if(this.timer) {
        this.$once('hook:beforeDestroy', () => {   // 清除定时器
          this.timer = null;         
          clearTimeout(this.timer);                                    
        })
        clearTimeout(this.timer);// 清除定时器
        this.timer = null;
      }
    },
    closeRechargeModal(){
       this.rechargeModal.is=false;
       this.handleClearTimeout()
    },
    handlePayCodeData() {
      // this.rechargeModal.coinNUm = 20 
      // this.rechargeModal.playImg = false
      // if(this.timer) {
      //   this.$once('hook:beforeDestroy', () => {   // 清除定时器
      //     this.timer = null;         
      //     clearTimeout(this.timer);                                    
      //   })
      //   clearTimeout(this.timer);// 清除定时器
      //   this.timer = null;
      // }
    },
    handlePayNnmber() {
      this.rechargeModal.currIndex = null
    },
    handlePayMoneyBnt(item, index){
      this.rechargeModal.currIndex = index;
      this.rechargeModal.payNnmber = null ;
    }
  },
  created(){
  },
  mounted() {
    this.handleWalletFirst()
  },
  destroyed(){
  }
}
</script>
<style lang="less">
.wallet{
  background: #fff;
  height: 100vh;
  overflow: hidden;
  // border-radius: 38px 38px 0 0;
  .wallet-total{
    display: flex;
    align-items: center;
    padding: 25px;
    box-shadow: 0px 13px 25px 0px rgba(204, 219, 230, 0.6);
    .total-img{
      width: 100px;
      height: 100px;
      background: #C9D9E9;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
    }
    .total-text{
      font-size: 12px;
      color: #333;
      font-weight: 600;
      margin-left: 20px;
      // padding-right: 150px;
      flex: 1;
      .row{
        display: flex;
        label{
          display: inline-block;
          width: 80px;
          text-align: right;
        }
        .col{
          flex: 1;
          >div{
            margin-top: 15px;
          }
        }
      }
      
    }
  }
  .wallet-lists{
    font-size: 12px;
    padding: 0 24px;
    margin-top: 40px;
    .lists-search{
      display: flex;
      align-items: center;
    }
    .lists-left{
      color: #999999;
      display: flex;
      align-items: center;
      >div{
        height: 35px;
        line-height: 35px;
        border: 1px solid #CCCCCC;
        padding:  0 15px;
        // border-radius: 8px;
        background: #fff;
        margin-right: 11px;
        cursor: pointer;
        color: #000000;
        &.tabShow{
          background: #3C76FA;
          color: #fff;
          border: 1px solid #3C76FA;
          box-shadow: 0px 4px 8px 0px rgba(60, 118, 250, 0.24);
        }
      }
    }
    .lists-right{
      margin-left: auto;
      display: flex;
      align-items: center;
      .block{
        margin-right: 12px;
        .demonstration{
          color: #000000;
          font-size: 14px;
        }
      }
      .search{
        width: 58px;
        height: 31px;
        background: #001B57;
        // background: linear-gradient(0deg, #30B1EA 0%, #69E5FE 100%);
        // box-shadow: 0px 13px 19px 0px rgba(58, 179, 236, 0.2);
        // border-radius: 6px;
        color: #fff;
        font-size: 14px;
        text-align: center;
        line-height: 31px;
        cursor: pointer;
      }
    }
    .lists-table{
      margin-top: 17px;
      /deep/.el-table{
        font-size: 12px;
        color: #666666;
        .el-table__body-wrapper{
          height: 360px !important;
          .el-table__body{
            width: 1067px !important;
          }
        }
      }
    }
    .lists-page{
      margin-top: 0px;
      /deep/.el-pagination{
        .el-pager{
          li{
            background: none;
            border: 1px solid #BDC8E4;
            color: #BDC8E4;
            &.active{
              border: 1px solid #515766;
              color: #515766;
            }
          }
        }
        .btn-next{
          padding: 0 5px;
          background: none;
          border: 1px solid #A0ACCB;
          color: #A0ACCB;
        }
        .btn-prev{
          padding: 0 5px;
          background: none;
          border: 1px solid #A0ACCB;
          color: #A0ACCB;
        }
      }
    }
  }
}


/* 充值网费弹窗*/
.network-tip{
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 32;
  width: 100%;
  height: 100vh;
  top: 0;
  .network-tip-warp{
    padding: 20px 30px 60px 30px;
    background: #fff;
    text-align: center;
    color: #333;
    position: relative;
  }

  .tip-close{   
    position: absolute;
    width: 40px;
    height: 40px;
    top: -26px;
    right: -20px;
    z-index: 44;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .network-all{
    text-align: left;
    .network-title{
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
    }
    .network-display{
      .network-money{
        display: flex;
        align-items: center;
      }
      .network-flex{
        display: flex;
        margin-top: 30px;
        .money-num{
          color: #FE3D3D;
        }
        .flex-display{
          display: flex;
          flex-wrap: wrap;
          
          max-width: 650px;
        }
        .flex-label{
          line-height: 60px;
          width: 74px;
        }
        .flex-bnt{
          // padding:  0 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 106px;
          height: 60px;
          font-size: 16px;
          border: 1px solid #ccc;
          border-radius: 8px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          &.flex-bnt-show{
            border: 1px solid #2C74FE;
            background: #F2F6FE;
            color: #1546E6
          }
          .bnt-item-text{
            margin-top: 5px;
            font-size: 12px;
          }
        }
        .flex-input{
          display: flex;
          align-items: center;
          >input{
            
            border-radius: 8px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0 10px;
            width: 86px;
            height: 60px;
            font-size: 12px;
            border: 1px solid #ccc;
          }
          .input-text{
            margin-left: 10px;
            margin-bottom: 10px;
            // line-height: 60px;
          }
        }
        .pay-label{
          line-height: 44px;
          width: 74px;

        }
        .pay-bnts{
          display: flex;
          margin-left: 20px;
          >div{
            width: 120px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            color: #fff;
            border-radius: 6px;
            cursor: pointer;
          }
          .zfb-add{
            margin-left: 20px;
            background: #5564F3;
            box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
          }
          .wechat-add{
            background: #5ED6FC;
            box-shadow: 0px 8px 19px 0px rgba(94, 214, 252, 0.3);
          }
          .lgb-add{
            margin-left: 20px;
            background: #54DDB0;
            box-shadow: 0px 8px 16px 0px rgba(84, 225, 177, 0.3);
          }
        }
      }
    }
  }

}

.order-tip{
  position: fixed;
  width: 440px;
  padding-bottom: 40px;
  top: 50%;
  left: 50%;
  background: #fff;
  text-align: center;
  border: 1px solid #eeeeee;
  // box-shadow: 0px 13px 31px 0px rgba(204, 219, 230, 0.35);
  transform: translate(-50%,-50%);
  z-index: 33;
  .tip-close{   
    position: absolute;
    width: 40px;
    height: 40px;
    top: -26px;
    right: -20px;
    z-index: 44;
    cursor: pointer;
    // background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #eeeeee;
    img{
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
    }
  }
  .tip-title{
    // margin-top: 30px;
    color: #1A1A1A;
    font-size: 16px;
    font-weight: bold;
    padding: 30px 40px;
  }
  .tip-data{
      // margin: 0 36px;
      color: #666666;
      font-size: 14px;
      .data-flex{
        padding: 20px 40px;
        display: flex;
        border-top: 1px solid rgba(225, 228, 242, 0.7);
        &:last-child{ 
          border-bottom: 1px solid rgba(225, 228, 242, 0.7);

        }
        >div{

        }
        >div:nth-child(2){
          width: 110px;
          margin-left: auto;
        }
        .flex-monry{
          color: #F84646;
        }
      }
    
  }
  p{
    padding: 0px 40px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-top: 37px;
  }
  .tip-bnt{
    padding: 0px 40px;
    display: flex;
    margin-top: 10px;
    >div{
      width: 100px;
      height: 44px;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      line-height: 44px;
      text-align: center;
      cursor: pointer;
    }
    .bnt-lgb{
      background: #30E8C3;
      box-shadow: 0px 8px 19px 0px rgba(48, 232, 195, 0.3);
      &.lgb-hide{
        background: rgba(198, 204, 237, 1);
        background: rgba(198, 204, 237, 0.3);
      }
    }
    .bnt-wechat{
      margin-left: 17px;
      background: #5ED6FC;
      box-shadow: 0px 8px 19px 0px rgba(94, 214, 252, 0.3);
    }
    .bnt-alipay{
      margin-left: 17px;
      background: #5564F3;
      box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
    }
  }
  .play-title{
    display: flex;
    padding: 30px 40px;
    .title-name{
      font-size: 16px;
      font-weight: bold;
      color: #1A1A1A;
    }
    .title-money{
      color: #999999;
      font-size: 12px;
      margin-left: auto;
      >span{
        color: #FF3030
      }
      .money-icon{
        font-size: 14px;
      }
      .money-num{
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
  .pay-code{
    width: 300px;
  }
  .pay-tip-text{
    font-size: 12px;
    color: #E14F4F;
    text-align: center;
  }
  .tip-lgb{
    padding: 0 40px;
    .lgb-input{
      height: 56px;
      background: #F3F5FC;
      input{
        width: 90%;
        height: 100%;
        padding: 0 10px;
      }
    }
    .lgb-bnts{
      display: flex;
      margin-top: 42px;
      font-size: 12px;
      >div{
        width: 88px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #fff;
        border-radius: 6px;
        cursor: pointer;
      }
    }
    .lgb-add{
      margin-left: 20px;
      background: #5564F3;
      box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
    }
    .wechat-add{
      background: #5ED6FC;
      box-shadow: 0px 8px 19px 0px rgba(94, 214, 252, 0.3);
    }
  }
  .tip-qr-code{
    padding: 0 50px;
    margin-top: 35px;
    .qr-img{
      width: 300px;
      height: 300px;
      margin: auto;
    }
    .qr-text{
      font-size: 12px;
      color: #E14F4F;
      margin: auto;
      text-align: center;
    }

  }
}
</style>
