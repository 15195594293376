<template>
  <div class="square">
    <div class="square-left">
      <div class="left-title">
        <div class="title-bnt">推荐</div>
        <div class="title-edit" @click="handleEditData">
          <img src="~@/assets/images/balance/square/edit.png" />
        </div>
        <div class="title-refresh" @click="handleRefreshData">
          <i class="el-icon-refresh-right"></i>
        </div>
      </div>
      <div class="left-list" id="left-list" ref="scrollBox" @scroll="scrollEvent " v-show="listData.length > 0">
        <div class="list-all" v-for="(item,idx) in listData" :key="idx">
          <div class="list-head">
            <div class="head-left">
              <img v-if="item.headImg" :src="item.headImg" />
              <img v-else src="~@/assets/images/balance/user/headImg.png" />
            </div>
            <div class="head-right">
              <div class="right-name">
                <div class="name-text">{{item.nickName}}</div>
                <img v-if="item.gender == 0" src="~@/assets/images/balance/square/sex1.png" />
                <img v-else src="~@/assets/images/balance/square/sex2.png" />
              </div>
              <div class="right-time">{{item.createTime}}</div>
            </div>
          </div>
          <div class="all-content">
            <span class="content-topicName" v-if="item.topicName">#{{item.topicName}}#</span>
            <span v-html="item.content.replace(/(\r\n|\n|\r)/gm,'<br />')"></span>
          </div>
          <div class="all-images" v-if="item.imgUrls.length > 0">
            <div class="images-one" v-if="item.imgUrls.length == 1">
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
            <div class="images-nine" v-else-if="item.imgUrls.length == 9">
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
            <div class="images-lists" v-else>
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
          </div>
          <div class="all-top">
            <div @click="handleLikeData(item,idx)">
              <div class="praise">
                <img v-if="item.isLike == 2" src="~@/assets/images/balance/square/praise.png" />
                <img v-else src="~@/assets/images/balance/square/praiseShow.png" />
              </div>
              <div>点赞{{item.likeCount}}</div>
            </div>
            <div @click="handleOpenMessageData(item)">
              <div class="comment">
                <img src="~@/assets/images/balance/square/comment.png" />
              </div>
              <div>评论 {{item.commentCount}}</div>
            </div>
            <div @click="handleRewardOpen(item,idx)">
              <div class="gift">
                <img src="~@/assets/images/balance/square/gift.png" />
              </div>
              <div>打赏</div>
              <div class="gift-money" v-if="item.rewardMoney > 0">{{item.rewardMoney}}</div>
            </div>
          </div>
        </div>
        <div v-if="loading" class="message-tip-show">没有更多数据了~</div>
        <div v-else class="message-tip-show">加载中...</div>
      </div>
      <div class="lists-no" v-show="listData.length == 0">
        <div class="no-data">
          <img src="~@/assets/images/balance/no-data.gif">
        </div>
      </div>
    </div>
    <div class="square-right">
      <div class="right-ranking">
        <div :class="'ranking-'+idx" v-for="(item,idx) in rankingArr" :key="idx">
          <div class="ranking-item">
            <div class="item-num" :class="'item-'+idx">{{idx+1}}</div>
            <div class="item-title">
              
              {{item.title}}
              <div class="item-tooltip " v-if="item.title.length>5">{{item.title}}</div>
            </div>
            <div class="item-time">{{ $utils.dateFormat(item.createTime,'yyyy-MM-dd')}}</div>
          </div>
        </div>
      </div>
      <div class="right-banner">
        <el-carousel height="100%" class="banner-carousel" arrow="never">
          <el-carousel-item v-for="(item,idx) in bannerArr" :key="idx">
            <div class="carousel-all" @click="handleImgOpen(item)">
              <img class="banner-img" :src="item.imgUrl "  />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right-advertising">
        <img v-if="advertisingArr.length>0" :src="advertisingArr[0].imgUrl" class="game-img"  @click="handleImgOpen(advertisingArr[0])">
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <div class="head-tip-bg" v-show="imgShow"></div>
    <div class="head-tip img-tip" v-show="imgShow">
      <div class="tip-close" style="-webkit-app-region: no-drag;" @click="handleImgTipData">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <Dynamic ref="dynamicChild"></Dynamic>
    </div>
    <!--回复弹窗 -->
    <div class="head-tip-bg" v-show="messageShow"></div>
    <div class="head-tip message-tip" v-show="messageShow">
      <div class="tip-close" @click="handleMessageCloseData" style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <Message ref="messageChild"></Message>
    </div>
    <!-- 打赏弹窗 -->
    <div class="reward-tip-bg" v-show="rewardShow" ></div>
    <div class="reward-tip" v-show="rewardShow">
      <div class="reward-close" @click="handleRewardCloseData" style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <div class="reward-item">
        <div class="reward-title">打赏{{rewardObj.nickName}}</div>
        <div class="reward-con">
          <div class="con-flex">
            <div @click="handleRewardItemData(item,idx)" :class="{'reward-name': idx == rewardIdx}" v-for="(item,idx) in rewardList" :key="idx">{{item.name}}</div>
           
          </div>
          <div class="con-input">
            <div class="input-title"> 
              <div class="title-show"></div>
              <div class="title-text">可用网费{{info.presentedMoney}}</div>
            </div>
            <el-input class="input-item"  v-model="rewardForm.rewardMoney" maxlength="4" placeholder="请输入要打赏的网费" @input="handleRewardMoneyData" ></el-input>
            <!-- <input class="input-item" @input="handleRewardMoneyData" v-model="rewardForm.rewardMoney"  maxlength="4" placeholder="请输入要打赏的网费"/> -->
          </div>
        </div>
        <div class="reward-foot" v-loading="rewardLoading" @click="handleAddgiveReward">确认</div>
      </div>

    </div>
    <!-- 照片弹窗 -->
    <div class="image-show-big" v-show="imgBigShow" @click="handleImgBigClose"></div>
    <div class="image-pre"  v-show="imgBigShow" @click="handlePreBigImg">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="image-show"  v-show="imgBigShow">
      <div class="show-item">
        <img :src="bigImgUrl"/>
        <div class="item-page">
          <div class="page-idx" :class="{'page-show':idx == imgBigIdx}" v-for="(item,idx) in bigImgs" :key="idx"></div>
          <!-- <div class="page-show"></div>
          <div class="page-idx"></div> -->
        </div>
      </div>
    </div>
    <div class="image-next"  v-show="imgBigShow"  @click="handleNextBigImg">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
const {shell} = window.require('electron');
import {
  squareList,
  rankingList,
  topicFirstList,
  topicList,
  giveLike,
  publish,
  squareInfo,
  giveReward
} from "@/api/balance/square";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  userinfo
} from "@/api/wallet";
import { slideshow } from "@/api/balance/first";
import Message from "./components/message";
import Dynamic from "./components/dynamic";
export default {
  name: "square",
  data() {
    return {
      rewardShow: false,
      headers: {
        secret: ""
      },
      sortIdx: 1,
      immList: [
        {
          id: 1
        }
      ],
      tagTablist: [
        // 存放一级话题标签
        { name: "推荐" },
        { name: "心情" },
        { name: "生活" },
        { name: "热门游戏" },
        { name: "其他" }
      ],
      tagShow: false,
      imgShow: false,
      messageShow: false,
      tagTabIdx: 0,
      rankingArr: [],
      bannerArr: [],
      topicLists: [], // 存放二级话题
      listData: [], // 存放动态
      squareInfo: {}, // 保存动态详情
      active: 1,
      loading: false,
      totalNum: 0,
      pageSize: 10,
      imgBigShow: false,
      bigImgs:[],
      bigImgUrl: '',
      imgBigIdx: 0,
      rewardObj: {}, // 保存打赏信息
      rewardList: [
        {
          id: 1,
          name: '打赏网费',
        },
        // {
        //   id: 2,
        //   name: '打赏霖感币',
        // },
      ],
      rewardIdx: 0,
      rewardForm:{
        dynamicId: '',
        type: 1,
        rewardMoney: null,
        lgUserId: '',
      },
      openRewardIdx: 0,
      rewardLoading: false,
      info: {},
      advertisingArr: []
    };
  },
  components: { Message, Dynamic },
  computed: {},
  methods: {
    // 刷新
    handleRefreshData() {
      this.handleParamsData()
    },
    // 点击轮播图
    handleImgOpen(item) {
      if(item.clickType == 1) {
        if(item.clickUrl) {
          shell.openExternal(item.clickUrl)
        }
      } else{
        
        if(item.clickUrl =='/shop') {
          if(window.Qbian){
            var ipc = window.Qbian.ipc;
            ipc.send('window-shop-open');
          }
          this.$store.state.bntType = 6
          return
        } else if(item.clickUrl =='/first') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 6
        }else if(item.clickUrl =='/userInfo') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 5
        }else if(item.clickUrl =='/game') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 7
        }else if(item.clickUrl =='/guessing') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 8
        }else if(item.clickUrl =='/square') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 9
        }else if(item.clickUrl =='/wallet') {
          this.$router.push(item.clickUrl)
          this.$store.state.bntType = 11
        }else{
          this.iframeUrl = item.clickUrl
          let width =  1200/100
          let height =  800/100
          this.tipWidth = (width*item.openSize).toFixed()  + 'px'
          this.tipHeight = (height*item.openSize).toFixed() + 'px'
          this.tipShowHide = true

        }
      }
    },
    //获取个人信息
    handleUserinfo() {
      userinfo().then(res => {
        if (res.code == 200) {
          this.info = JSON.parse(decryptByDESModeCBC(res.msg));
          return false;
        }
      }).catch(res=>{
      });

    },
    handleRewardMoneyData(data) {
      if(data > this.info.presentedMoney) {
        this.rewardForm.rewardMoney = this.info.presentedMoney
      }
    },
    hasDot(num){
      if(!isNaN(num)){
        return ( (num + '').indexOf('.') != -1 ) ? num: num.toFixed(2);   
      }
    },
    // 提交打赏
    handleAddgiveReward() {
      if(!this.rewardForm.rewardMoney) {
        this.$message.error('请输入打赏的金额!');
        return
      }
      if(this.rewardForm.rewardMoney<1) {
        this.$message.error('输入的金额大于或等于1~');
        return
      }
      this.rewardForm.dynamicId = this.rewardObj.id
      this.rewardForm.lgUserId = sessionStorage.lgUserId
      this.rewardLoading = true
      this.rewardForm.rewardMoney = Number(this.rewardForm.rewardMoney)
      // return
      giveReward(this.rewardForm).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg || '打赏成功!',
              type: 'success',
              customClass: 2000
          });
          let money  = this.rewardObj.rewardMoney + this.rewardForm.rewardMoney
          // this.listData[this.openRewardIdx].rewardMoney =this.hasDot(money)
          
          var y = String(money).indexOf(".") + 1;//获取小数点的位置
          if(y>0) {
            this.listData[this.openRewardIdx].rewardMoney =money.toFixed(2)

          }else{

            this.listData[this.openRewardIdx].rewardMoney =money
          }  
          this.handleUserinfo()
          // this.handleParamsData()
        }else{
          this.$message.error( res.msg || '打赏失败!');

        }
        this.handleRewardCloseData();
      }).catch(res=>{
        // this.$message.error( res.msg || '打赏失败!');
        this.handleRewardCloseData();
      });;

    },
    //选择打赏类型 
    handleRewardItemData(item,idx) {
      this.rewardForm.type = item.id
    },
    // 上一张
    handlePreBigImg() {
      if(this.imgBigIdx == 0) {
        this.imgBigIdx = 0
      }else {
        this.imgBigIdx--
        this.bigImgUrl = this.bigImgs[this.imgBigIdx].imgUrl
      }
    },
    // 下一张
    handleNextBigImg() {
      if(this.imgBigIdx == this.bigImgs.length-1) {
        this.imgBigIdx = this.bigImgs.length-1
      }else {
        this.imgBigIdx++
        this.bigImgUrl = this.bigImgs[this.imgBigIdx].imgUrl
      }

    },
    // 打开大图
    handleImgBigOpen(item,idx,imgIdx) {
      this.imgBigIdx = imgIdx
      this.bigImgUrl =  item.imgUrls[imgIdx].imgUrl
      this.bigImgs = item.imgUrls
      this.imgBigShow = true
    },
    // 关闭查看大图
    handleImgBigClose() {
      this.imgBigShow = false
    },
    handleRewardOpen(item,idx) {
      this.openRewardIdx = idx
      this.rewardForm.type = 1
      this.rewardShow = true
      this.rewardObj = item

    },
    // 打赏关闭
    handleRewardCloseData() {
      this.rewardShow = false
      this.rewardLoading = false
      this.rewardForm ={
        dynamicId: '',
        type: 1,
        rewardMoney: '',
        lgUserId: '',
      }
    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#left-list").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 50) {
        if (
          this.listData.length > this.totalNum ||
          this.totalNum == this.listData.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    loadMore() {
      this.handleSquareList();
    },
    handleCloseImg() {
      this.imgShow = false;
    },
    // 点赞
    handleLikeData(item, idx) {
      let status;
      if (item.isLike == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        dynamicId: item.id,
        lgUserId: sessionStorage.lgUserId,
        type: 1,
        isLike: status
      };
      giveLike(params).then(res => {
        if (res.code == 200) {
          this.$refs.messageChild.handleSquareInfo(item);

          // this.handleParamsData();
        }
      });
    },
    // 关闭发布动摇弹窗
    handleImgTipData() {
      this.imgShow = false;
      this.$refs.dynamicChild.handleAllDeleteImg();
    },
    //关闭标签弹窗
    handleTagCloseData() {
      this.tagShow = false;
    },
    // 点击标签tab
    handleTagTabData(item, idx) {
      this.tagTabIdx = idx;
      this.handletopicList(item.id);
    },
    // 标签弹窗
    handleTagData() {
      this.tagShow = true;
      this.handleTopicFirstList();
    },
    // 打开编辑弹窗
    handleEditData(item) {
      this.imgShow = true;
      this.$refs.dynamicChild.handleMachineData();
    },
    // 打开评论弹窗
    handleOpenMessageData(item) {
      this.messageShow = true;
      this.$refs.messageChild.handleSquareInfo(item);
      this.$refs.messageChild.handlecommentList(item);
    },
    // 关闭评论弹窗
    handleMessageCloseData() {
      this.messageShow = false;
      // this.handleParamsData();
    },
    // 一级话题列表
    handleTopicFirstList() {
      topicFirstList().then(res => {
        if (res.code == 200) {
          this.tagTablist = res.data;
          if (this.tagTablist.length > 0) {
            this.handletopicList(this.tagTablist[0].id);
          }
        }
      });
    },
    // 二级话题列表
    handletopicList(parentId) {
      topicList({ parentId: parentId }).then(res => {
        if (res.code == 200) {
          this.topicLists = res.rows;
          // this.tagTablist = res.data
        }
      });
    },
    handleParamsData() {
      this.listData = [];
      this.active = 1;
      this.handleSquareList();
    },
    // 动态列表
    handleSquareList() {
      let params = {
        lgUserId: sessionStorage.lgUserId,
        pageSize: this.pageSize,
        pageNum: this.active
      };
      squareList(params).then(res => {
        if (res.code == 200) {
          let arr = res.rows;
          this.totalNum = res.total;
          this.listData = this.listData.concat(res.rows);
          this.loading = this.active < res.total;
        }
      });
    },
    // 首次加载
    firstData() {
      this.handleSquareList();

      rankingList().then(res => {
        if (res.code == 200) {
          this.rankingArr = res.data;
        }
      });
      slideshow({ type: 4 }).then(res => {
        if (res.code == 200) {
          this.bannerArr = res.data;
        }
      });
      slideshow({ type: 7 }).then(res => {
        if (res.code == 200) {
          this.advertisingArr = res.data;
        }
      });
    },
    // 子组件刷新数据
    handleMessageChildData(item){
      for(let i in this.listData) {
        if(this.listData[i].id == item.id) {
          this.listData[i] = item 
        }
      }
      this.$forceUpdate();

    }
  },
  created() {
    this.headers.secret = sessionStorage.secret;
    this.$nextTick(() => {
      this.firstData();
    });
  },
  mounted() {
    this.handleUserinfo()
    // 监听外部盒子的滚动事件
    this.$refs.scrollBox.addEventListener("scroll", this.scrollEvent);
    // window.addEventListener('scroll', this.scrollEvent)
  },
  destroyed() {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
<style lang="less" scoped>
  .lists-no{
    width: 100%;
    height: 600px;
    position: relative;
  }
  .no-data{
    text-align: center;
    position: absolute;
    color: #999;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 3;
    >img{
      // width: 184px;
      // height: 160px;
    }
  }
.reward-tip-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 88;

}
.reward-tip{
  position: fixed;
  width: 410px;
  top: 315px;
  left: 50%;
  background: #fff;
  padding: 30px 63px 30px 28px;
  transform: translate(-50%,-50%);
  z-index: 99;
  .reward-close{
    position: absolute;
    width: 40px;
    height: 40px;
    top: -26px;
    right: -20px;
    z-index: 44;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }

  } 
  .reward-item{
    .reward-title{
      font-size: 16px;
      font-weight: bold;
    }
    .reward-con{
      margin-top: 30px;
      .con-flex{
        display: flex;
        >div{
          width: 140px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          color: #666666;
          cursor: pointer;
          &.reward-name{
            border: 1px solid #5564F3;
            color: #5564F3;
          }
          &:nth-child(2){
            margin-left: 20px;
          }
        }
      }
      .con-input{
        margin-top: 30px;
        .input-title{
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .title-show{
            width: 6px;
            height: 6px;
            background: #5564F3;
            border-radius: 50%;
          }
          .title-text{
            margin-left: 11px;
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }
        .input-item{
          width: 400px;
          height: 40px;
          background: #FFFFFF;
          // border: 1px solid #CCCCCC;
          border-radius: 5px;
          // padding: 0 10px;
          font-size: 14px;
          .el-input__inner{
            border: none;
            background: none;
          }
        }
      }
    }
  }
  .reward-foot{
    margin-top: 44px;
    width: 88px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #5564F3;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    box-shadow: 0 10px 15px rgba(85, 100, 243, 0.2);
    cursor: pointer;
  }
}
.image-show-big{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.9);
  z-index: 66;

}
.image-show{
  position: fixed;
  max-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 77;
  .show-item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 600px;
    text-align: center;
    >img{
      max-width: 100%;
    }
    .item-page{
      display: flex;
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 78;
      >div{
        margin-right: 4px;
        background:   #73edff;
        border-radius: 2px;
        &:last-child{
        margin-right: 0px;

        }
      }
      .page-show{
        width: 19px !important;
        height: 4px ;
      }
      .page-idx{
        width: 5px;
        height: 4px;
      }
    }
  }
  
}
  .image-pre,.image-next{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 13px;
    background: #999999;
    z-index: 78;
    cursor: pointer;
    >i{
      font-size: 18px;
      color: #FFFFFF;
    }
  }
  .image-pre{
    top: 50%;
    left: 5%;
    transform: translate(-50%,-50%);

  }
  .image-next{
    top: 50%;
    right: 0%;
    transform: translate(-50%,-50%);

  }
</style>
