<template>
  <div class="material">
    <div class="material-head">
      <div class="head-imgs">
        <img class="head-img" v-if="!addForm.auditHeadImg" :src="addForm.headImg" />
        <img class="head-img" v-else :src="addForm.auditHeadImg" />

        <img
          class="head-btn"
          @click="handleHeadBntData"
          src="~@/assets/images/balance/user/imgIcon.png"
        />
      </div>
      <div class="head-text">
        <div class="text-nickname" v-if="!nickNameShow">{{addForm.nickName}}</div>
        <el-input
          class="nickname-input"
          maxlength="10"
          v-else
          v-model="addForm.nickName"
          placeholder="请输入昵称"
        ></el-input>
        <img
          class="head-btn"
          src="~@/assets/images/balance/user/edit.png"
          @click="handleNickNameData"
        />
        <div class="text-tip">（限10个字符）</div>
      </div>
    </div>
    <div class="material-sex">
      <div>性别：</div>
      <img v-if="addForm.gender" src="~@/assets/images/balance/user/sex1.png" />
      <img v-else src="~@/assets/images/balance/user/sex.png" />
    </div>
    <div class="material-birthday">
      <div>生日：</div>
      <el-date-picker
        format="yyyy 年 MM 月 dd 日"
        @change="handleBirthday"
        value-format="yyyy-MM-dd"
        v-model="addForm.birthday"
        type="date"
        placeholder="选择日期"
      ></el-date-picker>
    </div>
    <div class="material-Constellation">
      <div>
        星座：
        <span>{{addForm.sign || '--'}}</span>
      </div>
    </div>
    <div class="material-job">
      <div>职业：</div>
      <el-select v-model="addForm.profession" placeholder="请选择">
        <el-option v-for="item in jobOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="material-address">
      <div>所在地：</div>
      <div class="address-flex">
        <el-select v-model="province" @change="handleCityData" placeholder="请选择省/市">
          <el-option
            v-for="item in provinceList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select v-model="city" @change="handleCountyData" placeholder="请选择市/区">
          <el-option
            v-for="item in cityList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select v-model="county" placeholder="请选择区/县">
          <el-option
            v-for="item in countyList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></el-option>
        </el-select>
      </div>
      <!-- <el-cascader
        v-if="addressList.length > 0"
        v-model="addForm.address"
        :options="addressList"
        :props="{ expandTrigger: 'hover',value: 'code',label: 'name',children: 'child', }"
        @change="handleAddressChange"
      ></el-cascader>-->
    </div>
    <div class="material-signature">
      <div>个性签名：</div>
      <el-input
        type="textarea"
        :rows="1"
        maxlength="50"
        placeholder="请输入  （限50字内）"
        v-model="addForm.signature"
      ></el-input>
    </div>
    <div class="material-tag">
      <div>
        我的标签：
        <span>（最多可选5个）（{{tagArr.length}}/5）</span>
      </div>
      <div class="tab-list">
        <div
          class="list-bnt"
          @click="handleTagData(items,idx)"
          :class="{'list-bnt-show': items.selectd == true}"
          v-for="(items,idx) in tagList"
          :key="idx"
        >{{items.name}}</div>
      </div>
    </div>
    <div class="material-add" @click="handleaddUserInfo">保存</div>

    <!-- 上传头像弹窗 -->
    <div class="head-tip-bg" v-show="headShow"></div>
    <div class="head-tip" v-show="headShow">
      <div class="tip-close" @click="handleHeadCloseData">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <div class="tip-title">
        上传头像
        <span v-if="addForm.auditHeadImg" class="tip-title-text">头像正在审核中!</span>
      </div>
      <div class="tip-bg">
        <div class="bg-show">
          <img v-if="addForm.auditHeadImg || headImg" :src="headImg" />
        </div>
      </div>
      <div class="tip-bnt">
        <div class="bnt-upload">
          <el-upload
            class="avatar-uploader"
            ref="upload"
            action="/zd-api/web/client/user/uploadPhotos"
            :data="{lgUserId:lgUserId,type: 1}"
            :headers="headers"
            name="files"
            list-type="picture"
            :multiple="false"
            :limit="2"
            :on-change="handleHeadImmgChange"
            :before-upload="handlePreview"
            :on-remove="handleRemove"
            :show-file-list="false"
            :auto-upload="false"
          ></el-upload>上传头像
        </div>
        <div class="bnt-add" @click="handleAddHeadImg">确认头像</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getUserInfo,
  getProfession,
  getTag,
  uploadPhotos,
  editUserInfo,
  getProvince
} from "@/api/userInfo";
import { userinfo } from "@/api/wallet";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC,
  getAstro
} from "@/common/utils/des";

export default {
  name: "material",
  data() {
    return {
      headImg: "",
      userInfo: {},
      lgUserId: "",
      certification: 0,
      addForm: {
        nickName: "张三",
        phone: "", //手机号码
        headImg: require("@/assets/images/balance/user/headImg.png"), // 头像
        gender: 0, // 性别
        birthday: "", // 生日
        profession: "", // 职业id
        address: [], // 所在地
        signature: "", // 个性签名
        lgUserTag: [], // 用户标签
        sign: "" // 星座
      },
      addressList: [],
      rightTab: [
        {
          name: "基本资料"
        },
        {
          name: "相册管理"
        }
      ],
      idxShow: 0,
      rightIdx: 0,
      tagList: [],
      tagArr: [],
      headShow: false,
      jobOptions: [],
      nickNameShow: false,
      headFile: null, // 保存上传文件
      fileList: [],
      headers: {
        secret: ""
      },
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: ""
      // lazy: true,
    };
  },
  components: {},
  computed: {},
  methods: {
    handleBirthday(data) {
      this.addForm.birthday = this.$utils.dateFormat(data, "yyyy-MM-dd");
      let time = this.$utils.dateFormat(this.addForm.birthday, "MM-dd");
      let arr = time.split("-");
      this.addForm.sign = getAstro(arr[0], arr[1]);
    },
    // 昵称切换输入
    handleNickNameData() {
      this.nickNameShow = !this.nickNameShow;
    },
    // 关闭上传头像弹窗
    handleHeadCloseData() {
      this.headShow = false;
    },
    // 打开上传头像弹窗
    handleHeadBntData() {
      if (this.addForm.auditHeadImg) {
        this.headImg = this.addForm.auditHeadImg;
      } else {
        this.headImg = "";
      }
      this.headShow = true;
    },
    handleAddHeadImg() {
      this.$refs.upload.submit();
    },
    handleDelectImg() {
      this.handleRemove();
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {}, // 封装一个判断图片文件后缀名的方法
    isImage(fileName) {
      if (typeof fileName !== "string") return;
      let name = fileName.toLowerCase();
      return (
        name.endsWith(".png") ||
        name.endsWith(".jpeg") ||
        name.endsWith(".jpg") ||
        name.endsWith(".png") ||
        name.endsWith(".bmp") ||
        name.endsWith(".gif")
      );
    },
    handleHeadImmgChange(file, fileList) {
      let type = this.isImage(file.name);
      if (!type) {
        this.$message.error("只允许上传图片!");
        return;
      }
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      if (file.response) {
        this.headShow = false;
        if (file.response.code == 200) {
          this.handleGetUserInfo();
          this.$message({
            message: file.response.msg || "操作成功!",
            type: "success",
            customClass: 2000
          });
        } else {
          this.$message.error(file.response.msg || "操作失败!");
        }
      }
      this.headImg = URL.createObjectURL(fileList[0].raw);
    },
    /**
     * 标签选择
     */
    handleTagData(item, idx) {
      if (this.tagList[idx].selectd == true) {
        this.tagList[idx].selectd = false;
        for (let i in this.tagArr) {
          if (this.tagArr[i].id == item.id) {
            this.tagArr.splice(i, 1);
          }
        }
      } else {
        if (this.tagArr.length < 5) {
          let obj = item;
          this.tagList[idx].selectd = true;
          this.tagArr.push(obj);
        }
      }
    },
    /**
     * 获取个人信息
     */
    handleGetUserInfo() {
      getUserInfo({ lgUserId: this.lgUserId }).then(res => {
        if (res.code == 200) {
          this.userInfo = res.data;
          this.certification = res.data.certification;
          this.lgUserId = res.data.lgUserId;
          sessionStorage.certification = res.data.certification;
          sessionStorage.playWith = res.data.playWith;
          sessionStorage.phone = res.data.phone;
          let arr = res.data.lgUserTag;
          if (res.data.lgUserTag.length > 0) {
            for (let j in this.tagList) {
              for (let i in arr) {
                if (arr[i].tagId == this.tagList[j].id) {
                  let obj = this.tagList[j];
                  this.tagArr.push(obj);
                  this.tagList[j].selectd = true;
                }
              }
            }
          }
          let addressList;
          this.addForm.address = [];
          let arrAdd = [];
          if (res.data.address) {
            addressList = res.data.address.split(",");
            this.province = Number(addressList[0]);
            this.city = Number(addressList[1]);
            this.county = Number(addressList[2]);
            this.handleCityData(this.province);
            this.handleCountyData(this.city);
          }
          this.addForm = Object.assign(this.addForm, res.data);
          if (this.addForm.birthday) {
            this.addForm.birthday = this.$utils.dateFormat(
              this.addForm.birthday,
              "yyyy-MM-dd"
            );
            let time = this.$utils.dateFormat(this.addForm.birthday, "MM-dd");
            let arr = time.split("-");
            this.addForm.sign = getAstro(arr[0], arr[1]);
          }
        }
      });
    },
    /**
     * 获取职位
     */
    getProfession() {
      getProfession().then(res => {
        if (res.code == 200) {
          this.jobOptions = res.data;
        }
      });
    },
    /**
     * 获取标签
     */
    getTag() {
      getTag().then(res => {
        if (res.code == 200) {
          this.tagList = res.data;
        }
      });
    },

    handleaddUserInfo() {
      // 修改个人信息
      let tagIds = "";
      if (this.tagArr.length > 0) {
        for (let i in this.tagArr) {
          if (this.tagArr.length - 1 == i) {
            tagIds += this.tagArr[i].id;
          } else {
            tagIds += this.tagArr[i].id + ",";
          }
        }
      }
      let address;
      if (this.province && this.city && this.county) {
        address = this.province + "," + this.city + "," + this.county;
      } else if (this.province && this.city) {
        address = this.province + "," + this.city;
      } else {
        address = this.province;
      }
      let params = {
        lgUserId: this.lgUserId,
        birthday: this.addForm.birthday,
        address: address,
        signature: this.addForm.signature,
        profession: this.addForm.profession,
        nickName: this.addForm.nickName,
        sign: this.addForm.sign,
        userTag: tagIds
      };
      let _this = this;
      editUserInfo({
        data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
      }).then(res => {
        if (res.code == 200) {
          _this.$message({
            message: res.msg,
            type: "success",
            customClass: 2000
          });
        } else {
          _this.$message.error(res.msg || "操作失败!");
        }
      });
    },
    // 选择省级
    handleProvinceData() {
      getProvince({ type: 1 }).then(res => {
        if (res.code == 200) {
          this.provinceList = res.data;
        }
      });
    },
    // 选择市级
    handleCityData(item) {
      getProvince({ type: 2, code: item }).then(res => {
        if (res.code == 200) {
          this.cityList = res.data;
          if (this.cityList.length < 1) {
            this.city = "";
            this.county = "";
            this.cityList = [];
            this.countyList = [];
          }
        }
      });
    },
    // 选择县级
    handleCountyData(item) {
      getProvince({ type: 3, code: item }).then(res => {
        if (res.code == 200) {
          this.countyList = res.data;
        }
      });
    }
  },
  beforecreate() {},
  created() {
    this.getTag();
    this.handleProvinceData();
    this.getProfession();
    this.headers.secret = sessionStorage.secret;
  },
  mounted() {
    this.lgUserId = sessionStorage.lgUserId;
    this.handleGetUserInfo();
  },
  destroyed() {}
};
</script>
<style lang="less" >
.material {
  height: 530px;
  margin-top: 25px;
  margin-left: 5px;
  color: #333333;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &.material::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .material-head {
    display: flex;
    .head-imgs {
      position: relative;
      .head-img {
        width: 61px;
        height: 61px;
        border-radius: 50%;
      }
      .head-btn {
        position: absolute;
        width: 32px;
        height: 32px;
        bottom: -5px;
        right: -15px;
        z-index: 3;
        cursor: pointer;
      }
    }
    .head-text {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .text-nickname {
        font-size: 14px;
      }
      .text-nickname {
        // width: 80px;
      }
      .nickname-input {
        width: 160px;
        /deep/.el-input__inner {
          padding: 0;
          border: none;
          background: none;
          outline: none;
          // border-bottom: 1px solid #E1E8F2;
        }
      }
      .head-btn {
        width: 14px;
        height: 14px;
        margin-left: 8px;
      }
      .text-tip {
        margin-left: 20px;
        color: #bebfcc;
        font-size: 11px;
      }
    }
  }
  .material-sex {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 12px;
    > div {
    }
    > img {
      width: 11px;
      height: 11px;
      margin-left: 15px;
    }
  }
  .material-birthday {
    display: flex;
    align-items: center;
    margin-top: 15px;
    > div {
      font-size: 12px;
    }
    > div:nth-child(2) {
      color: #666666;
    }
    /deep/.el-input__inner {
      border: none;
      background: none;
      border-bottom: 1px solid #e1e8f2;
    }
  }
  .material-Constellation {
    margin-top: 15px;
    span {
      color: #666666;
    }
  }
  .material-job {
    display: flex;
    align-items: center;
    margin-top: 15px;
    /deep/.el-input__inner {
      border: none;
      background: none;
      border-bottom: 1px solid #e1e8f2;
      outline: none;
    }
  }
  .material-address {
    display: flex;
    align-items: center;
    margin-top: 16px;
    /deep/.el-input__inner {
      border: none;
      background: none;
      border-bottom: 1px solid #e1e8f2;
      outline: none;
      margin-right: 20px;
    }
    .address-flex {
      display: flex;
    }
  }
  .material-signature {
    display: flex;
    align-items: center;
    margin-top: 16px;
    /deep/.el-textarea {
      max-width: 500px;
      min-width: 300px;
    }
    /deep/.el-textarea__inner {
      border: none;
      background: none;
      border-bottom: 1px solid #e1e8f2;
      outline: none;
    }
  }
  .material-tag {
    margin-top: 20px;
    span {
      color: #9a9ba6;
    }
    .tab-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      max-width: 750px;
      .list-bnt {
        width: 88px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        margin-right: 12px;
        margin-top: 8px;
        background: #ffffff;
        box-shadow: 0px 6px 25px 0px rgba(204, 219, 230, 0.3);
        border-radius: 4px;
        border: 2px solid #ffffff;
        cursor: pointer;
        &.list-bnt-show {
          border-color: #0c26e3;
          color: #0c26e3;
        }
      }
    }
  }
  .material-add {
    width: 80px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    border-radius: 6px;
    margin-top: 18px;
    background: linear-gradient(0deg, #54ddb0 0%, #49f367 100%);
    box-shadow: 0px 10px 26px rgba(84, 225, 177, 0.3);
    cursor: pointer;
    margin-bottom: 30px;
  }
  .head-tip-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 22;
  }
  .head-tip {
    position: fixed;
    width: 345px;
    height: 563px;
    padding: 0 46px;
    top: 55%;
    left: 50%;
    background: #fff;
    // box-shadow: 0px 13px 31px 0px rgba(204, 219, 230, 0.35);
    transform: translate(-50%, -50%);
    z-index: 33;
    .tip-close {
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tip-title {
      margin-top: 30px;
      color: #1a1a1a;
      font-size: 16px;
      font-weight: bold;
      .tip-title-text {
        margin-left: 10px;
        font-size: 12px;
        color: red;
        font-weight: 300;
      }
    }
    .tip-bg {
      width: 100%;
      height: 346px;
      background: #dce5ee;
      border: 6px;
      margin-top: 38px;
      .bg-show {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #e7eef6;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
    .tip-bnt {
      display: flex;
      margin-top: 54px;
      > div {
        width: 88px;
        height: 38px;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
      }
      .bnt-upload {
        position: relative;
        background: #5ed6fc;
        box-shadow: 0px 8px 19px 0px rgba(94, 214, 252, 0.3);
        .avatar-uploader {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 3;
          /deep/.el-upload {
            width: 100%;
            height: 100%;
          }
        }
      }
      .head-uploader {
        display: none;
        // opacity: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .bnt-add {
        margin-left: 23px;
        background: #5564f3;
        box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
      }
    }
  }
}
</style>
