<template>
  <div class="messageSquare">
    <div class="message-head">
      <div class="head-user">
        <img class="user-img" :src="squareDetails.headImg"/>
        <div class="user-text">
          <div class="text-name">
            <div>{{squareDetails.nickName}}</div>
            <img v-if="squareDetails.gender == 0" src="~@/assets/images/balance/square/sex1.png"/>
            <img v-else src="~@/assets/images/balance/square/sex2.png"/>
          </div>
          <div class="text-time">{{squareDetails.createTime}}</div>
        </div>
      </div>
      <div class="head-text">
        <div class="text-item">
          <span class="content-topicName" v-if="squareDetails.topicName">#{{squareDetails.topicName}}#  </span>
          <span v-html="squareDetails.content.replace(/(\r\n|\n|\r)/gm,'<br />')"></span>
        </div>
        <div class="text-imgs" v-if="squareDetails.imgUrls.length == 1" >
          <div class="imgs-list-mor" v-for="(item,idx) in squareDetails.imgUrls" :key="idx" @click="handleSquareImgs(squareDetails,idx)">
            <img :src="item.imgUrl"/>
          </div>
        </div>
        <div class="text-imgs" v-else>
          <div class="imgs-list" v-for="(item,idx) in squareDetails.imgUrls" :key="idx" @click="handleSquareImgs(squareDetails,idx)">
            <img :src="item.imgUrl"/>
          </div>
        </div>
      </div>
      <div class="head-status">
        <div @click="handleLikeData(squareDetails,0)">
          <div class="praise">
            <img v-if="squareDetails.isLike == 2" src="~@/assets/images/balance/square/praise.png"/>
            <img v-else src="~@/assets/images/balance/square/praiseShow.png">
          </div>
          <div>点赞{{squareDetails.likeCount}}</div>
        </div>
        <div>
          <div class="comment">
            <img src="~@/assets/images/balance/square/comment.png"/>
          </div>
          <div>评论 {{squareDetails.commentCount}}</div>
        </div>
        <div @click="handleMessageReward(squareDetails)">
          <div class="gift">
            <img src="~@/assets/images/balance/square/gift.png"/>
          </div>
          <div>打赏 </div>
        </div>
      </div>
    </div>
    <div class="message-content">
      <div class="content-person">
        <span v-for="(item,idx) in squareDetails.userList" :key="idx">
          <span v-if=" squareDetails.userList.length-1 == idx">
            {{item.nickName}}
          </span>
          <span v-else>
            {{item.nickName + '、'}} 

          </span>
        </span>
        等{{squareDetails.likeCount > 999?squareDetails.likeCount+ '+':squareDetails.likeCount}}人觉得很赞

      </div>
      <div class="content-list" v-for="(item,idx) in messageLists" :key="idx">
        <div class="list-left">
          <img :src="item.headImg"/>
        </div>
        <div class="list-right">
          <div class="right-name">
            <div>{{item.nickName}}</div>
            <img v-if="item.gender == 0" src="~@/assets/images/balance/square/sex1.png"/>
            <img v-else src="~@/assets/images/balance/square/sex2.png"/>
          </div>
          <div class="right-text" @click="handleToContentData(item)">
            <div class="text-content">{{item.content}}</div>
            
          </div>
          <div class="right-time">
            <div class="time-show">
              {{item.createTime || '8-16   19:49'}}
            </div>
            <div class="text-likeCount" @click.stop="handleContentLikeData(item,idx)">
              <div class="praise">
                <img v-if="item.isLike == 2" src="~@/assets/images/balance/square/praise.png"/>
                <img v-else src="~@/assets/images/balance/square/praiseShow.png">
              </div>
              <div>点赞{{item.likeCount}}</div>
            </div>

          </div>
          <div class="right-names" v-if="item.secondCommentDTO">
            <span class="names-all">
              <span @click="handleToMessage(item,idx)">{{item.secondCommentDTO.lgUserName}}</span>
              回复
              <span @click="handleFormMessage(item,idx)">{{item.secondCommentDTO.commentUserName}}：</span>
              {{item.secondCommentDTO.content}}
            </span>
          </div>
          <div v-if="item.secondCommentList.length > 0 && item.commentCount>2 || item.commentCount == 2">
            <div class="right-names" v-for="(mesItem,index) in  item.secondCommentList" :key="index">
              <span class="names-all">
                <span  @click="handleSecondCommentDTO(mesItem,item)">{{mesItem.lgUserName}}</span>
                回复
                <span  @click="handleFormContentData(mesItem,item)">{{mesItem.commentUserName}}：</span>
                {{mesItem.content}}
              </span>
            </div>
          </div>
          <div class="right-other-mor" @click="handleMessageMor(item,idx)" v-if="(item.commentCount>1 && !item.messageStatus)" >
            查看更多评论 <i class="el-icon-arrow-down"></i>
          </div>
          <div class="right-other-mor" @click="handleMessageMor(item,idx)" v-if="( item.twoNum > item.secondCommentList.length && item.messageStatus)" >
            查看更多评论 <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="message-foot">
      <input :placeholder="placeholderText" @keyup.delete='handleDelectMessage'  v-model="messageText" @keyup.enter="handleAddMessage" />
      <div class="foot-bnt" @click="handleAddMessage">发送</div>
    </div>
  </div>
</template>

<script>
import { squareInfo,commentList,twoList,commentMessage ,giveLike } from "@/api/balance/square";
export default {
  name: "message",
  data() {
    return{
      messageText: '', // 保存评论文字
      squareDetails: {
        content: '',
        imgUrls: []
      }, // 保存动态详情
      messageLists: [],
      info: {},
      toContent: false,
      addForm: {
        lgUserId: '',
        dynamicId: '',
        parentCommentId: '',
        commentUserId: '',
        commentId: '',
        type: 1,
        content: '',
      },
      placeholderText: '我也说一句...',

    }
  },
  components: {  },
  computed:{
  },
  methods: {
    handleDelectMessage() {
      if(!this.messageText) {
        this.placeholderText = '我也说一句...'
        this.addForm.commentUserId = ''
        this.toContent= false
        this.addForm.type = 1

      }
    },
    //打赏
    handleMessageReward(item){
      this.$parent.handleRewardOpen(item);
    },
    // 评论点赞
    handleContentLikeData(item, idx) {
      let status;
      if (item.isLike == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        commentId: item.id,
        lgUserId: sessionStorage.lgUserId,
        type: 2,
        isLike: status
      };
      giveLike(params).then(res => {
        if (res.code == 200) {
          this.messageLists[idx].isLike = status
          if(this.messageLists[idx].isLike == 1) {
            this.messageLists[idx].likeCount = this.messageLists[idx].likeCount*1+1
          }else {
            if(this.messageLists[idx].likeCount == 0) {
              this.messageLists[idx].likeCount = 0
            }else {
              this.messageLists[idx].likeCount = this.messageLists[idx].likeCount*1-1
            }
          }
        }
      });

    },
    // 点赞
    handleLikeData(item, idx) {
      let status;
      if (item.isLike == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        dynamicId: item.id,
        lgUserId: sessionStorage.lgUserId,
        type: 1,
        isLike: status
      };
      // return
      giveLike(params).then(res => {
        if (res.code == 200) {
          this.squareDetails.isLike = status
          this.handleSquareInfo(this.info)
        }
      });
    },
    handleSquareImgs(item,idx) {
      this.$parent.handleImgBigOpen(item,0,idx);
    },
    // 
    handleFormContentData(mesItem,item) {
      if(sessionStorage.lgUserId ==mesItem.commentUserId) {
        this.placeholderText = '我也说一句...'
        this.addForm.commentUserId = ''
        this.toContent= false
      } else {
        this.placeholderText = '回复'+mesItem.commentUserName+':'
        this.addForm.commentUserId = mesItem.commentUserId
        this.toContent= true

      }
      this.addForm.parentCommentId = item.id
      this.addForm.type = 2

    },
    handleFormMessage(item,idx) {
      if(sessionStorage.lgUserId == item.secondCommentDTO.commentUserId) {
        this.placeholderText = '我也说一句...'
        this.addForm.commentUserId = ''
        this.toContent= false
      } else {
       this.placeholderText = '回复'+item.secondCommentDTO.commentUserName+':'
        this.addForm.commentUserId = item.secondCommentDTO.commentUserId
        this.toContent= true

      }
      this.addForm.parentCommentId = item.id
      this.addForm.commentId = item.commentId
      this.addForm.type = 2

    },
    handleToMessage(item,idx) {
      if(sessionStorage.lgUserId == item.secondCommentDTO.lgUserId) {
        this.placeholderText = '我也说一句...'
        this.addForm.commentUserId = ''
        this.toContent= false
      } else {
        this.placeholderText = '回复'+item.secondCommentDTO.lgUserName+':'
        this.addForm.commentUserId = item.secondCommentDTO.lgUserId
        this.toContent= true

      }
      this.addForm.parentCommentId = item.id
      this.addForm.commentId = item.commentId
      this.addForm.type = 2
    },
    // 二级回复
    handleSecondCommentDTO(mesItem,item) {
      if(sessionStorage.lgUserId == mesItem.lgUserId) {
        this.placeholderText = '我也说一句...'
        this.addForm.commentUserId = ''
        this.toContent= false
      } else {
        this.placeholderText = '回复'+mesItem.lgUserName+':'
        this.addForm.commentUserId = mesItem.lgUserId
        this.toContent= true

      }
      this.addForm.parentCommentId = item.id
      this.addForm.commentId = mesItem.commentId
      this.addForm.type = 2

    },
    // 查看更多
    handleMessageMor(item,idx) {
      this.messageLists[idx].pageNum++
      this.handleTwoList(item,idx)
    },
    // 二级评论提交参数
    handleToContentData(item) {
      console.log(item)
      this.placeholderText = '回复'+item.nickName+':'
      this.toContent= true
      this.addForm.parentCommentId = item.id
      this.addForm.commentUserId = item.userId
      this.addForm.commentId = item.id
      this.addForm.type = 2
    },
    // 发送评论
    handleAddMessage() {
      let params = {
        lgUserId: sessionStorage.lgUserId,
        dynamicId: this.squareDetails.id,
      }
      if(this.addForm.type == 2) {
        params.parentCommentId = this.addForm.parentCommentId
        params.commentUserId = this.addForm.commentUserId
        params.commentId = this.addForm.commentId
        params.type =this.addForm.type
        if(!this.messageText) {
          this.$message.error('请输入评论!');
          return
        }
        params.content = this.messageText
      } else {
        if(!this.messageText) {
          this.$message.error('请输入评论!');
          return
        }
        params.content = this.messageText
        params.type =1
      }
      commentMessage(params).then(res => {
        if (res.code == 200) {
          this.toContent = false
          this.messageText = ''
          this.addForm.type = 1
          this.placeholderText = '我也说一句...'
          this.addForm.commentUserId = ''
          this.toContent= false
          this.handlecommentList(this.info)
          this.handleSquareInfo(this.info)
        }
      })

    },
    // 获取动态详情
    handleSquareInfo(item) {
      let params = {
        lgUserId: sessionStorage.lgUserId,
        dynamicId: item.id,
      }
      squareInfo(params).then(res => {
        if (res.code == 200) {
          this.squareDetails = res.data
          this.$parent.handleMessageChildData(this.squareDetails);
        }
      })
    },
    // 一级评论
    handlecommentList(item) {
      this.info = item
      let params = {
        lgUserId: sessionStorage.lgUserId,
        dynamicId: item.id,
      }
      commentList(params).then(res => {
        if (res.code == 200) {
          this.messageLists = res.rows
          for(let i  in  this.messageLists) {
            this.messageLists[i].secondCommentList = []
            this.messageLists[i].pageSize=5
            this.messageLists[i].pageNum=0
            this.messageLists[i].twoNum=0
            this.messageLists[i].messageStatus=false
          }
        }
      })
    },
    // 二级评论
    handleTwoList(item,idx) {
      this.messageLists[idx].messageStatus = true
      let params = {
        lgUserId: sessionStorage.lgUserId,
        parentCommentId: item.id,
        pageSize: this.messageLists[idx].pageSize,
        pageNum: this.messageLists[idx].pageNum,
      }
      twoList(params).then(res => {
        if (res.code == 200) {
          this.messageLists[idx].secondCommentList = this.messageLists[idx].secondCommentList.concat( res.rows) 
          this.messageLists[idx].twoNum=res.total
          this.$forceUpdate()
        }
      })
    }
  },
  created() {},
  mounted() {
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
// @import "./index.less";
.messageSquare{
  height: 570px;
  padding: 0px 30px 130px 40px;
  margin-top:34px;
  font-size: 12px;
  overflow: auto;
  color: #666;
  .message-head{
    padding-bottom: 18px;
    border-bottom: 1px solid #E6E6E6;
    .head-user{ 
      display: flex;
      align-items: center;
      .user-img{
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      .user-text{
        margin-left: 10px;
        
        .text-name{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #666;
          >img{
            margin-left: 10px;
          }
        }
        .text-time{
          margin-top: 6px;
          color: #999;
        }
      }
    }
    .head-text{
      margin-top: 20px;
      .text-item{
        .content-topicName{
          color: #999;
        }

      }
      .text-imgs{
        margin-top: 17px;
        display: flex;
        flex-wrap: wrap;
        >div{
          margin-right: 10px;
          margin-top: 10px;
          border-radius: 6px;
          background: #DCE5EE;
        }
        .imgs-list-mor{
          width: 200px;
          height: 300px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit:cover;
          }

        }
        .imgs-list{
          width: 126px;
          height: 126px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit:cover;
          }

        }
        .imgs-long{
          width: 200px;
          height: 300px;

        }
      }
    }
    .head-status{
      display: flex;
      align-items: center;
      margin-top: 27px;
      >div{
        display: flex;
        align-items: center;
        margin-right: 28px;
        cursor: pointer;
        color: #333;
        img{
          margin-top: 3px;
          margin-right: 5px;
        }
      }

    }
  } 
  .message-content{
    margin-top: 17px;
    .content-person{
      color: #333;
      span{
        color: #2D82D6;
      }
    }
    .content-list{
      display: flex;
      margin-top: 30px;
      .list-left{
        img{
          width: 34px;
          height: 34px;
          border-radius: 50%;
        }
      }
      .list-right{
        margin-left: 12px;
        .right-name{
          display: flex;
          align-items: center;
          >div:nth-child(1){
            color: #2D82D6;
            font-weight: 600;
          }
          img{
            margin-left: 3px;
          }
        }
        .right-text{
          // display: flex;
          margin-top: 8px;
          cursor: pointer;
          .text-content{
            color: #000;
          }
        }
        .right-time{
          display: flex;
          font-size: 12px;
          margin-top: 10px;
          .time-show{
            color: #666666;
          }
          .text-likeCount{
            display: flex;
            align-items: center;
            margin-left: 44px;
            color: #333333;
            .praise{
              img{
                margin-top: 3px;
                margin-right: 5px;
              }

            }
          }

        }
        .right-other-mor{
          color: #999;
          font-size: 10px;
          cursor: pointer;
          margin-top: 5px;
        }
        .right-names{
          margin-top: 8px;
          .names-all{
            cursor: pointer;
            span{
              color: #2D82D6;
            }
          }
        }
      }
    }
    

  }
  .message-foot{
    display: flex;
    position:fixed;
    align-items: center;
    width: 100%;
    height: 94px;
    // padding: 24px 34px ;
    background: #FFFFFF;
    box-shadow: 0px -3px 16px 0px rgba(204, 219, 230, 0.4);
    bottom: 0;
    left: 0;
    z-index: 33;
    input{
      width: 600px;
      height: 74px;
      background: none;
      border: none;
      outline: none;
      padding: 15px 10px 10px 41px;
      &::-webkit-input-placeholder{
        color:#666 !important;

      }
      &:-moz-placeholder{
        color:#666 !important;

      }
      &::-moz-placeholder{
        color:#666 !important;

      }
      &:-ms-input-placeholder{
        color:#666 !important;

      }
      
    }
    .foot-bnt{
      width: 88px;
      height: 38px;
      margin-right: 32px;
      margin-left: auto;
      text-align: center;
      line-height: 38px;
      background: #5564F3;
      border-radius: 5px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
<style lang="less" scoped>
</style>
