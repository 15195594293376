<template>
  <div class="shopList">
    <ul class="tabs">
      <li v-for="(item,idx) in tab" :class="{'tabs-show':idx == tabIdx}" :key="idx" @click="handleTabData(item,idx)">
        {{item.name}}
      </li>
    </ul>
    <div class="search">
      <div class="search-number"> 
        <el-input  v-model="searchData.keyWord" placeholder="请输入商品标题或者订单号进行搜索" clearable></el-input>
        <i class="el-icon-search" @click="handleSearchData"></i>
      </div>
      <div class="search-right">
        <div>
          <div>成交时间</div>
          <el-date-picker
            v-model="searchData.time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>

        </div>
        <div class="right-add" @click="handleSearchData">查询</div>
      </div>
    </div>
    <div class="lists">
      <ul class="lists-th">
        <li v-for="(item,idx) in thList" :key="idx" >{{item.name}}</li>
      </ul>
      <ul class="lists-td" v-if="listData.length > 0" ref="scrollBox" id="lists-data" @scroll="scrollEvent ">
        <li v-for="(item,idx) in listData" :key="idx" @click="handleLookData(item,idx)">
          <div class="td-top">
              <div class="top-tiem">{{$utils.dateFormat(item.createTime, 'yyyy-MM-dd hh:mm:ss')}}</div>
            <div class="top-number">订单编号：{{item.orderNumber}}</div>
          </div>
          <div class="td-item">
            <div class="item-img">
              <div class="img-border">
                <img :src="item.coverImg"/>
              </div>
              <div class="img-type">
                <div class="type-name">
                 {{item.productName}}
                </div>
                <div class="type-color" v-if="item.skuValue">
                  分类：{{item.skuString}}
                </div>
              </div>

            </div>
            <div class="item-num">{{item.buyNum}}</div>
            <div class="item-money">￥{{item.price}}</div>
            <div class="item-status">
              <div v-if="item.orderType == 2">
                  {{item.status==1?'待付定金':item.status==2?'开始支付尾款':item.status==3?'待付尾款':item.status==4?'拒绝支付尾款':item.status==5?'超时支付尾款':item.status==6?'待发货':item.status==7?'已发货':item.status==8?'交易成功':item.status==9?'申请退货':item.status==10?'已退货':item.status==11?'已退款':item.status==12?'超时支付定金':item.status==13?'取消订单':'--'}}
              </div>

              <div class="status-item" v-else>
                <div v-if="item.productType == 2">
                  {{item.status==1?'待支付':item.status==2?'待核销':item.status==3?'已退款':item.status==4?'已核销':item.status==5?'已取消':item.status==6?'已使用':item.status==9?'已删除':'--'}}
                </div>
                <div v-else>
                  {{item.status==1?'待支付':item.status==2?'待发货':item.status==3?'已退款':item.status==4?'已发货':item.status==5?'取消订单':item.status==6?'已收货':'--'}}
                </div>

              </div>
              <div class="status-open"  @click.stop="handlePayAdd(item)" v-if="item.status==1">去付款</div>
              <div class="foot-right-refund"   @click.stop="handleDelData"  v-if="item.productType == 2 && item.orderType == 1&&item.status==6 ">删除订单</div>
              <div class="foot-right-refund"  @click.stop="handleMallRefund" v-if="item.productType == 2 && item.status==2 ">申请退款</div>
            </div>
            <div class="item-remark">{{item.pickUpType==1?'门店自提':'--'}}</div>
          </div>
        </li>   
      </ul>
      <div class="lists-no" v-else>
        <div class="no-data">
          <img src="~@/assets/images/balance/no-data.gif">
        </div>
      </div>
    </div>

    <div class="status-tip-bg" v-show="statusShow"></div>
    <div class="status-tip" v-show="statusShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div> 
      <div class="tip-all">
        <div class="all-item">
          <!-- 完成状态 -->
          <div class="item-accomplish" v-if="lookObj.status == 4">
            <img src="~@/assets/images/balance/user/success.gif"/>
            <div class="accomplish-success">已完成</div>
          </div>
          <!-- 待支付 -->
          <div class="item-pending" v-else-if="lookObj.status == 1">
            <div class="pending-title">
              <div class="pending-left">
                <img src="~@/assets/images/balance/user/pending.png"/>
              </div>
              <div class="pending-right">等待支付</div>

            </div>
            <div class="pending-flex">
              <div class="flex-left"> 需付款：<span>￥{{lookObj.actualMoney}}</span></div>
              <div class="flex-right">
                <div>剩余：</div>
                <count-down  class="right-time"
                  :required='false'
                  :minutesTxt="':'" :secondsTxt="''"
                  v-on:start_callback="countDownS_cb(lookObj)" 
                  v-on:end_callback="countDownE_cb(lookObj)" 
                  :startTime="lookObj.itemShow" 
                  :endTime="lookObj.lastTime" >
                  </count-down>
                <!-- <span>29：58</span> -->
              </div>
            </div>
            <div class="pending-add"  @click="handlePayAdd(lookObj)">立即支付</div>
          </div>
          <!-- 待自提 -->
          <div class="item-pending" v-else>
            <div class="pending-title">
              <div class="pending-left">
                <img src="~@/assets/images/balance/user/pending.png"/>
              </div>
              <div class="pending-right">
                <!-- 待自提 -->
                {{lookObj.status==1?'待支付':lookObj.status==2?'待自提':lookObj.status==3?'退款':lookObj.status==4?'已完成':lookObj.status==5?'取消':'--'}}

              </div>
            </div>
            <div class="pending-tip">温馨提示：需要去炸蛋电竞馆门店取货</div>

          </div>
          <div class="item-shopinfo">
            <div class="shopinfo-img">
              <img :src="lookObj.coverImg"/>
            </div>
            <div class="shopinfo-item">
              <div class="item-name">{{lookObj.productName}}</div>
              <div class="item-color">分类：{{lookObj.skuString}} x{{lookObj.buyNum}}</div>
              <div class="item-money">价格：<span>￥{{lookObj.price}}</span></div>
            </div>
          </div>
          <ul class="item-list">
            <li>
              <div class="list-label">订单编号</div>
              <div class="list-val">{{lookObj.orderNumber}}</div>
              <div class="list-copy"  
              v-clipboard:copy="lookObj.orderNumber"
              v-clipboard:success="copy"
              v-clipboard:error="onCopyError" >复制</div>
            </li>
            <li>
              <div class="list-label">订单总价</div>
              <div class="list-val">￥{{lookObj.price|| 0}}</div>
            </li>
            <li>
              <div class="list-label">配送方式</div>
              <div class="list-val">门店自提</div>
            </li>
            <li>
              <div class="list-label">支付方式</div>
              <!-- <div class="list-val">{{lookObj.payType==1?'微信支付':lookObj.payType==2?'支付宝支付':lookObj.payType==3?'霖感币支付':'--'}}</div> -->
              <div class="list-val">{{typeOptions[lookObj.payType] || '--'}}</div>
            </li>
            <li>
              <div class="list-label">下单时间</div>
              <div class="list-val">{{$utils.dateFormat(lookObj.createTime, 'yyyy-MM-dd hh:mm:ss')}}</div>
            </li>
            <li>
              <div class="list-label">订单备注</div>
              <div class="list-val">{{lookObj.remark?lookObj.remark:'--'}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <play-tip ref="payTip"></play-tip>
  </div>
</template>
<script>
import CountDown from 'vue2-countdown'
import PlayTip from "@/views/balance/shoping/common/playTip";
import ShopOrder from "@/views/balance/components/shopOrder"
import {
  getOrderSummary,
} from "@/api/balance/shoping";
export default {
  name: "shopList",
  data() {
    return{
      statusShow: false,
      tab:[
        {
          name: '所有订单',
          id: ''
        },
        {
          id: 1,
          name: '待付款' 
        },
        {
          id: 2,
          name: '待收货' 
        },
        {
          id: 3,
          name: '已退款' 
        },
        {
          id: 4,
          name: '已完成' 
        },
        {
          id:5,
          name: '已取消' 
        },
      ],
      typeOptions: {
        1: '微信支付',
        2: '支付宝支付',
        // 3: '霖感币支付'
      },
      tabIdx: 0,
      thList: [
        {
          name: '商品信息'
        },
        {
          name: '单价及数量'
        },
        {
          name: '实付款'
        },
        {
          name: '订单状态'
        },
        {
          name: '提货方式'
        },
      ],
      listData: [],
      options: [
        {

        }
      ],
      searchData: {
        status: '',
        pageSize: 10,
        pageNum: 1,
        lgUserId: '',
        time: null

      },
      active: 1,
      totalNum: 0,
      loading: false,
      lookObj: {}

    }
  },
  computed:{
  },
  components: {PlayTip,ShopOrder,CountDown},
  methods: {

    countDownS_cb(){},
    countDownE_cb(item){
      this.lookObj.status = 5
    },

    onCopyError() {},
    // 复制单号
    copy(){
      this.$message({
        message: '复制成功',
        type: 'success',
        customClass: 2000
      });

    },
    handleLookData(item,idx) {
      this.lookObj = item
      this.lookObj.itemShow = new Date().getTime()
      this.statusShow = true
    },
    handleListData() {
      this.handleSearchData()
    },
    handlePayAdd(item) {
      this.statusShow = false
      this.$refs.payTip.handleOrderListPay(item)
    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#lists-data").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 50) {
        if (
          this.listData.length > this.totalNum ||
          this.totalNum == this.listData.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    handleOpenData () {},
    loadMore() {
      this.handleOrderList();
    },
    handleSearchData() {
      this.active = 1
      this.listData = []
      if(!this.searchData.time || this.searchData.time.length == 0) {
        this.searchData.time = null
        this.searchData.startTime = ''
        this.searchData.endTime = ''

      }else{
        this.searchData.startTime = this.$utils.dateFormat(this.searchData.time[0],'yyyy-MM-dd')
        this.searchData.endTime = this.$utils.dateFormat(this.searchData.time[1],'yyyy-MM-dd')
      }
      this.handleOrderList()
    },
    handlePlayCloseData() {
      this.statusShow = false
    },
    // 选中状态菜单
    handleTabData(item,idx){
      this.tabIdx = idx
      this.searchData.status = item.id
      this.handleSearchData()
    },
    handleOrderList() {
      let params = {
        keyWord: this.searchData.keyWord,
        status: this.searchData.status,
        pageSize: this.searchData.pageSize,
        pageNum: this.active,
        lgUserId: sessionStorage.lgUserId,
        startTime: this.searchData.startTime,
        endTime: this.searchData.endTime,
      }
      getOrderSummary(params).then(res => {
        if (res.code == 200) {
          this.totalNum = res.total;
          this.listData = this.listData.concat(res.rows);
          this.loading = this.active < res.total;
          if(this.listData.length > 0) {
            for(let i in this.listData) {
              if(this.listData[i].skuValue) {
                this.listData[i].skuStatus = JSON.parse(this.listData[i].skuValue) 
                this.listData[i].skuString = '' 
                for(let j in this.listData[i].skuStatus) { 
                  if(j == this.listData[i].skuStatus.length-1) {
                    this.listData[i].skuString += this.listData[i].skuStatus[j].value
                  }else{
                    this.listData[i].skuString += this.listData[i].skuStatus[j].value + '-'
                  }
                }
              }
            }
          }
        } 
      }).catch(res=>{
      });
    },
  },
  created() {},
  mounted() {
    this.handleOrderList()
  },
  destroyed() {
    
  }
};
</script>
<style lang="less" scoped>
@import "./shopList.less";

</style>