<template>
  <div class="account">
    <div class="account-bottom">
      <div class="bottom-item">
        <p>我的余额</p>
        <div class="item-flex  last-item">
          <div class="flex-left-all">
            网费余额：
            <span class="left-money">{{userInfo.balance || 0}}元 (现金:{{userInfo.availMoney}}元 赠送金:{{userInfo.presentedMoney}}元)</span>
            <span class="left-tip"> 用途∶（用户在炸蛋电竞馆门店上网场景消费）</span>
          </div>
          <div class="flex-right flex-right-g" @click="handleNetworkShow">充值</div>
        </div>
        <div class="item-flex  last-item">
          <div class="flex-left">
            霖感币：
            <span class="left-money">{{lgbObj.lgMoney || 0}}</span>
            <span class="left-tip"> 用途∶（用户在陪玩订单，消费/动态打赏等场景消费）</span>

          </div>
          <div class="flex-right flex-right-g" @click="handlePlayShow">充值</div>
        </div>
      </div>
      <div class="bottom-item">
        <div class="item-flex">
          <div class="flex-left">
            <p>我的收入</p>
              <div class="flex-left-all">
              霖感币：
              <span class="left-money">{{lgbObj.incomeMoney || 0}} </span>
              <span class="left-tip">（在收到其他用户使用霖感币消费后，下单，动态/消息打赏，参加活动等，获得的收益可转化成霖感币收入）</span>

            </div>
          </div>
          <div class="flex-right flex-right-b">结算</div>
        </div>
      </div>
      <div class="bottom-item">
        <div class="item-flex last-item">
          <div class="flex-left">
            <!-- <p>网费打赏</p> -->
            网费收入：
            <span class="left-money">{{lgbObj.rewardMoney || 0}}</span>
            <span class="left-tip">（动态网费打赏，获得的收益可以转化为上网赠送金额）</span>
          </div>
          <div class="flex-right flex-right-last" @click="handleDynamicData">提取</div>
        </div>
      </div>
    </div>
    <div class="account-top">
      <div class="top-tab">
        <div class="tab" :class="{'tabShow':tabIdx == 0}" @click="handleAccountTabData(3,0)">霖感币充值</div>
        <div :class="{'tabShow':tabIdx == 1}" @click="handleAccountTabData(1,1)">霖感币收入</div>
      </div>
      <div class="top-search">
        <div class="search-time">
           <el-date-picker
            v-model="payTime"
            type="month"
            size="small"
            value-format="yyyy-MM"
            placeholder="选择日期">
          </el-date-picker>
          <img class="search-bnt" @click="handlePayTime" src="~@/assets/images/balance/user/search.png"/>
        </div>
        <div class="search-status" v-show="tabIdx == 1">
          <div :class="{'status-bnt':searchIdx == idx}" v-for="(item,idx) in searchBnt" :key="idx" @click="handleSearchBnt(item,idx)">{{item.name}}</div>
        </div>
      </div>
      <div class="top-table">
        <div class="table-th">
          <div>订单号</div>
          <div>类型</div>
          <div>状态</div>
          <div>金额</div>
          <div>描述</div>
          <div>创建时间</div>
        </div>
        <div class="table-list" ref="tableList" id="tableList" v-if="listData.length>0">
          <div class="list-td" v-for="(item,idx) in listData" :key="idx">
            <div>{{item.orderNumber}}</div>
            <div>{{item.type == 1?'支出':item.type == 2?'收入':'充值'}}</div>
            <div>{{item.status == 1?'冻结中':item.status == 2?'已划款':item.status == 3?'已收款':'已退回'}}</div>
            <div>{{item.actualMoney}}</div>
            <div>{{item.remark}}</div>
            <div>{{item.createTime}}</div>
          </div>
        </div>
        <div class="lists-no" v-else>
          <div class="no-data">
            <img src="~@/assets/images/balance/no-data.gif">
          </div>
        </div>
        <div class="lists-page" v-if="search.total > 0" >
          <pagination
            :total="search.total"
            :page.sync="search.params.pageNum"
            :limit.sync="search.params.pageSize"
            @pagination="handleLgCoinOrderList"
          />
        </div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <div class="order-tip-bg" v-show="playShowBg"></div>
    <div class="order-tip" v-show="playShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="play-title">
        <div class="title-name">充值</div>
        <!-- <div class="title-money">
          {{palyLabel}}
          <span class="money-icon">￥</span> <span class="money-num">{{dataObj.price || 0}}</span>
        </div> -->
      </div>
      <!-- 霖感币支付 -->
      <div class="tip-lgb" >
        <div class="lgb-input">
          <input type="number" maxlength="5" v-model="payForm.topUpMoney" placeholder="请输充值金额"/>
        </div>
        <div class="lgb-bnts">
          <div class="wechat-add" @click="handlPayData(1)">微信</div>
          <div class="lgb-add" @click="handlPayData(2)">支付宝</div>

        </div>
      </div>
      <!-- 二维码支付 -->
      <!-- <div class="tip-qr-code" v-else>
        <div class="qr-img"></div>
        <div class="qr-text">严禁利用收款吗进行诈骗，发现后立即通知公安机关</div>
      </div> -->
    </div>
    <div class="order-tip" v-show="playImg">
      <div class="tip-close" @click="handlePayCodeData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="play-title">
        <div class="title-name">{{payName}}</div>
        <!-- <div class="title-money">
          {{palyLabel}}
          <span class="money-icon">￥</span> <span class="money-num">{{dataObj.price || 0}}</span>
        </div> -->
      </div>
      <img class="pay-code" :src="payCode"/>
      <div class="pay-tip-text">扫码成功请稍等几秒,在关闭弹窗</div>
    </div>
    <!-- 网费充值弹窗 -->
    <div class="network-tip" v-show="networkShow">
      <div class="tip-close" @click="handleNetworkClose">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="network-all">
        <div class="network-title">网费充值</div>
        <div class="network-display">
          <div class="network-flex network-money">
            <div>网费余额∶</div>
            <div class="money-num">{{userInfo.balance}}元 (现金:{{userInfo.availMoney}}元 赠送金:{{userInfo.presentedMoney}})</div>
            
          </div>
          <div class="network-flex">
            <div class="flex-label">充值金额∶</div>
            <div class="flex-display">
              <div class="flex-bnt" :class="{'flex-bnt-show': idx == payIdx}" v-for="(item,idx) in payMoneyS" :key="idx" @click="handlePayMoneyBnt(item,idx)">
                <div class="flex-bnt-item">
                  <div>{{item.money}}元</div>
                  <div class="bnt-item-text money-num" v-if="item.text">{{item.text}}</div>

                </div>
              </div>
              
              <div class="flex-input">
                <input  @focus="handlePayNnmber"  v-model="payNnmber" maxlength="5" placeholder="请输入金额"/>
                <div class="input-text">其他金额</div>
              </div>
            </div>
          </div>
          <div class="network-flex ">
            <div class="pay-label">充值方式∶</div>
            <div class="pay-bnts">
              
              <div class="wechat-add" @click="handlNetworkPayData(5)">微信</div>
              <div class="zfb-add" @click="handlNetworkPayData(6)">支付宝</div>
              <div class="lgb-add" @click="handlNetworkPayData(8)">霖感币(<span>{{lgbObj.lgMoney || 0}}</span>)</div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
 
import { getWalletInfo,getLgCoinOrderList,lgCoinOrder,getPocketBook,getLgCoinOrder,dynamicSettle,activityData,clientRecharge,clientPay,rechargePayStatus } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'
import { guessingList,userinfo } from "@/api/wallet";
export default {
  name: "account",
  data() {
    return{
      money: '',
      playShow: false,
      playShowBg: false,
      tabIdx: 0,
      searchBnt: [
        {
          id: '0',
          name: '全部',
        },
        {
          id: '2',
          name: '收入',
        },
        {
          id: '1',
          name: '支出',
        },
      ],
      lgUserId: '',
      lgbObj:{
        lgMoney: 0,
        incomeMoney: 0,
      },
      listData: [],
      payForm: {
        payType: '',
        topUpMoney: null,
        lgUserId: '',
        shopId: '',
      },
      payTime: new Date(),
      status: '',
      searchIdx: 0,
      playImg: false,
      payCode: '',
      payName: '微信',
      payNumber: '', // 支付订单号
      timer: null,
      search: {
        is: false,
        val: "",
        params: {
          page: 1,
          pageSize: 9,
          isAsc: 1,
          name: ""
        },
        total: 0,
        list: [],
      },
      coinNUm: 20,
      networkShow: false,
      payMoneyS:[
        {
          id: 1,
          money: 50,
          text: '',
          type: null
        },
        {
          id: 2,
          money: 100,
          text: '',
          type: null
        },
        {
          id: 3,
          money: 200,
          text: '',
          type: null
        },
        {
          id: 4,
          money: 500,
          text: '',
          type: null
        },
        {
          id: 6,
          money: 1000,
          text: '',
          type: null
        },
      ],
      userInfo: {},
      refillCards: [],
      payObj: {},
      payIdx: 0,
      payStatus: null,
      createNumber: '',
      payNnmber: null
    }
  },
  components: {  },
  computed:{
  },
  methods: {
    // 充值网费轮询接口
    rechargePayStatus() {
      this.coinNUm--
      let _this = this
      rechargePayStatus({orderNumber: this.payNumber}).then(res => {
        if (res.code == 200) {
          _this.timer = setTimeout(()=>{
            if(_this.coinNUm<1) {
              if(res.data.status == 2) {
                this.$message({
                    message: res.msg || '充值成功!',
                    type: 'success',
                    customClass: 2000
                });
              } else {
                _this.$message.error('充值超时!');
              }
              _this.handleWalletInfo()
              _this.handleLgCoinOrderList()
              _this.handlePlayCloseData()
              _this.coinNUm = 20 
              _this.timer = null
              _this.handleClearTimeout();
              _this.handleGetUserInfo();
              return true
            }else{
              if(res.data.status == 2) {
                _this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass: 2000
                });
                _this.coinNUm = 20 
                _this.timer = null
                _this.handleWalletInfo()
                _this.handleLgCoinOrderList()
                _this.handlePlayCloseData()
                _this.handleClearTimeout();
               _this.handleGetUserInfo();
                return true

              }
            }
            _this.rechargePayStatus()
          },3000)
        }else {
          _this.$message.error(res.msg || '操作失败!');
          _this.coinNUm = 20 
          _this.timer = null
          _this.handleClearTimeout();
        }
      })

    },
    // 网费充值-支付接口
    handleClientPay() {
      
      let params = {
        orderNumber: this.createNumber,
        orderType: 1,
        payType: this.payStatus,
      }
      clientPay({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.networkShow = false
            this.payNumber = res.data.orderNumber
            this.playImg = true
            this.payCode = res.data.imgUrl
          }
          if(this.payStatus != 8) {
            this.rechargePayStatus()

          }else {
            
            this.$message({
              message: res.msg || '充值成功!',
              type: 'success',
              customClass: 2000
            });
            this.handleWalletInfo()
            this.handleLgCoinOrderList()
            this.handlePlayCloseData()
            this.handleGetUserInfo();
          }
        }else {
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    // 选择充值按钮
    handlePayMoneyBnt(item,idx){
      this.payIdx = idx
      this.payObj = item
     this.payNnmber = null
    },
    handlePayNnmber() {
      this.payIdx = null
      this.payObj = {}
    },
    // 充值网费创建订单
    handlNetworkPayData(status) {
      if(this.payNnmber) {
        if(this.payNnmber < 30) {
          this.$message.error('充值不能少于30元!');
          return
        }
        if(this.$utils.numberInt(this.payNnmber)) {
          this.$message.error('请输入正整数!');
          return
        }

      }
      
      if(status == 5) {
        this.payName = '微信支付'
      } else if(status == 6){
        this.payName = '支付宝支付'
      }else if(status == 8){
        this.payName = '霖感币支付'
        if(this.payNnmber) {
          this.payObj.money = this.payNnmber
        }
        if(this.payObj.money > this.lgbObj.lgMoney) {
          this.$message.error('霖感币不足!');
          return
        }
        if(!this.payObj.money ) {
          this.$message.error('请输入或者选择金额!');
          return
        }
      }
      let params = {
        memberId: this.lgUserId,
        refillCardId: this.payObj.cardId,
        refillCardType: this.payObj.type,
        topUpMoney: this.payObj.money || Number(this.payNnmber),
        presenterMoney: this.payObj.presenterMoney,
        type: status,
        source: 2
      }
      clientRecharge({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.createNumber = res.data.orderNumber
          this.payStatus = res.data.payType
          if(status == 2 || status == 3) {
            this.playImg = true
            this.payCode = res.data.imgUrl
          }
          this.handleClientPay()
        }else {
          this.$message.error(res.msg);

        }
      })
    },
    // 关闭网费充值弹窗
    handleNetworkClose() {
      this.playShowBg = false
      this.networkShow = false
    },
    // 充值网费弹窗
    handleNetworkShow() {
      this.playShowBg = true
      this.networkShow = true
      this.createNumber = ''
      this.payObj = {}
      this.payIdx = 0
      this.payNnmber = null
      this.payStatus = null
      let params = {
        lgUserId: this.lgUserId
      }
      activityData({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.refillCards = res.data.refillCards
          for(let i  in  this.refillCards) {
            for(let j  in  this.payMoneyS) {
              if(this.refillCards[i].conditionMoney == this.payMoneyS[j].money) {
                this.payMoneyS[j].text = this.refillCards[i].cardName 
                this.payMoneyS[j].cardId = this.refillCards[i].cardId
                this.payMoneyS[j].presenterMoney = this.refillCards[i].presenterMoney
                this.payMoneyS[j].type = this.refillCards[i].type
              }
            }
          }
          this.payObj = this.payMoneyS[0]
        }else {
        }
      })
    },
    // 打赏提取
    handleDynamicData() {
      dynamicSettle({lgUserId: this.lgUserId}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg || '提取成功!',
              type: 'success',
              customClass: 2000
          });
          this.handleWalletInfo()
          this.handleGetUserInfo()
        }else {
          this.$message.error(res.msg || '提取失败!');
        }
      })

    },
    getLgCoinOrder() {
      this.coinNUm--
      let _this = this
      getLgCoinOrder({orderNumber: this.payNumber}).then(res => {
        if (res.code == 200) {
          _this.timer = setTimeout(()=>{
            if(_this.coinNUm<1) {
              if(res.data.status == 2) {
                this.$message({
                    message: res.msg || '支付成功!',
                    type: 'success',
                    customClass: 2000
                });
              } else {
                _this.$message.error('支付超时!');
              }
              _this.handleWalletInfo()
              _this.handleLgCoinOrderList()
              _this.handlePlayCloseData()
              _this.coinNUm = 20 
              _this.timer = null
              _this.handleClearTimeout();
              return true
            }else{
              if(res.data.status == 2) {
                _this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass: 2000
                });
                _this.coinNUm = 20 
                _this.timer = null
                _this.handleWalletInfo()
                _this.handleLgCoinOrderList()
                _this.handlePlayCloseData()
                _this.handleClearTimeout();
                return true

              }
            }
            _this.getLgCoinOrder()
          },3000)
        }else {
          _this.$message.error(res.msg || '操作失败!');
          _this.coinNUm = 20 
          _this.timer = null
          _this.handleClearTimeout();
        }
      })

    },
    handlePayCodeData() {
      this.playImg = false
      this.playShow = false
      this.playShowBg = false
      if(this.timer) {
        this.$once('hook:beforeDestroy', () => {   // 清除定时器
          this.timer = null;         
          clearTimeout(this.timer);                                    
        })
        clearTimeout(this.timer);　　// 清除定时器
        this.timer = null;

      }
    },
    // 微信支付 支付宝
    handlPayData(payType) {
      this.payForm.payType = payType
      if(payType == 1) {
        this.payName = '微信'
      }else {
        this.payName = '支付宝'
      }
      let shopInfo =  JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
      this.payForm.shopId = shopInfo.shopId
      if(!this.payForm.topUpMoney) {
        this.$message.error('请输入充值金额!');
        return
      }
      if(this.payForm.topUpMoney < 1 ) {
        this.$message.error('最少充值1元!');
        return
      }
      this.payForm.topUpMoney = Number(this.payForm.topUpMoney)
      this.payForm.lgUserId = this.lgUserId
      this.handleLgCoinOrder()
    },
    // 霖感币充值接口
    handleLgCoinOrder() {
      lgCoinOrder({data:encryptByDESModeCBCUtf8to64(JSON.stringify(this.payForm))}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.playShow = false
            this.payCode = res.data.imgUrl
            this.payNumber = res.data.orderNumber
            this.playImg = true

          }
          this.getLgCoinOrder()
        }else {
          this.$message.error(res.msg || '操作失败!');
        }
      })
      
    },
    handlePlayCloseData(){
      this.payForm.payType = 1
      this.payName = '微信'
      this.payForm.topUpMoney = null
      this.playShow = false
      this.playShowBg = false,
      this.playImg = false
      this.networkShow = false
      this.handleClearTimeout();
    },
    // 打开充值弹窗
    handlePlayShow() {
      this.playShowBg = true
      this.playShow = true
    },
    handleSearchBnt(item,idx) {
      this.pageNum = 1
      this.searchIdx = idx
      this.listData = []
      this.status = []
      if(item.id == 0) {
        this.status = '1,2'
      }else {
        this.status = item.id
      }
      this.handleLgCoinOrderList()
    },
    handlePayTime() {
      this.listData = []
      this.pageNum = 1
      this.handleLgCoinOrderList()
    },
    handleAccountTabData(type,idx) {
      this.tabIdx = idx
      this.pageNum = 1
      this.listData = []
      if (this.tabIdx == 0) {
        this.status = '3'
      } else{
        this.status = '1,2'
        
        this.listData = []
      }
      this.handleLgCoinOrderList()
    },
    handleWalletInfo() {
      getWalletInfo({lgUserId:this.lgUserId}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.lgbObj = res.data; 

          }
        }
      });
    }, 
    handleLgCoinOrderList() {
      let params = {
        pageNum: this.search.params.pageNum,
        pageSize: this.search.params.pageSize,
        lgUserId:this.lgUserId,
        type: this.status,
        time: this.payTime,
      }
      getPocketBook(params).then(res => {
        if (res.code == 200) {
          this.listData = res.rows
          this.search.total = res.total
        }
      });
    }, 
    handleClearTimeout() {

      if(this.timer) {
        this.playImg = false
        this.playShow = false
        this.playShowBg = false
        clearTimeout(this.timer);　　// 清除定时器
        this.timer = null;
        this.$once('hook:beforeDestroy', () => {   // 清除定时器
          this.timer = null;         
          clearTimeout(this.timer);                                    
        })
      }　
    },
    /**
     * 获取个人信息
     */
    handleGetUserInfo() {
      userinfo().then(res => {
        if (res.code == 200) {
          let info = JSON.parse(decryptByDESModeCBC(res.msg));
          this.userInfo = info
        }
      });
    },
  },
  created() {},
  mounted() {
    this.lgUserId = sessionStorage.lgUserId
    this.handleWalletInfo()
    this.handleGetUserInfo()
    
    this.status = '3'
    this.handleLgCoinOrderList()
  },
  destroyed() {
    this.handleClearTimeout()
    
  },
  beforeDestroy(){ 
    this.handleClearTimeout()
  },
};
</script>
<style lang="less">
@import "./account.less";
// .account{

// }

</style>
