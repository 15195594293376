<template>
  <div class="tipData">
    <el-dialog
      title="详情"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose">
      <span class="tip-name">{{obj.username}}的调查问卷</span>
      <div class="list" v-for="(item,idx) in obj.list" :key="idx">
        <div class="list-title">{{idx+1}}、{{item.name}} ({{item.type == 1?'单选题':'多选题'}})</div>
        <ul class="list-ul">
          <li class="list-li" :class="{'list-li-show':optionItem.selectd == true}" v-for="(optionItem,index) in item.options" :key="index">
            {{optionItem.no}}、{{optionItem.val}}
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'wallet',
  data() {
    return {
      dialogVisible: false,
      obj:{
        username: '张三',
        list:[
          {
            name: '你喜欢什么',
            type: 1,
            options:[
              {
                no: 'A',
                val:'100元以下'
              },
              {
                no: 'B',
                val:'100元-200元',
                selectd: true
              },
              {
                no: 'C',
                val:'200元-400元'
              },
              {
                no: 'Q',
                val:'其他: 1000元'
              },
            ]
          },
          {
            name: '你喜欢什么',
            type: 2,
            options:[
              {
                no: 'A',
                val:'100元以下',
                selectd: true
              },
              {
                no: 'B',
                val:'100元-200元',
                selectd: true
              },
              {
                no: 'C',
                val:'200元-400元'
              },
              {
                no: 'Q',
                val:'其他: 1000元'
              },
            ]
          },
          {
            name: '你喜欢什么',
            type: 1,
            options:[
              {
                no: 'A',
                val:'100元以下'
              },
              {
                no: 'B',
                val:'100元-200元'
              },
              {
                no: 'C',
                val:'200元-400元'
              },
              {
                no: 'Q',
                val:'其他: 1000元',
                selectd: true
              },
            ]
          },
        ]

      },
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false
    },
  },
  created(){
  },
  mounted() {
  },
  destroyed(){
  }
}
</script>
<style lang="less" scoped>
.tipData{
  .tip-name{
    font-size: 16px;
    font-weight: bold;
  }
  .list{
      margin-top: 30px;
      font-size: 14px;
    .list-title{
      
    }
    .list-ul{
      margin-top: 15px;
      .list-li{
        line-height: 25px;
        &.list-li-show{
          color: blue;
        }
      }
    } 
  }
}

</style>
