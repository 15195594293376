<template>
  <div class="shoping">
    <div class="shoping-search">
      <div class="search-recommend">排序方式： <span :class="{'icon-bg-show':searchobj.sort == 1}" @click="handleSalesData(1)">综合推荐</span></div>
      <div class="search-sales">
        
        <div :class="{'icon-bg-show':searchobj.sort == 4 || searchobj.sort == 3}">销量</div> 
        <div class="sales-filtrate">
          <div class="filtrate-top" :class="{'filtrate-top-show':searchobj.sort == 3}" @click="handleSalesData(3)">
          </div>
          <div class="filtrate-bottom" :class="{'filtrate-bottom-show':searchobj.sort == 4}" @click="handleSalesData(4)">
          </div>
        </div> 
      </div>
      <div class="search-price">
        <div :class="{'icon-bg-show':searchobj.sort == 6 || searchobj.sort == 5}">价格</div> 
        <div class="sales-filtrate">
          <div class="filtrate-top" :class="{'filtrate-top-show':searchobj.sort == 5}" @click="handleSalesData(5)">
          </div>
          <div class="filtrate-bottom" :class="{'filtrate-bottom-show':searchobj.sort == 6}" @click="handleSalesData(6)">
            <!-- <i class="el-icon-caret-bottom" :class="{'icon-bg-show':searchobj.sort == 6}"></i> -->
          </div>
        </div> 
      </div>
      <div class="search-input"> 
        <el-input size="small"  v-model="searchobj.name" placeholder="请输入商品名称" clearable></el-input>
        <img @click="handleShopNameData" src="~@/assets/images/balance/shoping/search.png"/>
      </div>
    </div>
    <ul class="shoping-list" v-if="listData.length>0" ref="scrollBox" id="lists-data" @scroll="scrollEvent ">
      <li class="list-all" v-for="(item,idx) in listData" :key="idx" @click="handleDetailsData(item)">
        <div class="all-item" >
          <div class="item-img">
            <img :src="item.coverImg" />
          </div>
          <div class="item-text">{{item.name}}</div>
          <div class="item-money"> ¥ {{item.minPrice}} 
            <!-- <span class="money-old">¥ {{item.maxPrice}}</span> -->
          </div>
        </div>
      </li>
    </ul>
    <Nodata v-else :noWidth="'364px'"  :noHeight="'340px'" ></Nodata>
  </div>
</template>
<script>

import {
  getProductList,
} from "@/api/balance/shoping";
import Nodata from "@/components/nodata";
export default {
  name: "shoping",
  data() {
    return {
      listData: [],
      totalNum: 0,
      pageSize: 10,
      active: 1,
      searchobj: {
        sort: 1,
        name: '',
      },
      loading: false,
    };
  },
  components: { Nodata},
  computed: {},
  methods: {
    handleShopNameData() {
      this.active = 1
      this.listData = []
      this.handleProductList()

    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#lists-data").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 80) {
        if (
          this.listData.length > this.totalNum ||
          this.totalNum == this.listData.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    loadMore() {
      this.handleProductList();
    },
    // 搜索
    handleSalesData(num) {
      this.active = 1
      this.listData = []
      this.searchobj.sort = num
      this.handleProductList()
    },
    // 跳转详情
    handleDetailsData(item) {
      // this.$router.push('/shopDetails')
      this.$router.push({path: '/shopDetails',query:{ id: item.id}});
    },
    handleProductList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.active,
        sort: this.searchobj.sort,
        name: this.searchobj.name
      }
      getProductList(params).then(res => {
        if (res.code == 200) {
          let arr = res.rows;
          this.totalNum = res.total;
          this.listData = this.listData.concat(res.rows);
          this.loading = this.active < res.total;
        }
      });
    }
  },
  created() {

  },
  mounted() {
    this.handleProductList()
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
@import "./index.less";

</style>
