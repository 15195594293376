<template>
  <div class="serve" >
    <div class="serve-title">
      <div class="title-flex">
        <div class="title-item" :class="{'title-h3':serveTab==0}" 
         @click="handleAddServe(null,0)">开通服务</div>
        <div class="title-item" :class="{'title-h3':serveTab==3}"   @click="handleAddServe(null,3)">申请记录</div>
      </div>
      <div class="title-back" v-show="serveTab == 1 || serveTab == 2" @click="handleAddServe(null,0)">返回</div>
    </div>
    <div class="serve-list" v-show="serveTab == 0">
      <div class="list-tab">
        <!-- <div class="tab-set">设置</div> -->
        <div class="tab-set" @click="handleOnline(1)" v-if="statusOnline == 2">上线</div>
        <div class="tab-set"  @click="handleOnline(2)" v-else>下线</div>
        <div class="tab-add" @click="handleAddServe(null,1)">添加技能</div>
      </div>
      <div class="list-table">
        <div class="table-th">
          <div>服务名称</div>
          <div>状态</div>
          <div>开通时间</div>
          <div>备注说明</div>
          <div>霖感币</div>
          <div>操作</div>
        </div>
        <div class="table-td" v-if="list.length>0">
          <div class="td-data" v-for="(item,idx) in list" :key="idx"> 
            <div>{{item.gameName}}</div>
            <div :class="[item.status == 1?'data-status-show':'data-status-hide']">{{item.status ==1?'已上架':'已下架'}}</div>
            <div v-if="item.createTime">
              {{  $utils.dateFormat(item.createTime,'yyyy-MM-dd hh:mm:ss')}}
            </div>
            <div v-else>---</div>
            <div>{{item.explainText || '---'}}</div>
            <div class="data-lgb"><span>{{item.price || 0}}</span> 霖感币/ {{item.unitType==1?'小时':'局'}}</div>
            <div class="data-operation">
              <div class="operation-left">
                <div class="get-serve" v-if=" item.status==1" @click="handleSetServe(item)">下架服务</div>

                <div class="get-serve"  @click="handleGetServe(item)" v-if="item.status !==1">上架服务</div>
                <div class="get-skill"  @click="handleSkillData(item)">技能信息</div>
              </div>
              <div class="operation-right">
                <div @click="handleAddServe(item,2)" v-if="item.status !== 1">
                  <i class="el-icon-edit-outline"></i>
                </div>
                <div @click="handleDeleteData(item)" v-if="item.status !== 1">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="lists-no" v-else>
          <div class="no-data">
            <img src="~@/assets/images/balance/no-data.gif">
          </div>
        </div>
      </div>
    </div>
    <div class="serve-list" v-show="serveTab == 3">
      <div class="list-table">
        <div class="table-th">
          <div>服务名称</div>
          <div>状态</div>
          <div>开通时间</div>
          <div>备注说明</div>
          <div>霖感币</div>
          <!-- <div>操作</div> -->
        </div>
        <div class="table-td table-td_big" v-if="applylist.length > 0">
          <div class="td-data" v-for="(item,idx) in applylist" :key="idx"> 
            <div>{{item.gameName}}</div>
            <div :class="[item.status == 1?'data-status-show':item.status == 2?'data-status-hide':'data-status-dai']">{{item.status ==1?'审核通过':item.status ==2?'审核拒绝':'待审核'}}</div>
            <div v-if="item.createTime">
              {{  $utils.dateFormat(item.createTime,'yyyy-MM-dd hh:mm:ss')}}
            </div>
            <div v-else>---</div>
            <div>{{item.remark || '---'}}</div>
            <div class="data-lgb"><span>{{item.price || 0}}</span> 霖感币/ {{item.unitType==1?'小时':'局'}}</div>
          </div>
          
        </div>
        <div class="lists-no" v-else>
          <div class="no-data">
            <img src="~@/assets/images/balance/no-data.gif">
          </div>
        </div>
      </div>
    </div>
    <AddServe v-show="serveTab == 1 || serveTab == 2" ref="addServe" :info="infoItem"></AddServe>
    <div class="head-tip-bg" v-if="tipShow"></div>
    <div class="head-tip" v-if="tipShow">
      <div class="tip-close" @click="handleHeadCloseData">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <div class="tip-canten">
        <div class="add-deploy add-item" v-for="(item,idx) in listData" :key="idx">
          <div class="deploy-item">
            <span>{{item.name}}：</span>
            <el-select v-model="item.addval" 
              collapse-tags @change="handleDeployVal" multiple placeholder="请选择游戏大区">
              <el-option
                v-for="(items,index) in item.list"
                :key="index"
                :label="items"
                :value="items">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="tip-bnt">
        <div class="bnt-add" v-loading="loading" @click="handleTipGameService">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getGameService,online,updateGameService,getGame,deleteGameService,getServiceApplyLog,getOnline } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'
import AddServe from "./addServe";
export default {
  name: "serve",
  data() {
    return{
      tipShow: false,
      list:[],
      serveTab: 0,
      lgUserId: '',
      onlineStatus: 1 ,
      gameDeploy:{}, // 游戏大区
      gameDWei:{}, // 游戏段位
      gameLocation:{}, // 游戏位置
      deployVal: [], // 保存接单大区的值
      dWeiVal: [], // 保存段位的值
      locationVal: [], // 保存位置的值
      gameList: {},
      gameItem: {},
      loading: false,
      infoItem: null,
      pageSize: 9999,
      pageNum: 1,
      applylist: [],
      statusOnline: ''
    }
  },
  components: { AddServe },
  computed:{
  },
  methods: {
    // 查询是否在线
    getOnline() {

      getOnline({lgUserId:this.lgUserId}).then(res=>{
        if (res.code == 200) {
          this.statusOnline = res.data.status
        }
      })
    },
    // 上下线
    handleOnline(type) {
      let status;
      if(type == 1) {
        this.statusOnline = 1
      } else{
        this.statusOnline = 2

      }
      let params = {
        lgUserId: this.lgUserId,
        onlineStatus:  this.statusOnline
      }
      online({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res=>{
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
        }else{
          this.$message.error(res.msg || '操作失败!');

        }
      })
    },
    // 修改技能信息
    handleTipGameService() {
      let gameParams = []
      for(let i in this.listData) {
        if(this.listData[i].addval.length>0) {
          let obj = {
            id: this.listData[i].id,
            name: this.listData[i].name,
            value: '',
          }
          for(let j in this.listData[i].addval) {
            if(this.listData[i].addval.length-1 == j) {
              if(this.listData[i].addval[j]) {
                obj.value += this.listData[i].addval[j]
              }
            }else {
              obj.value += this.listData[i].addval[j] + ','
            }
          }
          if(obj.value) {
            gameParams.push(obj)
          }
        }
      }
      this.loading = true
      let params = {
        id: this.gameItem.id,
        gameParam:  JSON.stringify(gameParams)
      }
      updateGameService({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res=>{
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleGameService()
          this.tipShow = false

        }else{
          this.$message.error(res.msg || '操作失败!');

        }
        this.loading = false
      }).catch(res=>{
        this.loading = false
        this.$message.error( '操作失败!');

      })
    },
    // 删除服务
    handleDeleteData(item) {
      deleteGameService({id: item.id}).then(res=>{
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleGameService()

        }else{
          this.$message.error(res.msg || '操作失败!');

        }
      })
    },
    // 接单大区事件
    handleDeployVal(data) {
      this.$forceUpdate()

    },
    // 擅长位置事件
    handleLocationVal(data,res) {
      let obj = {
        id: this.gameLocation.id,
        name: this.gameLocation.name,
        value: '',
      }

    },
    // 打开技能信息
    handleSkillData(item) {
      this.gameItem = item
      let gameDeploy = JSON.parse(item.gameParamsSource)
      let gameParams = JSON.parse(item.gameParams)
      this.listData = gameDeploy
      for(let i in this.listData) {
        for(let j  in  gameParams) {
          if(gameParams[j].id == this.listData[i].id) {
            gameParams[j].list = gameParams[j].value.split(',')
            this.listData[i].addval = gameParams[j].list
          }
        }
        this.listData[i].list = this.listData[i].value.split(',')
      }
      this.tipShow = true
    },
    // 关闭技能信息弹窗
    handleHeadCloseData() {
      this.tipShow = false
    },
    // 下架服务
    handleSetServe(item) {
      this.onlineStatus = 0
      this.handleOnlineData(item)

    },
    // 上架服务
    handleGetServe(item) {
      this.onlineStatus = 1
      this.handleOnlineData(item)
    },
    // 上下架接口
    handleOnlineData(item) {
      let params = {
        id: item.id,
        status: this.onlineStatus
      }
      updateGameService({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleGameService()

        }else {
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    handleFatherMethod() {
      this.serveTab = 0
      this.handleGameService()
    },
    handleAddServe(item,idx) {
      this.serveTab = idx
      this.infoItem = null
      if(idx == 1) {
        this.$refs.addServe.handleGetGame();
      }else if(idx == 2) {
        this.infoItem = item
        this.$refs.addServe.handleGetGame();
        setTimeout(()=>{
          this.$refs.addServe.getGameServiceInfo();
        },300)
      }else if(idx == 3) {
        this.getServiceApplyLog()

      }
      else {
        this.handleGameService()
        this.$refs.addServe.handleOriginal()
      }
    },
    handleGameService() {
      getGameService({ lgUserId: this.lgUserId }).then(res => {
        if(res.code == 200) {
          this.list = res.data
        }
      })
    },
    getServiceApplyLog() {
      let params = {
        lgUserId: this.lgUserId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }
      getServiceApplyLog(params).then(res => {
        if(res.code == 200) {
          this.applylist = res.rows
        }
      })

    },
  },
  created() {
    this.lgUserId = sessionStorage.lgUserId
  },
  mounted() {
    this.getOnline()
    this.handleGameService()
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
@import "./serveAdmin.less";
</style>
