<template>
  <div class="user">
    <div class="right-tab">
      <div v-for="(item,idx) in rightTab" :key="idx" :class="{'tab-show1':rightIdx == idx}" @click="handleRightTabData(item,idx)">{{item.name}}</div>
    </div>
    <div class="tab1" v-show="rightIdx == 0">
      <Material></Material>
    </div>
    <div class="tab2" v-show="rightIdx == 1">
      <Photo></Photo>
    </div>
  </div>
</template>
<script>

import Material from "../common/material";
import Photo from "../common/photo";
export default {
  name: "user",
  data() {
    return{
      rightTab:[
        {
          name: '基本资料',
        },
        {
          name: '相册管理',
        }
      ],
      idxShow: 0,
      rightIdx: 0,
    }
  },
  components: { Material,Photo },
  computed:{
  },
  methods: {
    handleRightTabData(item,idx) {
      this.rightIdx = idx

    }
  },
  created() {},
  mounted() {
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
.user{
    padding: 30px 24px  30px  0px;
  .right-tab{
    display: flex;
    padding: 0 19px;
    height: 44px;
    line-height: 44px;
    background: #FFFFFF;
    box-shadow: 0px 6px 25px 0px rgba(204, 219, 230, 0.3);
    border-radius: 19px;
    >div{
      font-size: 14px;
      color: #B3B3B3;
      margin-right: 32px;
      cursor: pointer;
    }
    .tab-show1{
      color: #000000;
      font-weight: 600;
    }

  }

}

</style>
