<template>
  <div class="setting">
    <div class="setting-item">
      <div class="item-title">设置中心</div>
      <div class="item-list">
        
        <div class="list-item">
          <div>会员卡支付密码</div>
          <div>**********</div>
          <div class="item-bnt item-bnt-g" @click="handlePassWordData">修改</div>
        </div>
        <div class="list-item">
          <div>手机号码</div>
          <div>{{phone?phone:'为绑定手机号,请绑定手机号!'}}</div>
          <div class="item-bnt item-bnt-b" @click="handlePhoneBntData">修改</div>
        </div>
      </div>
    </div>
    <!-- 修改手机弹窗 -->
    <div class="head-tip-bg" v-show="tipShowHide"></div>
    <div class="head-tip" v-show="phoneShow">
      <div class="tip-close" @click="handlePhoneCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-title">{{phoneTitle}}</div>
      <div class="tip-form">
        <div class="from-phone">
          <input :disabled="phoneDisabled" maxlength="11" v-model="telForm.phone" placeholder="请输入手机号"/>
        </div>
        <div class="from-code">
          <div class="code-input">
            <input v-model="telForm.code" placeholder="请输入短信验证码"/>
          </div>
          <div class="code-bnt" @click="handleCodeData" v-if="!codeShow">{{codeText}}</div>
          <div class="code-bnt"  v-else>{{codeNmu + 's'}}</div>
        </div>
      </div>
      <div class="bnt-add" @click="handlePhoneData">确认</div>
    </div>
    <div class="password-tip" v-show="passwordShow">
      <div class="tip-close" @click="handlePassWordCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-title">修改会员卡支付密码</div>
      <div class="tip-form">
        <div class="from-phone">
          <input :disabled="phoneDisabled" v-model="telForm.phone" maxlength="11" placeholder="请输入手机号"/>
        </div>
        <div class="from-code">
          <div class="code-input">
            <input v-model="telForm.code"   placeholder="请输入短信验证码"/>
          </div>
          <div class="code-bnt" @click="handleCodeData" v-if="!codeShow">{{codeText}}</div>
          <div class="code-bnt"  v-else>{{codeNmu + 's'}}</div>
        </div>
        <div class="from-phone from-item">
          <input type="password" v-model="telForm.newPassWord" maxlength="6" placeholder="请输入6位数字新密码"/>
        </div>
        <div class="from-phone from-item">
          <input type="password" v-model="telForm.confirmPassWord" maxlength="6" placeholder="请再输入一次6位数字新密码"/>
        </div>
      </div>
      <div class="bnt-add" @click="handlePassWordAdd">确认</div>
    </div>
  </div>
</template>
<script>
import { getWalletInfo,getUserInfo,getVerificationCode,updatePasswd,updatePhone,checkPhone } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: "setting",
  data() {
    return{
      tabIdx: 0,
      search: {
        time:[],
        status: 0
      },
      searchBnt: [
        {
          id: 0,
          name: '全部',
        },
        {
          id: 1,
          name: '收入',
        },
        {
          id: 2,
          name: '支出',
        },
      ],
      tipShowHide: false,
      phoneShow: false,
      phoneTitle: '绑定新手机',
      passwordShow: false,
      phone: '',
      telForm: {
        phone: '',
        code: '',
      },
      codeText: '获取验证码',
      codeNmu: 60,
      timer: null,
      codeShow: false,
      phoneDisabled: false,
      newPassWord: '',
      confirmPassWord: '',
      lgUserid: '',
      receipt: '',
    }
  },
  components: {  },
  computed:{
  },
  methods: {
    // 提交手机号
    handlePhoneData() {
      // true 走验证手机号 false 走修改
      if(this.phoneDisabled) {
        this.handlecheckPhone()
      } else {
        this.handleUpdatePhone()
      }
    },
    // 验证手机号
    handlecheckPhone() {
      let params= {
        lgUserid: this.lgUserid,
        phone: this.telForm.phone,
        code: this.telForm.code,
      }
      checkPhone({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if(res.code == 200) {
          this.receipt = res.data
          this.telForm.phone = ''
          this.telForm.code = ''
          this.phoneTitle = '绑定新手机'
          if(this.timer) {
            this.$once('hook:beforeDestroy', () => {   // 清除定时器      
              clearInterval(this.timer);      
              this.timer = null;                                 
            })
            clearInterval(this.timer);　　// 清除定时器
            this.timer = null;
            this.codeShow = false
            this.codeText = '获取验证码'
            this.codeNmu = 60

          }
          this.phoneDisabled = false
        }else {
          this.$message.error(res.msg || '操作失败!');

        }
      })

    },
    handleSearchBnt(item,idx) {
      this.search.status = item.id

    },
    handleAccountTabData(idx) {
      this.tabIdx = idx
    } ,
    //关闭修改手机弹窗
    handlePhoneCloseData() {
      this.tipShowHide = false
      this.phoneShow = false
      this.telForm.code = ''
      if(this.timer) {
        this.$once('hook:beforeDestroy', () => {   // 清除定时器      
          clearInterval(timer); 
          this.timer = null;                                      
        })
        clearInterval(this.timer);　　// 清除定时器
        this.timer = null;
        this.codeShow = false
        this.codeText = '获取验证码'

      }
    },
    handlePhoneBntData() {
      this.codeShow = false
      this.codeNmu = 60
      this.codeText = '获取验证码' 
      this.timer = null
      this.telForm.code = ''
      if(this.phone) {
        this.tipShowHide = true
        this.phoneShow = true
        this.phoneDisabled = true
        this.telForm.phone = this.phone
      } else {
        this.$message.error('请绑定手机号!');
        this.phoneDisabled = false
        this.telForm.phone = ''

      }
    },
    // 打开修改密码弹窗
    handlePassWordData() {
      this.codeShow = false
      this.codeNmu = 60
      this.codeText = '获取验证码' 
      this.timer = null
      this.telForm.newPassWord = ''
      this.telForm.confirmPassWord = ''
      if(this.phone) {
        this.tipShowHide = true
        this.passwordShow = true
        this.phoneDisabled = true
        this.telForm.phone = this.phone
      } else {
        this.$message.error('请绑定手机号!');
        this.phoneDisabled = false
        this.telForm.phone = ''

      }
    },
    // 关闭修改密码弹窗
    handlePassWordCloseData() {
      this.tipShowHide = false
      this.passwordShow = false
      if(this.timer) {
        this.$once('hook:beforeDestroy', () => {   // 清除定时器     
          clearInterval(timer);    
          this.timer = null;                                    
        })
        clearInterval(this.timer);　　// 清除定时器
        this.timer = null;
        this.codeShow = false
        this.codeText = '获取验证码'

      }

    },
    // 获取验证码
    handleCodeData() {
      let _this = this
      if(this.$utils.phoneVerif(this.telForm.phone)) {
        _this.$message.error('手机号码格式不正确~');
        return
      }
      this.codeShow = true
      if(this.codeShow) { 
        getVerificationCode({ phone: this.telForm.phone}).then(res => {
            if(res.code == 200) {
              this.timer = setInterval(()=>{
                _this.codeNmu--
                if(_this.codeNmu < 1 || _this.codeNmu == 0) {
                  _this.codeShow = false
                  _this.codeNmu = 60
                  _this.codeText = '获取验证码' 
                  clearInterval(_this.timer);
                  _this.timer = null
                  return true
                }
              },1000)

            }
        })
      }

      this.$once('hook:beforeDestroy', () => {            
        clearInterval(_this.timer);     
        _this.timer = null                               
      })
      
    },
     // 绑定手机号
    handleUpdatePhone() {
      var reg_tel = /^1[0-9]{10}$/; 
      if(!reg_tel.test(this.telForm.phone)) {
        this.$message.error('请输入正确的手机号!');
        return
      }

      let params= {
        lgUserid: this.lgUserid,
        phone: this.telForm.phone,
        code: this.telForm.code,
        receipt: this.receipt
      }
      updatePhone({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if(res.code == 200) {
          this.phoneTitle = '验证密保手机'
          this.telForm.phone = ''
          this.telForm.code = ''
          sessionStorage.phone = res.data
          this.phone =  sessionStorage.phone
          if(this.timer) {
            this.$once('hook:beforeDestroy', () => {   // 清除定时器
              clearInterval(this.timer);   
              this.timer = null;                                     
            })
            clearInterval(this.timer);　　// 清除定时器
            this.timer = null;
            this.codeShow = false
            this.codeText = '获取验证码'
            this.codeNmu = 60

          }
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handlePhoneCloseData()
          this.phoneDisabled = true
        }else {
          this.$message.error(res.msg || '操作失败!');

        }
      })
    },
    // 提交修改密码
    handlePassWordAdd() {
      
      var reg = new RegExp("^[0-9]*$");
      if(!this.telForm.phone) {
        this.$message.error('请输入手机号!');
        return
      }
      if(!this.telForm.code) {
        this.$message.error('请输入验证码!');
        return
      }
      if(!reg.test(this.telForm.newPassWord)){
        this.$message.error('密码请使用数字!');
        return
      }
      if(!this.telForm.newPassWord ||  !this.telForm.confirmPassWord) {
        this.$message.error('新密码或重复密码不能为空!');
        return
      }
      if(this.telForm.newPassWord !== this.telForm.confirmPassWord) {
        this.$message.error('重复密码不一致!');
        return
      }
      let params= {
        phone: this.telForm.phone,
        code: this.telForm.code,
        lgUserid: this.lgUserid,
        newPassword: this.telForm.confirmPassWord,
      }
      updatePasswd({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if(res.code == 200) {
          this.handlePassWordCloseData()
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
        }else{
          this.$message.error(res.msg || '操作失败!');

        }

      })

    },
  },
  created() {
  },
  mounted() {
    this.phone = sessionStorage.phone || ''
    this.lgUserid = sessionStorage.lgUserId
    if(this.phone) {
      this.telForm.phone = this.phone
      this.phoneTitle = '验证密保手机'
    }else{
      this.phoneTitle = '绑定新手机'

    }
  },
  destroyed() {
    // clearInterval(this.timer);　　// 清除定时器
    // this.timer = null;
    
  },
  beforeDestroy(){ 
    if(this.timer) {
      this.$once('hook:beforeDestroy', () => {   // 清除定时器      
        clearInterval(this.timer);   
        this.timer = null;                                    
      })
      clearInterval(this.timer);　　// 清除定时器
      this.timer = null;

    }
  },
};
</script>
<style lang="less">
.setting{
  color: #333333;
  padding: 30px 24px  30px  0px;
  .setting-item{
    background: #ffffff;
    padding: 29px 25px;
    margin-right: 20px;
    box-shadow: 0px 6px 25px 0px rgba(204, 219, 230, 0.3);
    border-radius: 19px;
    .item-title{
      font-weight: bold;
      color: #000000;
      font-size: 14px;
      padding-bottom: 25px;
      border-bottom: 1px solid #E8EBFA;
    }
    .list-item{
      color: #666666;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-top: 45px;

      >div{

      }
      >div:nth-child(1){
        width: 110px;
      }
      >div:nth-child(2){
        margin-left: 15px;
      }
      .item-bnt{
        width: 81px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        margin-left: auto;
        border-radius: 6px;
        color: #ffffff;
        cursor: pointer;
        &.item-bnt-g{
          background: linear-gradient(0deg, #54DDB0 0%, #49F367 100%);
          box-shadow: 0px 13px 19px 0px rgba(84, 225, 177, 0.2);

        }
        &.item-bnt-b{
          background: linear-gradient(0deg, #30B1EA 0%, #69E5FE 100%);
          box-shadow: 0px 13px 19px 0px rgba(58, 179, 236, 0.2);

        }
      }
    }
  }
  .head-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 22;

  }
  .head-tip,.password-tip{
    position: fixed;
    width: 345px;
    padding: 0 46px 30px 46px;
    top: 330px;
    left: 50%;
    background: #fff;
    box-shadow: 0px 13px 31px 0px rgba(204, 219, 230, 0.35);
    transform: translate(-50%,-50%);
    z-index: 33;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .tip-title{
      margin-top: 30px;
      color: #1A1A1A;
      font-size: 16px;
      font-weight: bold;
    }
    .tip-bg{
      width: 100%;
      height: 346px;
      background: #DCE5EE;
      border: 6px;
      margin-top: 38px;
      .bg-show{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #E7EEF6;
      }
    }
    .bnt-add{
      width: 88px;
      height: 38px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      cursor: pointer;
      margin-top: 40px;
      background: #5564F3;
      box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
    }
    .tip-form{
      color: #333333;
      font-size: 12px;
      margin-top: 36px;
      .from-phone{
        height: 44px;
        line-height: 44px;
        padding: 0 10px ;
        background: #FFFFFF;
        border: 1px solid #E1E4F2;
        border-radius: 6px;
        input{
          width: 100%;
          height: 100%;
          outline: none;
          background: none;
          border: none;
        }
      }
      .from-item{
        margin-top: 10px;

      }
      .from-code{
        display: flex;
        margin-top: 10px;
        >div{
          height: 44px;
        }
        .code-input{
          width: 230px;
          padding: 0 10px;
          background: #FFFFFF;
          border: 1px solid #E1E4F2;
          border-radius: 6px;
          input{
            width: 100%;
            height: 100%;
            outline: none;
            background: none;
            border: none;
          }
        }
        .code-bnt{
          width: 90px;
          margin-left: 6px;
          line-height: 44px;
          text-align: center;
          border: 1px solid #FDC7C7;
          border-radius: 6px;
          color: #F02727;
          cursor: pointer;
        }

      }
    }
    
  }
  .password-tip{
    top: 44.5%;
  }

} 

</style>
