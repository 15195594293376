<template>
  <div class="move">
    <div class="move-title">
      <div class="title-h3">实名认证</div>
      <!-- <div class="title-back">返回</div> -->
    </div>
    <div class="move-form">
      <el-form name="addForm" :model="addForm">
        <el-form-item label="">
          <div class="form-item">
            <span>姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：</span>
            <div class="item-input">
              <input maxlength="10" v-model="addForm.name" placeholder="请输入真实姓名"/>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="">
          <div class="form-item">
            <span>身份证号：</span>
            <div class="item-input">
              <input maxlength="18" v-model="addForm.cardNumber" placeholder="二代身份证号"/>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="form-item">
            <div class="item-card">
              <div class="card-title">身份证正面</div>
              <div class="card-up">
                <img class="card-img_hide" v-if="!frontImgShow" src="~@/assets/images/balance/user/move/front.png"/> 
                <img class="card-img_show" v-else :src="frontImgShow"/> 

                <div v-if="!frontImgShow">上传身份证正面</div>
                
                <el-upload
                class="avatar-uploader"
                ref="upload"
                action="aa"
                list-type="picture"
                :multiple="false"
                :limit=2
                :on-change="handleFrontImmgChange"
                :before-upload="handlePreview"
                :on-remove="handleRemove"
                :show-file-list="false"
                name="cardFrontImgFile"
                :auto-upload="false">
                </el-upload>
              </div>
            </div>
            <div class="item-card item-verso">
              <div>身份证反面</div>
              <div class="card-up">
                <img class="card-img_hide" v-if="!versoImgShow" src="~@/assets/images/balance/user/move/verso.png"/> 
                <img class="card-img_show" v-else :src="versoImgShow"/>
                <div  v-if="!versoImgShow">上传身份证反面</div>
                <el-upload
                class="avatar-uploader"
                ref="upload" 
                action="aa"
                list-type="picture"
                :multiple="false"
                :limit=2
                :on-change="handleVersoImmgChange"
                :before-upload="handlePreview"
                :on-remove="handleRemove"
                name="cardVersoImgFile"
                :show-file-list="false"
                :auto-upload="false">
                </el-upload>
              </div>
            </div>
          </div>

        </el-form-item>
        <el-form-item>
          <div class="form-item">
            <span>手&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;机：</span>
            <div class="item-input">
              <input maxlength="11" v-model="addForm.phone" placeholder="请输入手机号码"/>
              
            </div>
            <div class="code-bnt" @click="handleCodeData" v-if="!codeShow">{{codeText}}</div>
            <div class="code-bnt"  v-else>{{codeNmu + 's'}}</div>
          </div>

        </el-form-item>
        <el-form-item>
          <div class="form-item">
            <span>短信验证码：</span>
            <div class="item-input">
              <input maxlength="11" v-model="addForm.code" placeholder="请输入验证码"/>
            </div>
          </div>
        </el-form-item>
        
        <el-form-item>
          <div class="form-add">
            <div class="add-but" @click="handleMoveData">确定提交</div>
            <p class="add-text">霖感电竞平台将尊重您的隐私并严格保密</p>
          </div>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>
<script>
import { playerApply,getVerificationCode,getUserInfo  } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: "move",
  data() {
    return{
      addForm: {
        phone: '',
        cardNumber: '',
        name: '',
        code: '',
        cardFrontImgFile: '', 
        cardVersoImgFile: '', 
      },
      frontImgShow: '',
      versoImgShow: '',
      lgUserId:  '',
      codeNmu: 60,
      timer: null,
      codeText: '获取验证码',
      codeShow: false

    }
  },
  components: {  },
  computed:{
  },
  methods: {
    // 获取验证码
    handleCodeData() {
      let _this = this
      if(this.$utils.phoneVerif(this.addForm.phone)) {
        _this.$message.error('手机号码格式不正确~');
        return
      }
      this.codeShow = true
      if(this.codeShow) { 
        getVerificationCode({ phone: this.addForm.phone}).then(res => {
            if(res.code == 200) {
              this.timer = setInterval(()=>{
                _this.codeNmu--
                if(_this.codeNmu === 0) {
                  clearInterval(_this.timer);
                  _this.codeShow = false
                  _this.codeNmu = 60
                  _this.codeText = '获取验证码' 
                  _this.timer = null
                  return true
                }
              },1000)

            }
        })
      }

      this.$once('hook:beforeDestroy', () => {            
        clearInterval(_this.timer);   
        _this.timer = null                                 
      })
      
    },
    handleMoveData() {//提交
      if(!this.addForm.name) {
        this.$message.error('姓名不能为为空~');
        return
      } 
      if(!this.addForm.cardNumber) {
        this.$message.error('身份证号码不能为为空~');
        return
      } 
      if(!this.addForm.cardFrontImgFile) {
        this.$message.error('身份证正面不能为空~');
        return
      } 
      if(!this.addForm.cardVersoImgFile) {
        this.$message.error('身份证反面不能为空~');
        return
      } 
      if(this.$utils.phoneVerif(this.addForm.phone) || !this.addForm.phone) {
        this.$message.error('手机号码格式不正确~');
        return
      } 
      if(!this.addForm.code) {
        this.$message.error('短信验证码不能为为空~');
        return
      } 
      this.addForm.lgUserId = this.lgUserId
      const formData = new FormData();
      Object.keys(this.addForm).forEach(key => {
        if (key == "cardFrontImgFile") {
          formData.append('cardFrontImgFile',  this.addForm.cardFrontImgFile);
        } else if(key == "cardVersoImgFile"){
          formData.append('cardVersoImgFile', this.addForm.cardVersoImgFile);
        }else{
          formData.append(key, this.addForm[key]);

        }
      });
      playerApply(formData).then(res => {
        if (res.code == 200) {
          this.handleGetUserInfo()
          this.$message({
            message: res.msg,
            type: 'success',
            customClass: 2000
          });
        }else{
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    handleRemove(file, fileList) {
    },
    handlePreview(file) {
    },
    // 正面
    handleFrontImmgChange(file,fileList) {
      if (fileList.length > 1) {
          fileList.splice(0, 1);
      }
      this.addForm.cardFrontImgFile = file.raw 
      if(file.response){
        this.headShow = false
        if(file.response.code == 200) {
          this.$message({
              message: file.response.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

        }else{
          this.$message.error(file.response.msg || '操作失败!');

        }
      } 
      this.frontImgShow = URL.createObjectURL(fileList[0].raw);
    },
    // 身份证反面
    handleVersoImmgChange(file,fileList) {
      if (fileList.length > 1) {
          fileList.splice(0, 1);
      }
      this.addForm.cardVersoImgFile = file.raw 
      // this.addForm.versoImg = fileList
      if(file.response){
        this.headShow = false
        if(file.response.code == 200) {
          this.$message({
              message: file.response.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

        }else{
          this.$message.error(file.response.msg || '操作失败!');

        }
      } 
      this.versoImgShow = URL.createObjectURL(fileList[0].raw);
    },
    /**
     * 获取个人信息
     */
    handleGetUserInfo() {
      getUserInfo({ lgUserId: this.lgUserId }).then(res => {
        if (res.code == 200) {
          sessionStorage.lgUserId = res.data.lgUserId;
          sessionStorage.playWith = res.data.playWith
          this.$parent.handlePlayWithData();

        }
      });
    },

  },
  created() {
    this.lgUserId = sessionStorage.lgUserId
  },
  mounted() {
  },
  destroyed() {
    
  },
  beforeDestroy(){ 
    if(this.timer) {
      this.$once('hook:beforeDestroy', () => {   // 清除定时器   
        clearInterval(this.timer);   
        this.timer = null;                                       
      })
    }
  },
};
</script>
<style lang="less">
@import "./move.less";
</style>
