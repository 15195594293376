<template>
  <div class="dynamic" :class="{'dynamic-bg' :listData.length > 0}">
    <div class="square-left">
      <div class="left-list" id="left-list" ref="scrollBox" @scroll="scrollEvent " v-show="listData.length > 0">
        <div class="list-all" v-for="(item,idx) in listData" :key="idx">
          <div class="list-head">
            <div class="head-left">
              <img v-if="item.headImg" :src="item.headImg" />
              <img v-else src="~@/assets/images/balance/user/headImg.png" />
            </div>
            <div class="head-right">
              <div class="right-name">
                <div class="name-text">{{item.nickName}}</div>
                <img v-if="item.gender == 0" src="~@/assets/images/balance/square/sex1.png" />
                <img v-else src="~@/assets/images/balance/square/sex2.png" />
              </div>
              <div class="right-time">{{item.createTime}}</div>
            </div>
          </div>
          <div class="all-content">
            <span class="content-topicName" v-if="item.topicName">#{{item.topicName}}#</span>
            <span v-html="item.content.replace(/(\r\n|\n|\r)/gm,'<br />')"></span>
          </div>
          <div class="all-images" v-if="item.imgUrls.length > 0">
            <div class="images-one" v-if="item.imgUrls.length == 1">
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
            <div class="images-nine" v-else-if="item.imgUrls.length == 9">
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
            <div class="images-lists" v-else>
              <div v-for="(itemImmg,imgIdx) in  item.imgUrls" :key="imgIdx">
                <img :src="itemImmg.imgUrl" @click="handleImgBigOpen(item,idx,imgIdx)"/>
              </div>
            </div>
          </div>
          <div class="all-top">
            <div @click="handleLikeData(item,idx)" class="top-item">
              <div class="praise">
                <img v-if="item.isLike == 2" src="~@/assets/images/balance/square/praise.png" />
                <img v-else src="~@/assets/images/balance/square/praiseShow.png" />
              </div>
              <div>点赞{{item.likeCount}}</div>
            </div>
            <div @click="handleOpenMessageData(item)" class="top-item">
              <div class="comment">
                <img src="~@/assets/images/balance/square/comment.png" />
              </div>
              <div>评论 {{item.commentCount}}</div>
            </div>
            <div>
              <div class="gift">
                <img src="~@/assets/images/balance/square/gift.png" />
              </div>
              <div>打赏</div>
              <div class="gift-money" v-if="item.rewardMoney > 0">{{item.rewardMoney}}</div>
            </div>
            <div class="top-mor">
              <img src="~@/assets/images/balance/user/mor.png" @click="handleOpenDelete(item,idx)"/>
              <div class="mor-all" v-show="item.deleteShow">
                <div class="mor"></div>
                <div class="mor-del" @click="handleDeleteData(item)">
                  <img class="" src="~@/assets/images/balance/user/delete.png"/>
                  <div>删除</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lists-no" v-show="listData.length == 0">
        <div class="no-data">
          <img src="~@/assets/images/balance/no-data.gif">
        </div>
      </div>
    </div>
    <!--回复弹窗 -->
    <div class="head-tip-bg" v-show="messageShow"></div>
    <div class="head-tip message-tip" v-show="messageShow">
      <div class="tip-close" @click="handleMessageCloseData" style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <Message ref="messageChild"></Message>
    </div>
    <!-- 照片弹窗 -->
    <div class="image-show-big" v-show="imgBigShow" @click="handleImgBigClose"></div>
    <div class="image-pre"  v-show="imgBigShow" @click="handlePreBigImg">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="image-show"  v-show="imgBigShow">
      <div class="show-item">
        <img :src="bigImgUrl"/>
        <div class="item-page">
          <div class="page-idx" :class="{'page-show':idx == imgBigIdx}" v-for="(item,idx) in bigImgs" :key="idx"></div>
          <!-- <div class="page-show"></div>
          <div class="page-idx"></div> -->
        </div>
      </div>
    </div>
    <div class="image-next"  v-show="imgBigShow"  @click="handleNextBigImg">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>
<script>
const {shell} = window.require('electron');
import {
  squareList,
  giveLike,
  dynamicDelete
} from "@/api/balance/square";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  userinfo
} from "@/api/wallet";
import Message from "../../square/components/message";
export default {
  name: "square",
  data() {
    return {
      messageShow: false,
      listData: [], // 存放动态
      active: 1,
      loading: false,
      totalNum: 0,
      pageSize: 10,
      imgBigShow: false,
      bigImgs:[],
      bigImgUrl: '',
      imgBigIdx: 0,
      deleteShow: false
    };
  },
  components: { Message },
  computed: {},
  methods: {
    handleDeleteData(item) {
      dynamicDelete({id: item.id}).then(res => {
        if (res.code == 200) {
          this.$message({
            message: res.msg || '操作成功!',
            type: 'success',
            customClass: 2000
          });
          this.firstData()
        }else {
          this.$message.error(res.msg || '删除失败!');
        }
      });
    },
    // 打开删除按钮
    handleOpenDelete(item,idx) {
      this.listData[idx].deleteShow = !this.listData[idx].deleteShow 
      this.$forceUpdate();
    },
    // 上一张
    handlePreBigImg() {
      if(this.imgBigIdx == 0) {
        this.imgBigIdx = 0
      }else {
        this.imgBigIdx--
        this.bigImgUrl = this.bigImgs[this.imgBigIdx].imgUrl
      }
    },
    // 下一张
    handleNextBigImg() {
      if(this.imgBigIdx == this.bigImgs.length-1) {
        this.imgBigIdx = this.bigImgs.length-1
      }else {
        this.imgBigIdx++
        this.bigImgUrl = this.bigImgs[this.imgBigIdx].imgUrl
      }

    },
    // 打开大图
    handleImgBigOpen(item,idx,imgIdx) {
      this.imgBigIdx = imgIdx
      this.bigImgUrl =  item.imgUrls[imgIdx].imgUrl
      this.bigImgs = item.imgUrls
      this.imgBigShow = true
    },
    // 关闭查看大图
    handleImgBigClose() {
      this.imgBigShow = false
    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#left-list").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 50) {
        if (
          this.listData.length > this.totalNum ||
          this.totalNum == this.listData.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    loadMore() {
      this.handleSquareList();
    },
    // 点赞
    handleLikeData(item, idx) {
      let status;
      if (item.isLike == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        dynamicId: item.id,
        lgUserId: sessionStorage.lgUserId,
        type: 1,
        isLike: status
      };
      giveLike(params).then(res => {
        if (res.code == 200) {
          this.$refs.messageChild.handleSquareInfo(item);
        }
      });
    },
    // 打开评论弹窗
    handleOpenMessageData(item) {
      this.messageShow = true;
      this.$refs.messageChild.handleSquareInfo(item);
      this.$refs.messageChild.handlecommentList(item);
    },
    // 关闭评论弹窗
    handleMessageCloseData() {
      this.messageShow = false;
    },
    // 动态列表
    handleSquareList() {
      let params = {
        lgUserId: sessionStorage.lgUserId,
        pageSize: this.pageSize,
        pageNum: this.active,
        type: 1
      };
      squareList(params).then(res => {
        if (res.code == 200) {
          let arr = res.rows;
          this.totalNum = res.total;
          this.listData = this.listData.concat(res.rows);
          for(let i in this.listData) {
            this.listData[i].deleteShow = false
          }
          this.loading = this.active < res.total;
        }
      });
    },
    
    // 首次加载
    firstData() {
      this.listData = []
      this.active = 1
      this.handleSquareList();
    },
    // 子组件刷新数据
    handleMessageChildData(item){
      for(let i in this.listData) {   
        if(this.listData[i].id == item.id) {
          this.listData[i] = item 
        }
      }
      this.$forceUpdate();

    }
  },
  created() {
    this.$nextTick(() => {
      this.firstData();
    });
  },
  mounted() {
    // 监听外部盒子的滚动事件
    this.$refs.scrollBox.addEventListener("scroll", this.scrollEvent);
  },
  destroyed() {}
};
</script>
<style lang="less">
</style>
<style lang="less" scoped>
@import "./dynamic.less";
.lists-no{
  width: 100%;
  height: 600px;
  position: relative;
}
.no-data{
  text-align: center;
  position: absolute;
  color: #999;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 3;
  >img{
    width: 300px;
    height: 300px;
  }
}
.reward-tip-bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 88;

}
.reward-tip{
  position: fixed;
  width: 410px;
  top: 315px;
  left: 50%;
  background: #fff;
  padding: 30px 63px 30px 28px;
  transform: translate(-50%,-50%);
  z-index: 99;
  .reward-close{
    position: absolute;
    width: 40px;
    height: 40px;
    top: -26px;
    right: -20px;
    z-index: 44;
    cursor: pointer;
    img{
      width: 100%;
      height: 100%;
    }

  } 
  .reward-item{
    .reward-title{
      font-size: 16px;
      font-weight: bold;
    }
    .reward-con{
      margin-top: 30px;
      .con-flex{
        display: flex;
        >div{
          width: 140px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          font-size: 14px;
          border: 1px solid #CCCCCC;
          border-radius: 5px;
          color: #666666;
          cursor: pointer;
          &.reward-name{
            border: 1px solid #5564F3;
            color: #5564F3;
          }
          &:nth-child(2){
            margin-left: 20px;
          }
        }
      }
      .con-input{
        margin-top: 30px;
        .input-title{
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .title-show{
            width: 6px;
            height: 6px;
            background: #5564F3;
            border-radius: 50%;
          }
          .title-text{
            margin-left: 11px;
            font-size: 14px;
            color: #333;
            font-weight: 600;
          }
        }
        .input-item{
          width: 400px;
          height: 40px;
          background: #FFFFFF;
          // border: 1px solid #CCCCCC;
          border-radius: 5px;
          // padding: 0 10px;
          font-size: 14px;
          .el-input__inner{
            border: none;
            background: none;
          }
        }
      }
    }
  }
  .reward-foot{
    margin-top: 44px;
    width: 88px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #5564F3;
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    box-shadow: 0 10px 15px rgba(85, 100, 243, 0.2);
    cursor: pointer;
  }
}
.image-show-big{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  z-index: 66;

}
.image-show{
  position: fixed;
  max-width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 77;
  .show-item{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 600px;
    text-align: center;
    >img{
      // width: 100%;
      max-width: 100%;
      // height: 100%;
      // max-height: 80%;
    }
    .item-page{
      display: flex;
      position: absolute;
      bottom: 5%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 78;
      >div{
        margin-right: 4px;
        background:   #73edff;
        border-radius: 2px;
        &:last-child{
        margin-right: 0px;

        }
      }
      .page-show{
        width: 19px !important;
        height: 4px ;
      }
      .page-idx{
        width: 5px;
        height: 4px;
      }
    }
  }
  
}
.image-pre,.image-next{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 13px;
  background: #999999;
  z-index: 77;
  cursor: pointer;
  >i{
    font-size: 18px;
    color: #FFFFFF;
  }
}
.image-pre{
  top: 50%;
  left: 5%;
  transform: translate(-50%,-50%);

}
.image-next{
  top: 50%;
  right: 0%;
  transform: translate(-50%,-50%);

}
</style>
