<template>
  <div class="userinfo">
    <div class="userinfo-left">
      <div class="left-tab " :class="{'tab-show':item.id == idxShow}" v-for="(item,idx) in tab" :key="idx" @click="handleTabData(item,idx)">
        <img :class="'tab-' + idx" v-if="item.id == idxShow" :src="item.imgUrlShow"/>
        <img :class="'tab-' + idx"  v-else :src="item.imgUrl"/>
        <div>{{item.name}}</div>
        
      </div>
    </div>
    <div class="userinfo-right">
      <User v-if="idxShow == 1"></User>
      <Account v-if="idxShow == 2"></Account>
      <Setting v-if="idxShow == 3"></Setting>
      <PlayWith v-if="idxShow == 4"></PlayWith>
      <!-- 入驻实名认证 -->
      <Move v-if="idxShow == 6 && playWith==1 "></Move>
      <serve-admin v-if="idxShow == 6 && playWith!=1 "></serve-admin>
      <!-- 动态 -->
      <Dynamic v-if="idxShow == 7"></Dynamic>
      <ShopList v-if="idxShow == 8"></ShopList>
    </div>
  </div>
</template>
<script>

// import Head from "@/components/head";
// import Guessing from "./common/guessing";
import User from "./components/user";
import Account from "./components/account";
import Setting from "./components/setting";
import PlayWith from "./components/playWith";
import Move from "./components/move";
import ServeAdmin from "./components/serveAdmin";
import Dynamic from "./components/dynamic";
import ShopList from "./components/shopList";
export default {
  name: "userInfo",
  data() {
    return{
      tab:[
        {
          id: 1,
          name: '个人中心',
          imgUrl: require('@/assets/images/balance/info.png'),
          imgUrlShow: require('@/assets/images/balance/infoShow.png')
        },
        {
          id: 2,
          name: '我的账户',
          imgUrl: require('@/assets/images/balance/account.png'),
          imgUrlShow: require('@/assets/images/balance/accountShow.png')
        },
        {
          id: 3,
          name: '设置中心',
          imgUrl: require('@/assets/images/balance/set.png'),
          imgUrlShow: require('@/assets/images/balance/setShow.png')
        },
        {
          id: 4,
          name: '陪玩订单',
          imgUrl: require('@/assets/images/balance/order.png'),
          imgUrlShow: require('@/assets/images/balance/orderShow.png')
        },
        // {
        //   id: 5,
        //   name: '我的关注',
        //   imgUrl: require('@/assets/images/balance/attention.png'),
        //   imgUrlShow: require('@/assets/images/balance/attentionShow.png')
        // },
        {
          id: 6,
          name: '申请入驻',
          imgUrl: require('@/assets/images/balance/apply.png'),
          imgUrlShow: require('@/assets/images/balance/applyShow.png')
        },
        {
          id: 7,
          name: '我的动态',
          imgUrl: require('@/assets/images/balance/user/message.png'),
          imgUrlShow: require('@/assets/images/balance/user/messageShow.png')
        },
        {
          id: 8,
          name: '商城订单',
          imgUrl: require('@/assets/images/balance/user/cat.png'),
          imgUrlShow: require('@/assets/images/balance/user/catShow.png')
        },
      ],
      rightTab:[
        {
          name: '基本资料',
        },
        {
          name: '相册管理',
        }
      ],
      idxShow: 1,
      rightIdx: 0,
      playWith: 1 , // 是否入驻
    }
  },
  components: { User,Account,Setting,PlayWith,Move,ServeAdmin,Dynamic,ShopList },
  computed:{
  },
  methods: {
    handleTabData(item,idx){
      this.playWith = sessionStorage.playWith
      this.idxShow = item.id
    },
    handleRightTabData(item,idx) {
      this.rightIdx = idx

    },
    handlePlayWithData() {
      this.playWith = sessionStorage.playWith
      if(this.playWith == 1) {
        this.tab[4].name = '申请入驻'
      }else {
        this.tab[4].name = '服务管理'

      }

    },
  },

  created() {},
  mounted() {
    if(this.$route.query.status ) {
      this.idxShow = 2

    }
    if(sessionStorage.playWith) {
      this.playWith = sessionStorage.playWith

    }
    this.handlePlayWithData()
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
