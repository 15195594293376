<template>
  <div class="address">
    <!-- 地址弹窗 -->
    <div class="address-tip-bg" v-show="tipShow"></div>
    <div class="address-tip" v-show="tipShow">
      <div class="tip-all">
        <div class="all-title">新增收货地址</div>
        <ul>
          <li>
            <div class="li-label">收件人:</div>
            <div class="li-val"> 
              <input v-model="addForm.name" maxlength="10"  placeholder="请输入收件人"/>
            </div>
          </li>
          <li>
            <div class="li-label">手机号:</div>
            <div class="li-val">
              <input v-model="addForm.phone" maxlength="11"  placeholder="请输入手机号码"/>
            </div>
          </li>
          <li>
            <div class="li-label">所在省市:</div>
            <el-select v-model="pCode" @change="changeProvince" placeholder="请选择省市">
              <el-option
                v-for="item in pList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </li>
          <li>
            <div class="li-label">所在市:</div>
            <el-select v-model="cCode" @change="changeCity" placeholder="请选择市区">
              <el-option
                v-for="item in cList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
            </el-select>
          </li>
          <li>
            <div class="li-label">所在区县:</div>
            <el-select v-model="aCode" @change="changeArea" placeholder="请选择县">
              <el-option
                v-for="item in aList"
                :key="item.code"
                :label="item.name"
                :value="item.code">
              </el-option>
            </el-select>
          </li>
          <li>
            <div class="li-label">详细地址:</div>
            <div class="li-val">
              <textarea v-model="addForm.address" maxlength="30" placeholder="请输入详情地址"></textarea>
            </div>
          </li>
          <li>
            <div class="li-box">
              <el-checkbox v-model="isChecked" @change="handleIsChecked">设为默认地址</el-checkbox>

            </div>
          </li>
        </ul>
        <div class="bnts">
          <div class="bnts-pass" @click="handleTipCloseData">关闭</div>
          <div class="bnts-add"  @click="handleAddData">保存</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProvince,editReceiptAddress,addReceiptAddress 
} from "@/api/balance/shoping";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,imageToBase64,base64ToFile }  from '@/common/utils/des'
export default {
  name: "addressTip",
  data() {
    return{
      tipShow: false,
      areaName: '',
      aName: '',
      cName: '',
      pName: '',
      aCode: '',
      cCode: '',
      pCode: '',
      value: '',
      isChecked: false,
      addForm:{
        name: '',
        phone: '',
        address: '',
        area:'',
        lgUserId: '',
        isDefault: 2
      },
      pList: [],
      cList: [],
      aList: [],
    }
  },
  watch: {
  },
  computed:{
  },
  methods: {  
    handleIsChecked(data) {
      if(data == true) {
        this.addForm.isDefault == 1
      }else{
        this.addForm.isDefault == 2
      }
    },
    // 省
    changeProvince(data) {
      for(let i in  this.pList) {
        if(data == this.pList[i].code) {
          this.pName = this.pList[i].name
        }
      }
      this.handleCity(data)

    },
    // 市
    changeCity(data) {
      for(let i in  this.cList) {
        if(data == this.cList[i].code) {
          this.cName = this.cList[i].name
        }
      }
      this.handleArea(data)

    },
    // 区
    changeArea(data) {
      for(let i in  this.aList) {
        if(data == this.aList[i].code) {
          this.aName = this.aList[i].name
        }
      }

    },
    // 省列表
    handleProvince(){
      getProvince({type: 1}).then(res => {
        this.pList = res.data
      })

    },
    // 市列表
    handleCity(code){
      getProvince({type: 2, code:code}).then(res => {
        this.cList = res.data
      })

    },
    //区列表
    handleArea(code){
      getProvince({type: 3,code:code}).then(res => {
        this.aList = res.data
      })

    },
    handleTipCloseData() {
      this.tipShow = false
    },
    handleAddressOpen() {
      this.tipShow = true
      this.handleProvince()
    },
    handleAddData() {
      if(!this.addForm.name) {
        this.$message.error('请输入姓名!');
        return
      }
      if(this.$utils.phoneVerif(this.addForm.phone)) {
        this.$message.error('手机号码格式不正确~');
        return
      }
      if(!this.addForm.address) {
        this.$message.error('请输入详细地址!');
        return
      }
      if(!this.pName) {
        this.$message.error('请选择省市!');
        return
      }
      if(!this.cName) {
        this.$message.error('请选择市!');
        return
      } 
      if(!this.aName) {
        this.$message.error('请选择县区!');
        return
      }
      this.addForm.area = this.pName +  this.cName +  this.aName
      this.addForm.lgUserId = sessionStorage.lgUserId
      addReceiptAddress(this.addForm).then(res => {
        if(res.code == 200) {
          this.$message({
            message: res.msg || '新增成功!',
            type: 'success',
            customClass: 2000
          });
          this.handleTipCloseData()
        }else{
          this.$message.error(res.msg || '保存失败!');
        }
        
      })
    },
  },
  created() {},
  mounted() {
  },
  beforeDestroy(){ 
  },
  destroyed() {
  }
};
</script>
<style lang="less" scoped>    
@import "./addressTip.less";
</style>
 