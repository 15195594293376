<template>
  <div class="shop-details">
    <div class="details-back" @click="handleBackData">
      <img src="~@/assets/images/balance/game/back.png"/>
    </div>
    <!-- 这是详情 -->
    <div class="details-flex">
      <div class="flex-left">
        <div class="left-top">
          <div class="left-imgs">
            <div class="imgs-big"  @mouseenter="enter()" @mouseleave="leave()" >
              <!-- <img :src="imgShow"> -->
               <pic-zoom :url="imgShow" class="big-show" :scale="3"></pic-zoom>
              <div class="big-icon" v-show='bigIcon'>
                <i class="el-icon-search"></i>
              </div>
            </div>
            <div class="imgs-list">
              <div class="list-pre" @click="handlePretData" v-if="imgList.length>5"><i  class="el-icon-arrow-left
              "></i></div>
              <div class="list-data"  ref="listImg">
              
                <div class="data-imgs" v-for="(item,idx) in imgList" :key="idx"  @click="handleImgsData(item,idx)" :style="{transform: `translate3d(-${widthShow}px, 0px, 0px)`}">
                  <img :class="{'imgs-show': idx == imgIdx}" v-if="imgList.length>0" :src="item">
                  <!-- <img class="no-img" v-else src="~@/assets/images/balance/game/noImg.png"> -->
                </div>
              </div>
              <div class="list-next" @click="handleNextData" v-if="imgList.length>5"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
          <div class="left-item" >
            <div class="item-title">{{obj.name}}</div>
            <div class="item-money" v-if="!obj.price">￥{{obj.minPrice}}~{{obj.maxPrice}}</div>
            <div class="item-money" v-else>￥{{obj.price || 0}}</div>
            <div class="item-inventory" v-if="!obj.stock">库存：{{obj.totalStock || 0}}</div>   
            <div class="item-inventory" v-else>库存：{{obj.stock || 0}}</div>   
            <div class="item-color" v-for="(item,idx) in obj.productAttrs" :key="idx">
              <div class="color-text"> 选择{{item.name}}</div>
              <div class="color-list"  v-for="(valItem,index) in item.values" :key="index"
              > 
                <div  @click="handleValueData(valItem.value,idx,$event,index)
              " class="list-item" :class="[subIndex[idx] == index?'productActive':'']">{{valItem.value}}</div>
               
              </div>
            </div>
            <div class="item-bnt">
              <el-input-number v-model="num"  controls-position="right" @change="handleNumChange" :min="minNum" :max="maxNum"></el-input-number>
              <div class="bnt-add bnt-item" v-if="bntAddShow" @click="handlePayOrder">立即下单</div>
              <div class="bnt-add-hide bnt-item" v-else>立即下单</div>
            </div>
            <div class="item-tip">温馨提示：仅支持到店自取</div>
          </div>
        </div>
        <div class="left-bottom" v-if="detailImgList.length > 1">
          <img :src="item" v-for="(item,idx) in detailImgList" :key="idx" />
          
        </div>
        <div class="left-bottom" v-else>
          <img :src="obj.detailImg"/>
          
        </div>
      </div>
      <div class="flex-right">
        <div class="right-title">看了又看</div>
        <ul class="right-ul">
          <li v-for="(item,idx) in recommendList" :key='idx' @click="handlerecommendData(item,idx)">
            <div class="li-img">
              <img :src="item.coverImg"/>
            </div>
            <div class="li-shopname">{{item.name}}</div>
            <div class="li-money">￥{{item.minPrice}}</div>
          </li>
        </ul>
      </div>
    </div>
    <play-tip ref="payTip" v-bind:skuObj="checlidObj"></play-tip>
    <ShopOrder ref="shopOrder"></ShopOrder>
  </div>
</template>

<script>
import PlayTip from "./common/playTip";
import ShopOrder from "@/views/balance/components/shopOrder"
import PicZoom from "vue-piczoom"
import {
  getProductInfo,selectRecommend,notesBrowsing
} from "@/api/balance/shoping";
export default {
  name: "shopDetails",
  data() {
    return{
      bntAddShow: true, // 控制按钮状态
      shopOrderShow: false,
      maxNum: 99,
      minNum: 1,
      num: 1,
      imgIdx: 99,
      widthShow: 0,
      imgShow: '',
      bigIcon: true,
      id: '',
      obj: {},
      valIdx: '',
      statusId: '',
      skuObj: {},
      imgList: [],
      detailImgList:[],
      skuShow: false,
      checlidObj: {
        buyNum: 0
      },
      recommendList: [],
      // 新增
      
      selectArr: [], //存放被选中的值
      shopItemInfo: {}, //存放要和选中的值进行匹配的数据
      subIndex: [], //是否选中 因为不确定是多规格还是单规格，所以这里定义数组来判断
      price:'' //选中规格的价钱

    }
  },
  components: {PicZoom,PlayTip,ShopOrder},
  computed:{
  },
  methods: {  
    handleListData() {
      this.handleProductInfo()

    },
    handlerecommendData(item) {
      this.id = item.id
      this.handleProductInfo()
      this.handleSelectRecommend()
    },
    handlePayOrderData(item) {
      this.$refs.payTip.handleOrderListPay(item)

    },
    handleOpenData() {
      this.handleProductInfo()
      this.$refs.shopOrder.handleOrderList();
    },
    // 选择sku
    handleValueData: function (item, n, event, index) {
      var self = this;
      if (self.selectArr[n] != item) {
        self.selectArr[n] = item;
        self.subIndex[n] = index;
      } else {
        self.selectArr[n] = "";
        self.subIndex[n] = -1; //去掉选中的颜色
      }
      self.checkItem();
    },
    checkItem: function () {
      var self = this;
      var option = self.obj.productAttrs;
      var result = []; //定义数组储存被选中的值
      for(var i in option){
        result[i] = self.selectArr[i] ? self.selectArr[i] : '';
      }
      for (var i in option) {
        var last = result[i]; //把选中的值存放到字符串last去
        for (var k in option[i].values) {
          result[i] = option[i].values[k].value; //赋值，存在直接覆盖，不存在往里面添加name值
          option[i].values[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
        }
        result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时被覆盖
      }
      if(this.shopItemInfo[result]){
        this.checlidObj = {}
        this.obj.price = this.shopItemInfo[result].price || ''
        this.obj.stock = this.shopItemInfo[result].stock 
        if(this.obj.lgCoinDiscount) {
          this.shopItemInfo[result].lgCoinDiscount =this.obj.lgCoinDiscount || 100
        }
        this.checlidObj = this.shopItemInfo[result]
        this.skuShow = true
        this.maxNum = this.obj.stock
        this.num = 1
        if(this.shopItemInfo[result].stock == 0) {
          this.bntAddShow = false
          this.obj.stock = '库存不足'
        }else{
          this.bntAddShow = true
        }
      }else{
        this.maxNum = this.obj.totalStock
        this.obj.price = ''
        this.obj.stock = ''
        this.skuShow = false
        this.num = 1
        this.bntAddShow = true
      }
      self.$forceUpdate(); //重绘
    },
    isMay: function (result) {
      for (var i in result) {
        if (result[i] == '') {
          return true; //如果数组里有为空的值，那直接返回true
        }
      }
      return this.shopItemInfo[result].stock == 0 ? false : true ; //匹配选中的数据的库存，若不为空返回true反之返回false
    },
    handleSKUData() {
      for(let j in this.obj.productAttrs) {
        if(this.obj.productAttrs[j].show) {
          let arr = this.obj.productAttrs[j].values
          for(let key in arr) {
            if(arr[key].selectd) {
              this.statusId += arr[key].id

            }
          }

        }
      }
      let arr = this.obj.productSkus
      let productId = ''
      for(let i in  arr ) {
        let skuId = ''
        let list = JSON.parse(arr[i].skuValue)
        for(let j in list) {
          skuId +=list[j].valueId
          if(skuId == this.statusId) {
            this.skuObj = arr[i]
            break
          }
        }
      }
      if(this.skuObj.productImg) {
        this.imgShow = this.skuObj.productImg
      }else{
        this.skuObj.coverImg = this.obj.coverImg
      }
      this.skuObj.name = this.obj.name
      this.skuObj.lgCoinDiscount = this.obj.lgCoinDiscount || 100
      
      this.obj.stock = this.skuObj.stock
      this.obj.price = this.skuObj.price
      if(this.obj.stock == 0 || !this.obj.stock) {
        this.num =  0
        this.maxNum = 0
        this.minNum = 0
      }else{
        this.num =  1
        this.maxNum = Number(this.obj.stock)
        this.minNum = 1
      }
      this.checlidObj = {}
    },
    handlePayOrder() {
      if(this.obj.skuType == 2) {
        this.checlidObj = this.obj.productSkus[0]
        this.checlidObj.coverImg = this.obj.coverImg
        this.checlidObj.name = this.obj.name
        this.checlidObj.buyNum = this.num
        if(this.obj.lgCoinDiscount) {
          this.checlidObj.lgCoinDiscount =this.obj.lgCoinDiscount || 100
        }
      }else{
        if(!this.skuShow) {
          this.$message.error('请选择好规格哦!');
          return
        }
        this.checlidObj.buyNum = this.num
        this.checlidObj.coverImg = this.obj.coverImg
        this.checlidObj.name = this.obj.name
      }
      if(this.num == 0) {
        this.$message.error('库存不足哦!');
        return

      }
      this.checlidObj.payChannel = this.obj.payChannel
      this.$refs.payTip.handleOpenOrderData(this.checlidObj)
    },
    // 鼠标移入
    enter() {
      this.bigIcon = false
    },
    // 鼠标移除  
    leave() {
      this.bigIcon = true
    },
    // 放大镜功能
    handleMagnifyingGlass() {

    },
    // 选中图片
    handleImgsData(item,idx) {
      this.imgIdx = idx
      this.imgShow = item
    },
    // 上一页切换图片
    handlePretData() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetHeight
      if(this.widthShow>0) {
        this.widthShow = this.widthShow - ((length + 8 ) * 5 - 15)
      } else{
        this.widthShow = 0
      }
    },
    // 下一页切换图片
    handleNextData() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetHeight
      if(this.widthShow > 0){
        if(this.widthShow>length*6){
          this.widthShow = this.widthShow + ((length + 8 ) * 5 - 15)
          return
        }
      }else{
        if(this.imgList.length > 5) {
          this.widthShow = (length + 8 ) * 5 - 15
        }
      }
    },
    // 返回
    handleBackData() {
      this.$router.go(-1)
    },
    handleNumChange(data) {
      if(this.obj.stock) {
        if(data==this.obj.stock) {
          this.maxNum = this.obj.stock
          this.num = this.obj.stock
          return
        }
      }
    },
    notesBrowsing() {
      let params = {
        productId: this.id,
        source: 1,
        lgUserId: sessionStorage.lgUserId
      }
      notesBrowsing(params).then(res => {
      })
    },
    handleProductInfo() {
      getProductInfo({id:this.id}).then(res => {
        if (res.code == 200) {
          this.selectArr = []
          this.shopItemInfo = []
          this.subIndex = []
          this.obj = {}
          this.obj = res.data
          if(this.obj.skuType == 2) {
            this.obj.price = this.obj.productSkus[0].price
          } else{
            var self = this;
            for (var i in self.obj.productSkus) {
              let arr = JSON.parse(self.obj.productSkus[i].skuValue) 
              let string = ''
              for(let j in arr) {
                if(arr.length-1 == j) {
                  string += arr[j].value
                }else{
                  string += arr[j].value + ','

                }
                self.obj.productSkus[i].string = string
                
              }
              
              self.shopItemInfo[self.obj.productSkus[i].string] = self.obj.productSkus[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
            }
            self.checkItem();
          }
          this.imgShow = this.obj.coverImg
          this.imgList =  this.obj.mainImg.split(',') 
          this.detailImgList =  this.obj.mainImg.split(',') 
        } 
      }).catch(res=>{
      });

    },
    handleSelectRecommend() {
      selectRecommend().then(res => {
        if (res.code == 200) {
          this.recommendList = res.data
        } 
      }).catch(res=>{
      });

    },
  },
  created() {

  },
  mounted() {
    this.id = this.$route.query.id
    this.handleProductInfo()
    this.handleSelectRecommend()
    this.notesBrowsing()
  },
  beforeDestroy(){ 
  },
  destroyed() {
  }
};
</script>
<style lang="less" scoped>
@import "./details.less";

</style>
<style lang="less">
.mouse-cover-canvas{
  top: 195px !important;
  left: 354px!important;
}
</style>
