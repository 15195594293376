<template>
  <div class="addServe">
    <el-form name="addForm" :model="addForm" v-loading="loading">
      <!-- 选择游戏段位 -->
      <el-form-item label="" >
        <div class="add-game add-item">
          <div class="game-all">
            <div class="game-flex">
              <span>开通游戏：</span>
              <div @click="handleGameListShowHide" class="flex-list-name">
                <div> {{gameName || '游戏'}}</div>
                <i class="el-icon-caret-bottom "></i>
              </div>
              <div v-if="gameName" @click="handleDeployListShowHide" class="flex-list-name flex-list-deploy">
                <div> {{deployName || '段位'}}</div>
                <i class="el-icon-caret-bottom "></i>
              </div>

            </div>
          </div>
          <ul v-if="deployShow">
            <li v-for="(item,idx) in gameDWeiOrder" :class="[idx === dWeiIdx?'game-show':'']" :key="idx" @click="handleDweiData(item,idx)">{{item}}</li>
          </ul>
          <ul v-if="gameShow">
            <li v-for="(item,idx) in gameList" :class="[idx == gameIdx?'game-show':'']"  :key="idx" @click="handleGameData(item,idx)">{{item.name}}</li>
          </ul>

        </div>
      </el-form-item>
      <!-- 示例图 -->
      <el-form-item label="">
        <div class="add-material add-item">
          <div class="add-title">认证资料照：<span>（请上传清晰完整的技能资料）</span></div>
          <div class="add-imgs">
            <div class="flex-tip-list">{{gameMessage.minRequire}}</div>
            <div class="imgs-all">
              <div class="imgs-upload">
                <div class="upload-text" v-if="!exampleImg">资料图片要求如下</div>
                <ul v-if="!exampleImg">
                  <li>1.电脑清晰截图，不能手机拍摄</li>
                  <li>2.游戏昵称与分数清晰</li>
                  <li>3.如需考核应上此号进行考核</li>
                  <!-- <li>4. lol最低入驻男钻二，女黄金</li>
                  <li>5.绝地求生分数2000以上入驻</li> -->
                </ul>
                <img class="upload-example" v-if="exampleImg" :src="exampleImg">
              </div>
              <span>示例</span>
              <div class="imgs-url">
                <img v-if="gameMessage.certDemoImg" :src="gameMessage.certDemoImg"/>
                <img v-else src="~@/assets/images/balance/user/move/img.png"/>
              </div>

            </div>
            <div class="upload-image">
              <el-upload
              class="avatar-uploader"
              ref="upload"
              action="aa"
              list-type="picture"
              :multiple="false"
              :limit=2
              :on-change="handleExampleImmgChange"
              :before-upload="handlePreview"
              :on-remove="handleRemove"
              :show-file-list="false"
              name="danGradingImgFile"
              :auto-upload="false">
              </el-upload>
              上传图片
            </div>
          </div>
        </div>
      </el-form-item>
      <!-- 技能信息 -->
      <el-form-item label="" v-for="(item,index) in listData" :key="'term'+index">
        <div class="add-deploy add-item">
          <div class="deploy-item">
            <span>{{item.name}}：</span>
            <el-select v-model="item.addval"  @change="handleDeployVal" multiple :placeholder="'请选择'+item.name">
              <el-option
                v-for="(items,index) in item.list"
                :key="index"

                :label="items"
                :value="items">
              </el-option>
            </el-select>
          </div>
        </div>
      </el-form-item>
      <!-- 实力认证图 -->
      <el-form-item label="">
        <div class="add-upload add-item">
          <div class="upload-images">
            <p class="images-text">1-6张实力认证图 <span>（必填）</span></p>
            <el-upload
              class="images-picture"
              action="aaa"
              list-type="picture-card"
              :limit='6'
              :on-change="handlePictureImmgChange"
              :auto-upload="false"
              :file-list="fileList"
              :on-exceed='handleonexceed'
              name="strengthImgFiles"
              :on-remove="handleStrengthRemove">
              <i class="el-icon-plus"></i>
              <div class="upload-tip">上传图片</div>
            </el-upload>
          </div>
          <div class="upload-video">
            <!-- <p class="images-text">实力认证视频 <span>（非必选）</span></p>
            <el-upload
            class="images-picture"
              action="aaa"
              list-type="picture-card"
              :limit='1'
              :on-preview="handlePictureCardPreview"
              :auto-upload="false"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
              <div class="upload-tip">上传视频</div>
            </el-upload> -->
          </div>
        </div>
      </el-form-item>
      <!-- 说明 -->
      <el-form-item label="">
        <div class="add-state add-item">
          <div class="add-title">说明</div>
          <div class="state-input">
            <el-input
              type="textarea"
              maxlength = '70'
              :rows="4"
              placeholder="可以写自己喜欢的游戏，喜欢的玩法和打法(0/70)"
              v-model="addForm.explain">
            </el-input>

          </div>
        </div>
      </el-form-item>
      <!-- 上传语音 -->
      <el-form-item label="">
      <div class="add-audio add-item">
        <div class="add-title">语音介绍</div>
        <div class="audio-flex">
          <div class="flex-left">
            <i class="el-icon-microphone"></i>
            <div>点击上传</div>
            
              <el-upload
              class="images-picture"
              ref="upload"
              action="aaaaaa"
              list-type="picture"
              :multiple="false"
              :limit=2
              :on-change="handleAudioChange"
              :show-file-list="false"
              name="voiceIntroduceFile"
              :auto-upload="false">
              </el-upload>
          </div>
          <div class="flex-right"> 上传的录音可供游客试听，请上传正确的MP3文件，内容为简单的自我介绍，
            <div>示例：{{gameMessage.voiceHint || '录制语音介绍您的服务'}}</div>
          </div>
        </div>
        <div class="audio-show" v-if="audioUrl">
          <audio controls="controls" ref="audio" >
            <source :src="audioUrl" type="audio/ogg" />
          </audio>
        </div>
      </div>
      </el-form-item>
      <el-form-item label="">
        <div class="add-bnt">
          <div class="bnt-data"  @click="handleRaddGameService">提交入驻申请</div>
          <p>点击即表明我已阅读并同意《陪玩申请入驻协议》</p>
        </div>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import { getGame,getGameService,addGameService,getGameServiceInfo } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,imageToBase64,base64ToFile }  from '@/common/utils/des'
export default { 
  props:{
    info: Object
  },
  name: "addServe",
  data() {
    return{
      gameName: '', // 保存游戏名称
      deployName: '', // 保存大区名称
      deployShow: false,
      dialogImageUrl: '',
      dialogVisible: false,
      gameList:[],
      gameIdx: 9999,
      imgList: [],
      lgUserId: '',
      gameMessage: {}, // 保存选中的某个游戏
      gameDeploy:{}, // 游戏大区
      gameDWei:{}, // 游戏段位
      gameLocation:{}, // 游戏位置
      dWeiIdx: 9999, // 保存段位高亮
      gameShow: true,
      gameDWeiOrder: [],
      exampleImg: '',
      addForm: {
        applyId: '',
        lgUserId:'',
        gameId: '', // 游戏ID
        danGrading: '', // 段位名称
        danGradingImgFile: null, // 示例图
        explain: '', // 说明
        strengthImgFiles: [], // 实力认证图
        voiceIntroduceFile: null, // 语音文件
        gameParams: [], // 游戏参数
      },
      deployVal: [], // 保存接单大区的值
      dWeiVal: [], // 保存段位的值
      locationVal: [], // 保存位置的值
      audioUrl:  '',
      loading: false,
      listData: [],
      infoId: null,
      gameServiceId: null,
      fileList: [],
    }
  },
  watch: {
    info(newVal,oldVal) {
      if(newVal) {
        this.infoId = newVal.applyId
        this.gameServiceId = newVal.id
      }
    },
  },
  components: {  },
  computed:{
  },
  methods: {
    /**
    * url: 图片路径
    * cb: callback
    */
    handleImgToBase64(url, cb) {
      let that = this;
      var image = new Image();
      image.crossOrigin = '';
      image.src = url;
      image.onload = function () {
        let base64 = imageToBase64(image); //图片转base64

        let file = base64ToFile(base64, 'file'); //base64转File
  
        // 根据自身需求调整【因个人项目逻辑不一样，这里使用回调函数】
        cb && typeof cb == 'function' && cb(file);
        return file;
      }
    },
    // 查询详情
    getGameServiceInfo(item) {

      getGameServiceInfo({applyId: this.infoId}).then(res => {
        let obj = res.data
        if(res.data) {
          let item = {}
          for(let i in this.gameList) {
            if(this.gameList[i].id == obj.gameId) {
              this.gameName = this.gameList[i].name
              this.gameIdx = i
              item =  this.gameList[i]
            }
          }
          this.handleGameData(item,this.gameIdx)
          this.fileList = []
          let imgs = []
          let _this = this
          if(obj.strengthImg) {
            imgs = obj.strengthImg.split(',')
            for(let i in imgs) {
              let obj = {
                name: 'item'+ i,
                url: imgs[i]
              }
              this.fileList.push(obj)
            }
          }
          this.addForm = {
            danGradingImgFile:null,
            gameId: obj.gameId, // 游戏ID
            danGrading: obj.danGrading, // 段位名称
            explain:  obj.explainText, // 说明
            gameParams: obj.gameParams, // 游戏参数
          }
          this.deployName = obj.danGrading
          this.exampleImg =  obj.danGradingImg
          this.audioUrl = obj.voiceIntroduce
          let gameParams = JSON.parse(this.addForm.gameParams)
          for(let i in this.listData) {
            for(let j  in  gameParams) {
              if(gameParams[j].id == this.listData[i].id) {
                gameParams[j].list = gameParams[j].value.split(',')
                this.listData[i].addval = gameParams[j].list
              }
            }
            this.listData[i].list = this.listData[i].value.split(',')
          }

        }
      })
    },
    // 初始化数据
    handleOriginal(){
      this.infoId = null,
      this.addForm = {
        applyId: '',
        lgUserId:'',
        gameId: '', // 游戏ID
        danGrading: '', // 段位名称
        danGradingImgFile: null, // 示例图
        explain: '', // 说明
        strengthImgFiles: [], // 实力认证图
        voiceIntroduceFile: null, // 语音文件
        gameParams: [], // 游戏参数
      }
      this.deployVal = []
      this.dWeiVal = []
      this.locationVal = []
      this.gameName = ''
      this.deployName = ''
      this.deployShow = ''
      this.exampleImg = ''
      this.audioUrl = ''
      this.loading = false
      this.gameMessage = {} 
      this.gameDeploy = {}
      this.gameDWei={}
      this.gameLocation={}
      this.gameDWeiOrder = []
      this.gameShow = true
      this.gameIdx = 9999
      this.dWeiIdx = 9999
      this.listData = []
      this.fileList = []
    },
    // 添加
    handleRaddGameService() {
      if(!this.gameName) {
        this.$message.error('请选择开通的游戏!');
        return
      }
      if(!this.deployName) {
        this.$message.error('请选择游戏的段位!');
        return
      }
      if(!this.exampleImg) {
        this.$message.error('请上传示例图!');
        return
      }
      if(!this.addForm.explain) {
        this.$message.error('请输入自己的说明!');
        return
      }
      if(this.addForm.strengthImgFiles.length == 0) {
        this.$message.error('请上传至少一张实力认证图!');
        return

      }
    
      this.addForm.gameParams = []
      const formData = new FormData();
      for(let i in this.listData) {
        if(this.listData[i].addval.length>0) {
          let obj = {
            id: this.listData[i].id,
            name: this.listData[i].name,
            value: '',
          }
          for(let j in this.listData[i].addval) {
            if(this.listData[i].addval.length-1 == j) {
              if(this.listData[i].addval[j]) {
                obj.value += this.listData[i].addval[j]
              }
            }else {
              obj.value += this.listData[i].addval[j] + ','
            }
          }
          if(obj.value) {
            this.addForm.gameParams.push(obj)
          }
        }
      }
      let applyId =  ''
      if(this.info) {
        applyId = this.info.applyId
        formData.append('applyId', applyId);
      }
      this.loading = true
      if (null != this.addForm.danGradingImgFile) {
        formData.append('danGradingImgFile', this.addForm.danGradingImgFile);
      }
      if(this.gameServiceId) {
        formData.append('gameServiceId', this.gameServiceId);
      }
      formData.append('gameId', this.addForm.gameId);
      formData.append('gameParams', JSON.stringify(this.addForm.gameParams));
      formData.append('danGrading', this.deployName);
      formData.append('lgUserId', this.lgUserId);
      formData.append('explain', this.addForm.explain);
      let arr = []
      if (null != this.addForm.strengthImgFiles) {
        let upFile = this.addForm.strengthImgFiles.shift()
        formData.append('strengthImgFiles', upFile);
      }

      if (null != this.addForm.voiceIntroduceFile) {
        formData.append('voiceIntroduceFile', this.addForm.voiceIntroduceFile);
      }  
      // return
      addGameService(formData).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleOriginal()
          this.$parent.handleFatherMethod();

        }else {
          this.$message.error(res.msg || '操作失败!');
        }
        
        this.loading = false
      }).catch(res=>{
        this.loading = false
        this.$message.error( '操作失败!');

      })

    },
    // 技能信息事件
    handleDeployVal(data,res) {
      this.$forceUpdate()

    },
    handleStrengthRemove(file, fileList) {
        if(fileList.length == 0) {
          this.addForm.strengthImgFiles = []
        } 
    },
    handlePreview(file) {
    },
    // 上传音频
    handleAudioChange(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传音频大小不能超过 2MB!');
        return
      }
      this.addForm.voiceIntroduceFile = file.raw
      if(this.audioUrl) {
        this.$refs.audio.src = fileList[0].url;
      }else {
        this.audioUrl = fileList[0].url;
      } 
    }, 
    handleonexceed(e){
      this.$message.warning('最多上传六张图片');
    },
    // 上传实力图
    handlePictureImmgChange(file,fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片不能超过 2MB!');
        return
      }
      if(file.response){
        if(file.response.code == 200) {
          this.$message({
              message: file.response.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

        }else{
          this.addForm.strengthImgFiles = []
          this.$message.error(file.response.msg || '操作失败!');
          return

        }
      }
       this.addForm.strengthImgFiles = []
      for (let x of fileList) {
        if (x.raw) {
          this.addForm.strengthImgFiles.push(x.raw)
        }
      }
      
    },
    // 上传示例图
    handleExampleImmgChange(file,fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传图片不能超过 2MB!');
        return
      }
      if (fileList.length > 1) {
          fileList.splice(0, 1);
      }
      this.addForm.danGradingImgFile = file.raw 
      if(file.response){
        this.headShow = false
        if(file.response.code == 200) {
          this.$message({
              message: file.response.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

        }else{
          this.$message.error(file.response.msg || '操作失败!');

        }
      }
      this.exampleImg = URL.createObjectURL(fileList[0].raw);
    },
    handleDweiData(item,idx) {
      this.dWeiIdx = idx
      this.deployName = item
      this.gameShow = false
    },
    // 显示游戏列表
    handleGameListShowHide(){
      this.deployShow = false
      this.gameShow = true

    },
    // 显示段位列表
    handleDeployListShowHide() {
      this.deployShow = true
      this.gameShow = false
      this.dWeiIdx = 9999

    },
    handleRemove(file, fileList) {
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleGameData(item,idx) {
      this.handleOriginal()
      this.gameIdx = idx
      this.addForm.gameId = item.id
      this.gameName = item.name
      this.gameMessage = item
      this.gameDWeiOrder =item.secondValue.split(',')
      let gameDeploy = JSON.parse(item.gameDeploy)
      let obj = {}
      for(let i in gameDeploy) {
        gameDeploy[i].list = gameDeploy[i].value.split(',')
      }
      this.listData = gameDeploy
    },
    // getGame
    handleGetGame() {
      let params = {
        pageNum: 1,
        pageSize: 999
      }
      getGame(params).then(res => {
        if (res.code == 200) {
          this.gameList = res.rows
        }
      })
    },
  },
  created() {
    this.lgUserId = sessionStorage.lgUserId
  },
  mounted() {
  },
  destroyed() {

  }
};
</script>
<style lang="less">
@import "./addServe.less";
</style>
