<template>
  <div class="photo">
    <div class="add-img" >   
      <el-upload
        action="/zd-api/web/client/user/uploadPhotos"
        multiple
        class="imgs-card"
        :data="{lgUserId:lgUserId,type: 2}"
        ref="upload"
        :headers="headers"
        :on-exceed='handleonexceed'
        name="files"
        :before-upload="handleUpload"
        :on-change="handleBgImmgChange"
        :auto-upload="autoUpload"
        :before-remove="beforeRemove"
      >
      </el-upload>
      上传图片

    </div>
    <p >温馨提示：最多可上传5长照片，图片大小不超过2M，审核通过后展示出来，如需修改请删除后再次上传（请尽量上传1:1尺寸图）</p>
    <div class="photo-images">
      <div class="photo-all">
        <div class="images-item" v-for="(item,idx) in imgList" :key="idx">
          <img class="item-close" @click="handleCloseImgData(item,idx)" src="~@/assets/images/balance/close.png">
          <div class="item-bnt" v-if="item.status == 3" @click="handleSetImgData(item,idx)">设为封面</div>
          <div class="item-show-dig"> </div>
          <img class="item-show-img" :src="item.imgUrl"/>
          <img class="item-show-status" v-if="item.status == 2"  src="~@/assets/images/balance/user/img1.png"/>
          <img class="item-show-status" v-if="item.status == 1"  src="~@/assets/images/balance/user/img2.png"/>
          <img class="item-show-status" v-if="item.status == 3"  src="~@/assets/images/balance/user/img3.png"/>
          <img class="item-show-status" v-if="item.status == 4"  src="~@/assets/images/balance/user/img4.png"/>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import { getUserPhotos,deletePhotos,setBackdrop } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: "photo",
  data() {
    return{
      autoUpload: true,
      limit: 5,
      imgList: [
        {
          id: 1,
          urlSrc: '',
        },
        {
          id: 2,
          urlSrc: '',
        },
        {
          id: 3,
          urlSrc: '',
        },
        {
          id: 4,
          urlSrc: '',
        },
        {
          id: 5,
          urlSrc: '',
        },
      ],
      lgUserId: '',
      fileList: [],
      headers: {
        secret: '',
      }
    }
  },
  components: {  },
  computed:{
  },
  methods: {
    handleUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png'; 
      const isGif = file.type === 'image/gif';
      if (!isPNG && !isGif && !isJPG) {
        this.$message.error('上传头像图片只能是JPG、png、gif格式!');
      }
      return isLt2M && (isJPG || isPNG || isGif);
    },
    handleCloseImgData(item,idx) {
      deletePhotos({id:item.id}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

          this.handlegetUserPhotos()
        }else {
          this.$message.error(res.msg || '操作失败!');
        }
      });
    },
    // 设为背景
    handleSetImgData(item,idx) {
      setBackdrop({id:item.id}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg || '操作成功!',
              type: 'success',
              customClass: 2000
          });

          this.handlegetUserPhotos()
        }else {
          this.$message.error(res.msg || '操作失败!');
        }

      })
    },
    handleonexceed(e){
    },
    beforeRemove(file, fileList) {
    },
    handleBgImmgChange(file,fileList) {
      if(file.response) {
        if(file.response.code == 200) {
          this.handlegetUserPhotos()

        }

      }

    },
    handlegetUserPhotos() {
      getUserPhotos({lgUserId:this.lgUserId}).then(res => {
        if (res.code == 200) {
          this.imgList = res.data
          if(this.imgList.length>4) {
            this.autoUpload = false
            return
          } else {
            
            this.autoUpload = true
          }
        }
      });
    },
  },
  created() {
    this.headers.secret = sessionStorage.secret
    this.lgUserId = sessionStorage.lgUserId
    this.handlegetUserPhotos()
  },
  mounted() {
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
.photo{
  margin-top: 25px;
  font-size: 12px;
  .add-img{
    position: relative;
    width: 90px;
    height: 35px;
    border-radius: 6px;
    line-height: 35px;
    font-size: 14px;
    text-align: center;
    background: #5ED6FC;
    box-shadow: 0px 6px 25px 0px rgba(94, 214, 252, 0.3);
    color: #ffffff;
    .imgs-card{
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
      /deep/.el-upload{
        width: 100%;
        height: 100%;
      }
    }
  }
  >p{
    margin-top: 30px;
    color: #666666;
  }
  .photo-images{
    height: 420px;
    padding-top: 35px;
    padding-left: 3px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .photo-all{
      display: flex;
      flex-wrap: wrap;
      max-width: 550px;
    }
    .images-item{
      position: relative;
      width: 169px;
      height: 169px;
      // background: #DCE5EE;
      border-radius: 6px;
      margin-right: 13px;
      margin-bottom: 13px;
      .item-show-img{
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      .item-show-status{
        position: absolute;
        width: 49px;
        height: 48px;
        left: -3px;
        top: -3px;
        border-radius: 6px;
        z-index: 1;
      }
      .item-show-dig{
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        background: rgba(0,0,0,0.1);
        top: 0px;
        right: 0px;
        z-index: 2;
      }
      .item-close{
        display: none;
        position: absolute;
        top: 5px;
        right: 6px;
        z-index: 3;
        cursor: pointer;
      }
      .item-bnt{
        display: none;
        position: absolute;
        width: 81px;
        height: 31px;
        line-height: 31px;
        text-align: center;
        background: #ffffff;
        border-radius: 6px;
        color: #8D9399;
        top: 6px;
        right: 40px;
        z-index: 3;
        cursor: pointer;

      }
      &:hover{
        .item-bnt,.item-close,.item-show-dig{
          display: block;
        }

      }
    }
  }
  .photo-images::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

}

</style>
