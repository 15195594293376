<template>
  <div class="dynamic">
    <div class="tip-title">发布动态话题</div>
    <div class="tip-form">
      <div class="form-textarea">
        <textarea maxlength="2000" v-model="addForm.content" placeholder="分享新鲜事..."></textarea>
      </div>
      <div class="form-tag" @click="handleTagData"  v-if="!addForm.topicId">#话题标签#</div>
      
      <div class="form-tag-show" v-else>
        #{{addForm.topicName}}# 
        <i class="el-icon-close form-tag-close" @click="handleCloseTag"></i></div>
    </div>
    <div class="tip-images">
      <div class="images-title">
        <img src="~@/assets/images/balance/square/imgIco.png" />
        <div>
          图片
          <span>（{{images.length}}/9）</span>
        </div>
      </div>
      <div class="images-list">
        <div class="list-all " v-if="images.length<4">
          <div class="all-img list-three" v-for="(item,idx) in images" :key="idx
          ">
            <div class="img-item">
              <img class="img-list-show" :src="item.imgUrl"/>
            </div>
            <div class="img-list-bg">

              <img class="img-list-close" @click="handleDelete(item,idx)" src="~@/assets/images/balance/user/close.png" />
            </div>
          </div>
        </div>
        <div class="list-all list-all_nine" v-else>
          <div class="all-img list-nine" v-for="(item,idx) in images" :key="idx
          ">
            <div class="img-item">
              <img class="img-list-show" :src="item.imgUrl"/>
            </div>
            <div class="img-list-bg">
              <img class="img-list-close" @click="handleDelete(item,idx)" src="~@/assets/images/balance/user/close.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bnt-add">
      <div class="add-left">
        <el-upload
          action="/zd-api/web/client/dynamic/dynamicUpload"
          multiple
          class="imgs-card"
          :data="{sort: sortIdx}"
          ref="upload"
          :headers="headers"
          name="file"
          :before-upload="handleUpload"
          :on-change="handleBgImmgChange"
          :on-success="handleSuccessData"
        ></el-upload>上传图片
      </div>
      <div class="add-right" @click="handleAddData">发表动态</div>
    </div>
    <!-- 标签弹窗 -->
    <div class="tag-tip-bg" v-show="tagShow"></div>
    <div class="head-tip tag-tip" v-show="tagShow">
      <div class="tip-close" @click="handleTagCloseData" style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <div class="tip-title tag-title">选择话题标签</div>
      <div class="tip-flex">
        <div class="flex-left">
          <div class="left-item">
            <div
              class="item-bnt"
              :class="{'item-bnt-show':idx==tagTabIdx}"
              v-for="(item,idx) in tagTablist"
              :key="idx"
              @click="handleTagTabData(item,idx)"
            >
            {{item.title}}
            </div>
          </div>
        </div>
        <div class="flex-right">
          <div class="right-list">
            <div class="list-no" @click="handleTopicData(item,99999)">
              <img src="~@/assets/images/balance/square/no.png" />
              <div>不选择话题</div>
            </div>
            <div class="list-item" @click="handleTopicData(item,idx)" v-for="(item,idx) in topicLists" :key="idx">
              <div class="item-title">
                <div>{{item.title}}</div>
                <!-- <img src="~@/assets/images/balance/square/hot.png" /> -->
              </div>
              <!-- <div class="item-text">{{item.title}}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  topicFirstList,
  topicList,
  deleteDynamicImg,
  publish
} from "@/api/balance/square";
export default {
  name: "dynamic",
  data() {
    return {
      images:[],
      headers: {
        secret: ""
      },
      sortIdx: 1,
      tagShow: false,
      tagTabIdx: 0,
      tagTablist: [],
      topicLists: [],
      addForm:{
        lgUserId: '',
        content: '',
        topicId:'',
        topicName: '',
        imgList: []
      }
    };
  },
  components: {},
  computed: {},
  methods: {
    // 初始化
    handleMachineData() {
      this.addForm = {
        lgUserId: '',
        content: '',
        topicId: null,
        topicName: null,
        imgList: []
      }
      this.images=[]
      this.sortIdx = 1

    },
    // 提交动态
    handleAddData() {
      if(!this.addForm.content || !this.addForm.content.replace(/(\r\n|\n|\r)/gm,'')) {
        this.$message.error('请输入您要发布的内容!');
        return
      }
      this.addForm.lgUserId = sessionStorage.lgUserId
      this.addForm.imgList = this.images
      publish(this.addForm).then(res =>{
        if(res.code == 200) {
          this.handleMachineData()
          this.$parent.handleParamsData();
          this.$parent.handleCloseImg();
        }
      })
    },
    // 删除全部图片
    handleAllDeleteImg(){
      if(!this.images.length>0) {
        return
      }
      deleteDynamicImg(this.images).then(res =>{
        if(res.code == 200) {
          this.images = []
        }
      })

    },
    // 删除单张图片
    handleDelete(item,idx) {
      let arr = []
      arr.push(item)
      this.handleDeleteDynamicImg(arr,idx)
    },
    handleSuccessData(response, file, fileList) {
      let  _this = this
      if(response.code == 200) {
        let arr = []
        if(_this.images.length>8) {
          _this.images.splice(_this.images.length,1)
          arr.push(response.data)
          deleteDynamicImg(arr).then(res =>{
            if(res.code == 200) {

            }
          })
          
        this.$message.error('最多上传9张!');
        }else{
          _this.images.push(response.data)
          _this.sortIdx = this.images.length + 1;
        }
      }
    },
    // 删除图片接口
    handleDeleteDynamicImg(arr,idx) {
      deleteDynamicImg(arr).then(res =>{
        if(res.code == 200) {
          this.images.splice(idx,1)
        }
      })
    },
    // 删除话题标签
    handleCloseTag() {
      this.addForm.topicId = null
      this.addForm.title = null
    },
    // 选择话题
    handleTopicData(item,idx) {
      if(idx == 99999) {
        this.addForm.topicId = null
        this.addForm.topicName = null

      } else {
        this.addForm.topicId = item.id
        this.addForm.topicName = item.title

      }
      this.tagShow = false
      
    },
    handleBgImmgChange(file, fileList) {
    },
    handleUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png'; 
      const isGif = file.type === 'image/gif';
      if (!isPNG && !isGif && !isJPG) {
        this.$message.error('上传图片只能是 JPG、png、gif 格式!');
      }
      return isLt2M && (isJPG || isPNG || isGif);
    },
    //关闭标签弹窗
    handleTagCloseData() {
      this.tagShow = false;
    },
    // 标签弹窗
    handleTagData() {
      this.tagShow = true;
      this.handleTopicFirstList();
    },
    // 一级话题列表
    handleTopicFirstList() {
      topicFirstList().then(res => {
        if (res.code == 200) {
          this.tagTablist = res.data;
          if (this.tagTablist.length > 0) {
            this.handletopicList(this.tagTablist[0].id);
          }
        }
      });
    },
    // 二级话题列表
    handletopicList(parentId) {
      topicList({ parentId: parentId }).then(res => {
        if (res.code == 200) {
          this.topicLists = res.rows;
        }
      });
    },
    // 点击标签tab
    handleTagTabData(item,idx) {
      this.tagTabIdx = idx
      this.handletopicList( item.id)
    },
  },
  created() {
    this.headers.secret = sessionStorage.secret
  },
  mounted() {},
  destroyed() {}
};
</script>
<style lang="less">
.dynamic {
  .tip-title {
    &.tag-title {
      margin-left: 37px;
    }
    &:extend(.tipTitle);
  }
  .bnt-add {
    display: flex;
    margin-top: 40px;
    > div {
      width: 88px;
      height: 38px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      cursor: pointer;
      margin-right: 23px;
    }
  }
  .tip-form {
    color: #333333;
    font-size: 12px;
    margin-top: 25px;
    .form-textarea {
      height: 87px;
      border: 0.5px solid #b3b3b3;
      border-radius: 5px;
      background: #fff;
      padding: 5px 10px;
      textarea {
        width: 100%;
        height: 100%;
        background: none;
        outline: none;
        border: none;
        color: #999999;
      }
    }
    .form-tag {
      margin-top: 12px;
      color: #5564f3;
      cursor: pointer;
    }
    .form-tag-show{
      margin-top: 12px;
      color: #5564f3;
      .form-tag-close{
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }

    }
  }
  .tip-images {
    margin-top: 35px;
    .images-title {
      display: flex;
      align-items: center;
      color: #666666;
      font-size: 14px;
      img {
        width: 14px;
        height: 14px;
      }
      > div {
        margin-left: 8px;
      }
    }
    .images-list {
      .list-all {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        &_nine {
          max-width: 300px;
        }

        .all-img {
          position: relative;
          border-radius: 6px;
          background: #dce5ee;
          // cursor: pointer;
          &:hover{
            .img-list-bg{
              display: block;
            }
          }
          .img-list-bg{
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(0,0,0,0.3);
            z-index: 3;
          }
          .img-item{
            width: 100%;
            height: 100%;

          }
          .img-list-show{
            width: 100%;
            height: 100%;
            border-radius: 6px;
          }
          &.list-three {
            width: 189px;
            height: 189px;
            margin-right: 13px;
            &:last-child{
              margin-right: 0;

            }
          }
          &.list-nine {
            width: 86px;
            height: 86px;
            margin-right: 8px;
            margin-bottom: 8px;
          }
          .img-list-close {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 18px;
            height: 18px;
            z-index: 33;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
