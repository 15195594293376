<template>
  <div class="check">
    <div class="check-all">
      <p>确定要结账下机?</p>
      <div class="check-cantent"></div>
      <div class="check-flex">
        <el-button class="flex-left" @click="checkHideData">取消</el-button>
        <el-button type="primary" class="flex-right" @click="checkDeplane">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { memberDeplane } from "@/api/member";

import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";

export default {
  name: "Home",
  data() {
    return {
      type: "",
      info: {}
    };
  },
  methods: {
    checkHideData() {
      var ipc = window.Qbian.ipc;
      ipc.send("window-check-min");
    },
    checkDeplane() {
      var info = this.info,
        _this = this;
      if (window.Qbian) {
        var ipc = window.Qbian.ipc;
        ipc.send("member-deplane");
        ipc.once("member-deplane", (event, secret) => {
          memberDeplane({
            secret: secret
          }).then(res => {
            if (res.code == 200) {
              _this.$message({
                message: "结账成功~",
                type: "success",
                customClass: "home-modal"
              });
              ipc.send("window-all-closed");
            } else {
              _this.$message({
                message: res.msg,
                type: "error",
                customClass: "home-modal"
              });
            }
          });
        });
      }
    }
  },
  created() {
    // console.log(encryptByDESModeCBCUtf8to64(JSON.stringify({
    //     shopId: 7468
    // })))
    this.type = this.$route.query.type || "";
  },
  mounted() {},
  destroyed() {}
};
</script>
<style lang="less">
html {
}
// #app{

// }
</style>
<style lang="less" scoped>
.check {
  text-align: center;
  width: 350px;
  height: 350px;
  .check-all {
    padding: 20px;
  }
  p {
    font-size: 18px;
  }
  .check-flex {
    display: flex;
    justify-content: center;
    /* margin-top: 60px; */
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translate(-50%, 0);
    .flex-left {
    }
    .flex-right {
      margin-left: 20px;
    }
  }
}
</style>
