<template>
  <div class="play-tip">
    <!-- 订单弹窗 -->
    <div class="order-tip-bg" v-show="tipShow"></div>
    <div class="order-tip" v-show="orderShow">
      <div class="tip-close" @click="handleTipCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-all">
        <div class="all-title">确认订单</div>
        <div class="all-flex">
          <div class="flex-left">
            <img v-if="skuObj.productImg" :src="skuObj.productImg"/>
            <img v-else :src="skuObj.coverImg"/>
          </div>
          <div class="flex-right">
            <div class="right-name">
              {{skuObj.name || skuObj.productName}}
            </div>
            <div class="right-color"><span>类型：</span>{{skuType}} </div>
            <div class="right-status">
              <div class="right-number"><span>数量：</span> x{{skuObj.buyNum}}</div>
              <div class="right-money">价格： <span>￥{{skuObj.price}}</span></div>
            </div>
          </div>
        </div>
        <div class="all-input">
          <div class="input-type" @click="handleAddressData">
            提货方式
            <span>至炸蛋电竞馆门店自提</span>
          </div>
          <div class="input-remark">
            点单备注
            <input v-model="remark" placeholder="选填（限20字内）"/>
          </div>
        </div>
        <div class="all-total">
         <span class="total-num"> 共计{{skuObj.buyNum}}件</span> 合计 <span class="total-money">￥{{totalMoney}}</span>
        </div>
        <div class="all-bnts">
          <div class="bnts-flex"  :class="[item.id == 1?'weChat':item.id == 2?'alipay':'lgb']" v-for="(item,idx) in skuObj.payChannelShow"  @click="handleQRcodeData(item.id)">
            <div v-if="item.id != 3">{{item.name}}</div>
            <div class="" v-else>
              <div >霖感币支付</div>
              <div class="lgb-text" v-if="skuObj.lgCoinDiscount">霖感币支付{{skuObj.lgCoinDiscount}}折</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <div class="status-tip" v-show="playShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <!-- 霖感币支付 -->
      <div class="tip-lgb" v-show="playIdx == 3">
        <div class="lgb-title">请输入支付密码</div>
        <div class="lgb-money">￥{{lgPrice}}</div>
        <div class="lgb-input" >
          <div class="input-item"  v-for="(item,idx) in inputData.value_num" :key="idx" @click.stop="setFocus">
            <div v-if="(inputData.value_length==item-1)&&inputData.focus_class" class="psd-blink"></div>
            <div v-else :class="{'see-no':inputData.see == false && inputData.val_arr[idx]}"> {{inputData.see?inputData.val_arr[idx]:""}}</div>
          </div>
        </div>
          <!-- 输入框（隐藏） -->
        <el-input ref="pswVal" v-model="inputData.input_value"   @focus="getFocus(inputData.input_value)" maxlength="6"  class='input_container' placeholder="" @input="getValue"  @blur="blur"></el-input>
        <div class="lgb-add-input"  v-loading="orderLoading" @click="handlLgbPayData">确认支付</div>
      </div>
      <!-- 二维码支付 -->
      <div class="tip-qr-code" v-show="playIdx != 3">
        <div class="qr-img">
          <img :src="imgCode"/>
        </div>
        <div class="qr-text">
          <div>{{playIdx==1?'微信':'支付宝'}}扫一扫支付</div>
          <div>请在5分钟内完成支付</div>
        </div>
      </div> 
    </div>
    <!-- 提示 -->
    <div class="close-tip-bg" v-show="closeShow"></div>
    <div class="close-tip" v-show="closeShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div> 
      <div class="close-text">
        <div class="text-flex">
          <i class="el-icon-warning-outline flex-icon" ></i>
          <div>如果您正在支付中，那么请不要关闭</div>
        </div>
        <div class="text-tip">您确定取消支付吗？</div>
      </div>
      <div class="close-flex">
        <div class="close-yes" @click="handleCloseYes">确定</div>
        <div class="close-no" @click="handleCloseNo">取消</div>
      </div>
    </div>
    <AddressTip ref="address"></AddressTip>
  </div>
</template>

<script>
import AddressTip from "./addressTip";
import {
  createOrder,orderPay,polling
} from "@/api/balance/shoping";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,imageToBase64,base64ToFile }  from '@/common/utils/des'
import { getWalletInfo } from "@/api/userInfo";
export default {
  name: "playTip",
  data() {
    return{
      lgbObj:{
        lgMoney: 0,
        incomeMoney: 0,
      },
      imgCode: '',
      orderLoading: false,
      tipShow: false,
      orderShow: false,
      playShow: false,
      palyLabel: '支付金额：',
      playTitle: '霖感币支付',
      playIdx: 0,
      dataObj: {
        // strengthImgShow: [],
      },
      msg: '',
      closeShow: false,
      // 输入框参数设置     
      inputData: {
        input_value: "", //输入框的初始内容
        value_length: 6, //输入框密码位数
        isNext: false, //是否有下一步的按钮
        get_focus: false, //输入框的聚焦状态 
        focus_class: true, //输入框聚焦样式
        value_num: [1, 2, 3, 4, 5, 6], //输入框格子数
        height: "30rpx", //输入框高度
        width: "120px", //输入框宽度
        see: false, //是否明文展示
        interval: false, //是否显示间隔格子 
        val_arr: '',
      },
      totalMoney: 0,
      remark: '',
      skuType: '',
      orderNumber: '',
      coinNUm: 20,
      timer: null,
      statefocus: false,
      lgPrice: 0,
      skuObj: {}
    }
  },
  watch: {
    msg(curval) {
      if(/[^\d]/g.test(curval)) {
       this.msg = this.msg.replace(/[^\d]/g, '');
      }
    },
  },
  components: {AddressTip},
  computed:{
  },
  methods: {  
    // 打开地址弹窗
    handleAddressData() {
      this.$refs.address.handleAddressOpen();
    },
    // 获取余额
    handleWalletInfo() {
      getWalletInfo({lgUserId:sessionStorage.lgUserId}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.lgbObj = res.data; 
          }
        }
      });
    },
    handleCloseNo() {
      this.tipShow = true
      this.closeShow = false
      this.playShow = true
      this.orderShow = false
      
    },
    handleCloseYes() {
      this.orderShow = false
      this.tipShow = false
      this.closeShow = false
      this.playShow = false
      this.handleClearTimeout()
      this.$parent.handleListData()
    },
    // 打开
    handleOpenOrderData(item){
      this.skuObj = item 
      this.skuType = ''
      if(this.skuObj.payChannel) {
        this.skuObj.payChannelShow = JSON.parse(this.skuObj.payChannel)
      }
      if(this.skuObj.skuValue) {
        this.skuObj.skuArr = JSON.parse(this.skuObj.skuValue)
        for(let i in this.skuObj.skuArr) {
          if(i == this.skuObj.skuArr.length-1) {
            this.skuType += this.skuObj.skuArr[i].value
          }else {
            this.skuType += this.skuObj.skuArr[i].value + '-'

          }
        }

      }
      if(this.skuObj.lgCoinDiscount > 10) {
        this.skuObj.lgCoinDiscount = this.skuObj.lgCoinDiscount*0.1
      }
      this.totalMoney = ((this.skuObj.price * 1) * this.skuObj.buyNum).toFixed(2)
      this.orderShow = true
      this.tipShow = true
    },
    // 关闭
    handleTipCloseData() {
      this.orderShow = false
      this.tipShow = false
    },
    // 1霖感币 2微信 3支付宝
    handleQRcodeData(num) {
      console.log(num)
      this.playIdx = num
      this.password = ''
       if(num==3) {
        this.playTitle = '霖感币支付'
        this.palyLabel = '支付金额：'

      } else {
        this.playTitle = '扫码支付'
        this.palyLabel = '应付金额：'

      }
      this.handlCreateOrder()
    },
    // 创建订单
    handlCreateOrder() {
      
      let info = JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
      let params = {
        lgUserId: sessionStorage.lgUserId,
        productId: this.skuObj.productId, // 产品Id
        buyNum: this.skuObj.buyNum, // 购买数量
        source: 1, // 1：门店客户端
        payType: this.playIdx, // 支付方式
        skuId: this.skuObj.id, // 选择的skuid
        shopId: info.shopId, // ,门店id
        shopName: sessionStorage.shopName, // 门店名称
        pickUpType: 1, // 1门店自提
        remark: this.remark, // 备注 
      }
      let _this = this
      // return
      createOrder({
        data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
      }).then(res => {
        if (res.code == 200) {
          _this.orderNumber = res.data.orderNumber
          if(_this.playIdx == 3) {
            _this.playShow = true
            _this.orderShow = false
            _this.lgPrice = res.data.money
            _this.$nextTick(()=>{
              _this.setFocus();

            })

          } else {
            _this.handleOrderPay()

          }
        } else {
          _this.$message.error(res.msg);
          return
          
        }
      }).catch(res=>{
      });

    },
    handleOrderListPay(item) {
      this.handleWalletInfo()
      this.orderNumber = item.orderNumber
      this.playIdx = item.payType
      this.tipShow = true
      this.playShow = true
      this.lgPrice = item.actualMoney
      if(this.playIdx != 3) {
        this.handleOrderPay()
      }else{
        this.$nextTick(()=>{
          this.setFocus();
        })
      }
    },
    // 支付接口
    handleOrderPay() {
      let params = {
        orderNumber:  this.orderNumber,
        passwd: this.inputData.input_value, // 产品Id
      }
      orderPay({
        data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))
      }).then(res => {
        if (res.code == 200) {
          if(this.playIdx == 3) {
            this.handlePlayCloseData()
            this.$message({
                message: res.msg || '支付成功!',
                type: 'success',
                customClass: 2000
            });
            this.$parent.handleListData()
          } else {
            this.tipShow = true
            this.playShow = true
            this.orderShow = false
            this.imgCode =  res.data.imgUrl
            this.handlePolling()
          }
        } else {
          this.$message.error(res.msg);
          return
          
        }
      }).catch(res=>{
      });

    },
    handlLgbPayData() {
      if(!this.inputData.input_value) {
        this.$message.error('密码不能为空!');
        return
      }
      this.handleOrderPay()
    },
    handleClearTimeout(){
      if(this.timer) {
        clearTimeout(this.timer);　　// 清除定时器
        this.timer = null;
        this.$once('hook:beforeDestroy', () => {   // 清除定时器   
          clearTimeout(this.timer);   
          this.timer = null;                                       
        })
      }　
    },
    // 接口轮询
    handlePolling() {
      let _this = this
      _this.coinNUm--
      polling({
        orderNumber: _this.orderNumber
      }).then(res => {
        if (res.code == 200) {
          _this.timer = setTimeout(()=>{
            if(_this.coinNUm<1) {
              if(res.data == 2){ 
                _this.$message({
                    message: res.msg || '支付成功!',
                    type: 'success',
                    customClass: 2000
                });
                _this.$parent.handleListData()
              }else {
                _this.$message.error('支付超时!');
              }
              _this.coinNUm = 20 
              _this.handleCloseYes()
              _this.handleClearTimeout()
              _this.$parent.handleListData()
              return true
            }else {
              if(res.data == 2) {
                _this.$message({
                    message: res.msg || '支付成功',
                    type: 'success',
                    customClass: 2000
                });
                _this.coinNUm = 20 
                _this.handleClearTimeout()
                _this.$parent.handleListData()
                return true

              }

            }
           _this.handlePolling()
          },3000)
        } else {
          this.$message.error(res.msg || '操作失败');
          return
          
        }
      }).catch(res=>{
      });

    },
    // 关闭支付弹窗
    handlePlayCloseData() {
      if(this.playIdx != 3) {
        this.closeShow = true
      }else {
        this.playShow = false
        this.tipShow = false
        this.$parent.handleListData()

      }
      this.inputData.input_value = ''
      this.playIdx == 1

    },

    // 失去焦点时
    blur() {
      let that = this;
      that.inputData.focus_class = true
      that.inputData.getFocus = false
    },

    // 点击聚焦
    setFocus() {
      let that = this;
      that.inputData.getFocus = true
      that.$refs['pswVal'].focus()
    },

    // 获取输入框的值
    getFocus(data) {
      let that = this;
      // 设置空数组用于明文展现
      let val_arr = [];
      // 获取当前输入框的值
      let now_val = data
      // 遍历把每个数字加入数组
      for (let i = 0; i < 6; i++) {
        val_arr.push(now_val.substr(i, 1))
      }
      // 获取输入框值的长度
      let value_length = data.length;
      that.inputData.value_length = value_length
      that.inputData.val_arr = val_arr
      that.inputData.input_value = now_val
    },
    // 获取输入框的值
    getValue(data) {
      let that = this;
      // 设置空数组用于明文展现
      let val_arr = [];
      // 获取当前输入框的值
      let now_val = data
      // 遍历把每个数字加入数组
      for (let i = 0; i < 6; i++) {

        val_arr.push(now_val.substr(i, 1))
      }
      // 获取输入框值的长度
      let value_length = data.length;
      that.inputData.value_length = value_length
      that.inputData.val_arr = val_arr
      that.inputData.input_value = now_val
    },
  },
  created() {},
  mounted() {
  },
  beforeDestroy(){ 
    this.handleClearTimeout()
  },
  destroyed() {
    this.handleClearTimeout()
  }
};
</script>
<style lang="less" scoped>    
@import "./payTip.less";
</style>
 