<template>
  <div class="playWith">
    <div class="playWith-tab">
      <div :class="{'tabShow':idx==tabIdx}" @click="handleTabData(item,idx)" v-for="(item,idx) in tabList" :key="idx">{{item.name}}</div>
    </div>
    <div class="playWith-bnts">
      <div :class="{'btns-show':idx == bntIdx}" v-for="(item,idx) in bnts" :key="idx" class="bnts-item" @click="handleBntsData(item,idx)">

        <img :src="item.iconShow" v-if="idx == bntIdx"/>
        <img :src="item.icon" v-else/>
        <div>{{item.name}}</div>
        
        </div>
    </div>
    <div class="playWith-table" >
      <div class="table-th">
        <div>订单信息</div>
        <div>单价及数量</div>
        <div>金额</div>
        <div>订单状态</div>
        <div>操作</div>
      </div>
      <div class="table-list" v-if="listData.length > 0">
        <div class="list-data" v-for="(item,idx) in listData" :key="idx">
            
          <div class="data-title">
            <img v-if="item.status == 2 || item.status == 3" src="~@/assets/images/balance/playWith/orderIco1.png"/>
            <img v-else src="~@/assets/images/balance/playWith/with2.png"/> 

            <div>
              订单编号：{{item.orderNumber}}
            </div>
            <div class="title-time">
              {{item.createTime}}
            </div>
          </div>
          <div class="data-td"> 
            <div class="td-item td-nick-head">
              <div class="item-head">
                <img v-if="tabIdx == 0" :src="item.fromHeadImg">
                <img v-else :src="item.toHeadImg">
              </div>
              <div class="item-nick-name">
                <div class="" v-if="tabIdx == 0">昵称：{{item.fromNickName}}</div>
                <div class="" v-else>昵称：{{item.toNickName}}</div>
                <div>服务：{{item.gameName}}</div>

              </div>
            </div>
            <div class="td-item">{{item.unitPrice}}/{{item.unitType==1?'小时':'局'}}*{{item.number || 0}}</div>
            <div class="td-item">{{item.actualMoney}}</div>
            <div class="td-item">
              <!-- <div>00:05:00 </div> -->
              <div v-if="item.startTime && item.endTime">
                <count-down 
                  v-on:start_callback="countDownS_cb(item,idx)" 
                  v-on:end_callback="countDownE_cb(item,idx)" 
                  :startTime="item.itemShow" 
                  :endTime="item.endTime" >
                  </count-down>

              </div>
              <div> {{item.status == 1?'待支付':item.status == 2?'已支付':item.status == 3?'进行中':item.status == 4?'被拒绝接单':item.status == 5?'取消待审核':item.status == 6?'取消订单':item.status == 7?'超时':'已完成'}} </div>
            </div>
            <div class="td-item td-bnt">
              <div class="bnt-data" v-if="tabIdx == 0 ">
                <div @click="handleReplyOrder(item)" v-if="item.status == 2 ">不接单</div>
                <div @click="handleReplyOrderNo(item)" v-if="item.status == 2 ">立即接单</div>
              </div>
              <div class="bnt-data" v-if="tabIdx == 1">
                <div @click="handleCompletePass(item)" v-if=" item.status == 2 || item.status == 3">取消订单</div>
                <div @click="handleCompleteOrder(item) " v-if="item.status == 3 ">完成订单</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="lists-no" v-else>
        <div class="no-data">
          <img src="~@/assets/images/balance/no-data.gif">
        </div>
      </div>

    </div>
    <div class="remarks-tip-bg" v-if="tipShow"></div>
    <div class="remarks-tip" v-if="tipShow">
      <div class="tip-close" @click="handleHeadCloseData">
        <img src="~@/assets/images/balance/user/close.png" />
      </div>
      <div class="tip-title">取消订单的原因</div>
      <div class="tip-canten">
        <ul class="canten-remarks">
          <li class="remarks-itme" @click="handleSelectBox(item,idx)" v-for="(item,idx) in  remarks" :key="idx">
            <div class="remarks-name">{{item.name}}</div>
            <div class="remarks-box">
              <img v-if="selectIdx === idx" src="~@/assets/images/balance/boxSelect.png">
            </div>
          </li>
        </ul>
      </div>
      <div class="tip-bnt">
        <div class="bnt-add" v-loading="loading" @click="handleTipGameService">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
import CountDown from 'vue2-countdown'
import { getOrders,getHistoryOrder,replyOrder,completeOrder  } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: "playWith",
  data() {
    return{
      tabList:[
        {
          name: '我接的单',
        },
        {
          name: '我的下单',
        }
      ],
      bnts:[
        {
          id:'',
          name: '全部',
          icon: require('@/assets/images/balance/playWith/with1.png'),
          iconShow: require('@/assets/images/balance/playWith/withShow1.png'),
          num: 0
        },
        {
          id:3,
          name: '进行中',
          icon: require('@/assets/images/balance/playWith/with2.png'),
          iconShow: require('@/assets/images/balance/playWith/withShow2.png'),
          num: 0
        },
        {
          id:9,
          name: '已完成',
          icon: require('@/assets/images/balance/playWith/with3.png'),
          iconShow: require('@/assets/images/balance/playWith/withShow3.png'),
          num: 0
        },
      ],
      tabIdx: 0,
      bntIdx: 0,
      tableTh: [
        {
          name: '订单信息',
        },
        {
          name: '单价及数量',
        },
        {
          name: '金额',
        },
        {
          name: '订单状态',
        },
        {
          name: '操作',
        },
      ],
      listData: [],
      lgUserId: '',
      searchObj: {
      },
      bntStatus: '',
      tipShow: false,
      loading: false,
      remarks:[
        {
          name: '不想打了',
        },
        {
          name: '我临时有事',
        },
        {
          name: '陪练没时间',
        },
        {
          name: '陪练水平差',
        },
        {
          name: '陪练服务态度恶劣',
        },
        {
          name: '陪练未提供服务',
        },
        {
          name: '实际服务者与接单陪练不符',
        },
        {
          name: '其它',
        }
      ],
      selectIdx: 0,
      remark: '' ,
      passOrder:{}
    }
  },
  components: {
    CountDown
  },
  computed:{
  },
  methods: {
    countDownS_cb(){},
    countDownE_cb(item,idx){
      this.listData[idx].status = 7
    },

    handleSelectBox(item,idx) {
      this.selectIdx = idx
      this.remark = item.name
    },
    handleHeadCloseData() {
      this.tipShow = false
      this.passOrder = {}
      this.remark = ''
      this.selectIdx = 0
    },
    handleTipGameService() {
      if(!this.remark) {
        this.$message.error('请输入取消的理由!');
        return
      }
      let params={
        orderNumber: this.passOrder.orderNumber,
        replyOrder: 3,
        remark: this.remark,
      }
      completeOrder({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleOrders()
        }else{
          this.$message.error(res.msg || '操作失败!');
        }
        this.handleHeadCloseData()
      })

    },
    handleCompletePass(item) { // 取消订单
      this.passOrder = item
      this.tipShow = true
      this.remark = this.remarks[0].name

    },
    handleCompleteOrder(item) { // 完成订单
      let params={
        orderNumber: item.orderNumber,
        replyOrder: 4,
      }
      completeOrder({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleOrders()
        }else{
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    handleReplyOrder(item) { // 接单
      let params={
        orderNumber: item.orderNumber,
        replyOrder: 1
      }
      replyOrder({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleOrders()
        }else{
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    handleReplyOrderNo(item) { // 不接单
      let params={
        orderNumber: item.orderNumber,
        replyOrder: 2
      }
      replyOrder({data: encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: res.msg,
              type: 'success',
              customClass: 2000
          });
          this.handleOrders()
        }else{
          this.$message.error(res.msg || '操作失败!');
        }
      })

    },
    handleHistoryOrder() {// 我的接单
      getHistoryOrder().then(res => {
        if (res.code == 200) {
          this.listData = res.rows;
          for(let i in this.listData) {
            this.listData[i].itemShow = this.$common.publicMethod.getTimestamp(this.listData[i].createTime)
          }
        }
      })
  
    },
    
    handleOrders() {// 接单列表
      getOrders(this.searchObj).then(res => {
        if (res.code == 200) {
          this.listData = res.rows;
          for(let i in this.listData) {
            this.listData[i].itemShow = new Date().getTime()
          }
        }
      })

    },
    handleTabData(item,idx) {
      this.tabIdx = idx
      this.bntIdx = ''
      this.searchObj = {}
      if(idx == 0) {
        this.searchObj.toLgUserId = this.lgUserId

      }else{
        this.searchObj.fromLgUserId = this.lgUserId

      }
      this.handleOrders()
    },
    handleBntsData(item,idx) {
      this.bntIdx = idx
      this.bntStatus = item.id
      this.searchObj.status = this.bntStatus 
      if(this.tabIdx == 0) {
        this.searchObj.toLgUserId = this.lgUserId

      }else{
        this.searchObj.fromLgUserId = this.lgUserId

      }
      this.handleOrders()
      // this.handleTabData()
    }
  },
  created() {
    this.lgUserId = sessionStorage.lgUserId
    this.searchObj.toLgUserId = this.lgUserId
  },
  mounted() {
    this.handleOrders()
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
.playWith{
  color: #666666;
  padding: 30px 24px  30px  0px;
  .playWith-tab{
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 6px 25px 0px rgba(204, 219, 230, 0.3);
    border-radius: 19px;
    padding: 15px 0;
    font-size: 14px;
    >div{
      padding: 0 19px;
      cursor: pointer;
      &.tabShow{
        color: #000000;
        font-weight: bold;
      }
    }
  }
  .playWith-bnts{
    display: flex;
    margin-top: 15px;
    font-size: 12px;
    .bnts-item{
      display: flex;
      align-items: center;
      padding: 11px 14px ;
      background: #F7F9FC;
      border: 1px solid #E5E9F0;
      border-radius: 12px;
      margin-right: 16px;
      cursor: pointer;
      &.btns-show{
        background: linear-gradient(0deg, #54DDB0 0%, #49F367 100%);
        box-shadow: 0px 13px 19px 0px rgba(84, 225, 177, 0.2);
        color: #FFFFFF;
      }
      img{
        width: 14px;
        height: 14px;
      }
      >div{
        margin-left: 6px;
      }
    }
  }
  .playWith-table{
    margin-top: 38px;
    >div{
    }
    .table-th{
      display: flex;
      padding: 14px 19px ;
      background: #FFFFFF;
      box-shadow: 0px 6px 20px 0px rgba(204, 219, 230, 0.2);
      border-radius: 19px;
      color: #333333;
      font-size: 12px;
      >div{

      }
      >div:nth-child(1){
        flex: 7;
      }
      >div:nth-child(2){
        text-align: center;
        flex: 3;

      }
      >div:nth-child(3){
        text-align: center;
        flex: 2;

      }
      >div:nth-child(4){
        text-align: center;
        flex: 3;

      }
      >div:nth-child(5){
        text-align: center;
        flex: 5;

      }
    }
    
    .lists-no{
      width: 100%;
      height: 400px;
      position: relative;
      .no-data{
        text-align: center;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 3;
        >img{
          width: 264px;
          height: 240px;
        }
      }
    }
    .table-list::-webkit-scrollbar{
      display: none; /* Chrome Safari */
    }
    .table-list{
      height: 400px;
      // border: 1px solid red;
      // padding-top: 10px;
      background: #FFFFFF;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-top: 7px;
      box-shadow: 0px -6px 20px 0px rgba(204, 219, 230, 0.2);
      border-radius: 19px;
      font-size: 12px;
      .list-data{
        width: 100%;
        >div{
          display: flex;
          align-items: center;
        }
        .data-title{
          padding: 12px 19px ;
          border-bottom: 1px solid #EEF3F9;
          >div:nth-child(2){
            margin-left: 20px;
          }
          img{
            width: 14px;
            height: 14px;
          }
          .title-time{
            margin-left: auto;
            margin-right: 40px;
          }
        }
        .data-td{
          display: flex;
          align-items: center;
          // padding: 28px 0;
          padding: 28px 19px ;
          border-bottom: 1px solid #EEF3F9;
          >div:nth-child(1){
            flex: 7;
            // width: 300px;
          }
          >div:nth-child(2){
            text-align: center;
            // flex: 3;
            width: 140px;

          }
          >div:nth-child(3){
            text-align: center;
            // flex: 2;
            width: 80px;

          }
          >div:nth-child(4){
            text-align: center;
            // flex: 3;
            width: 130px;

          }
          >div:nth-child(5){
            text-align: center;
            width: 230px;

          }
          .td-item{
            &.td-nick-head{
              display: flex;
              align-items: center;
            }
            .item-head{
              width: 44px;
              height: 44px;
              background: #DAE3F0;
              border-radius: 22px;
              >img{
                width: 100%;
                height: 100%;
                border-radius: 22px;
              }
            }
            .item-nick-name{
              margin-left: 13px;
              >div:nth-child(2){
                margin-top: 5px;
              }
            }
            &.td-bnt{
              .bnt-data{
                display: flex;
                justify-content: center;
                >div:nth-child(1){
                  color: #EF4545;
                  cursor: pointer;
                }
                >div:nth-child(2){
                  cursor: pointer;
                  color: #54DFAB;
                  margin-left: 27px;
                }

              }
            }
          }
        }
      }
    }
  }

}

.remarks-tip-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 22;
}
.remarks-tip {
  position: fixed;
  width: 440px;
  padding: 0;
  top: 56%;
  left: 50%;
  background: #fff;
  box-shadow: 0px 13px 31px 0px rgba(204, 219, 230, 0.35);
  transform: translate(-50%, -50%);
  z-index: 33;
  .tip-close {
    position: absolute;
    width: 40px;
    height: 40px;
    top: -26px;
    right: -20px;
    z-index: 44;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tip-title{
    padding: 28px 36px 22px 36px;
    background: rgba(204, 219, 230, 0.35);
    font-size: 16px;
    font-weight: bold;
    color: #1A1A1A;
  }
  .tip-canten{
    // max-width: 440px;
    .canten-remarks{
      font-size: 14px;
      color: #1A1A1A;
      padding: 22px 36px;
      .remarks-itme{
        margin-top: 14px;
        display: flex;
        align-items: center;
        .remarks-box{
          position: relative;
          width: 25px;
          height: 26px;
          border-radius: 50%;
          border: 1px solid #999999;
          margin-left: auto;
          cursor: pointer;
          img{
            position: absolute;
            width: 22px;
            height: 20px;
            left: 5px;
            top: 0px;
            z-index: 3;
          }
        }
      }
    }
    .add-item{
      margin-bottom: 30px;
      &.add-deploy{
        .deploy-item{
          /deep/.el-select{
            width:90%;
            input{
              border: none;
              background: none;
              border-bottom: 1px solid #E1E3E6;
            }
            .el-select__tags{
              width: 90%;
            }
          }
          span{
            color: #333;
          }
        }
      }
    }
  }
  .tip-bnt {
    display: flex;
    margin-top: 50px;
    margin-bottom: 28px;
    > div {
      width: 120px;
      height: 38px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      line-height: 38px;
      text-align: center;
      cursor: pointer;
    }
    .bnt-add {
      margin: auto;
      background: #5564f3;
      box-shadow: 0px 8px 19px 0px rgba(85, 100, 243, 0.3);
    }
  }
}
</style>
